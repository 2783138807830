.badge-new {
    position: relative;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
}

.badge-new-background {
    position: absolute;
    z-index: 0;
    inset: 0;
    transform: skew(31deg);
}

.badge-new-text {
    position: relative;
    z-index: 1;
}
