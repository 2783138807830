._new_Select {
  position: relative;
  display: inline-block;
  border-radius: 28px;

  ._new_Select_Button {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    padding: 0;
    border: 1px solid var(--primary-surface-25);
    border-radius: inherit;
    background: var(--background-default);
    cursor: pointer;
    font-family: inherit;
    outline: none;
    transition: all var(--animation-speed-default) var(--animation-easing-default);

    &:hover {
      border-color: transparent;
      background: var(--primary-surface-25);
      transition: none;
    }

    &:focus-visible {
      border-color: var(--content-on-background-default);
    }
  }

  ._new_Select_Chevron {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
  }

  ._new_Select_OptionsWrapper {
    position: relative;
    display: inline-block;
  }

  ._new_Select_Options {
    position: absolute;
    z-index: 10;
    top: 24px;
    left: -40px;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(-8px, 8px, 0) scale3d(0.375, 0.25, 1);
    transform-origin: top left;
    transition:
      opacity var(--animation-speed-default) var(--animation-easing-default),
      transform var(--animation-speed-default) var(--animation-easing-default);
  }

  &._new_Select_opened {
    ._new_Select_Options {
      opacity: 1;
      pointer-events: initial;
      transform: translate3d(0, 0, 0);
      transition:
        opacity var(--animation-speed-fast) var(--animation-easing-default),
        transform var(--animation-speed-fast) var(--animation-easing-default);
    }

    ._new_Select_Button {
      border-color: var(--content-on-background-default);
    }
  }
}

._new_Select_select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  opacity: 0;
}

._new_Select_phone {
  ._new_Select_Chevron {
    right: 16px;
  }
}
