/* stylelint-disable no-duplicate-selectors */

.Button {
  display: inline-flex;
  padding: 0;
  border: 0;
  border-radius: 0.325rem;
  margin: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: 2em;
  text-align: center;
  text-decoration: none;
}

.Button,
.Button:focus {
  outline: none;
}

.Button .Button-Inner {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.675em 2em;
  border-radius: inherit;
  cursor: pointer;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
}

.Button .Button-Inner {
  background-color: var(--primary-default);
  color: var(--content-on-color-white);
  transition: background-color var(--animation-speed-default) var(--animation-easing-default);
}

.Button.Button_disabled {
  opacity: 1;
}

.Button.Button_disabled .Button-Inner {
  background-color: var(--primary-disabled);
}

.Button:hover .Button-Inner,
.Button-Proxy:hover .Button .Button-Inner,
.Button:focus-visible .Button-Inner,
.Button:active .Button-Inner {
  transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
}

.Button:hover .Button-Inner,
.Button-Proxy:hover .Button .Button-Inner,
.Button:focus-visible .Button-Inner {
  background-color: var(--primary-surface-85);
}

.Button:active .Button-Inner,
.Button:hover:active .Button-Inner,
.Button-Proxy:active .Button .Button-Inner,
.Button-Proxy:hover:active .Button .Button-Inner,
.Button:focus-visible:active .Button-Inner,
.Button.Button_active .Button-Inner {
  background-color: var(--primary-action);
}

.Button.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button.Button_active .Button-Inner,
.Button.Button_active:focus-visible .Button-Inner {
  background-color: var(--primary-default);
}

.Button.Button_active:active .Button-Inner,
.Button.Button_active:active:hover .Button-Inner,
.Button-Proxy:active:hover .Button.Button_active .Button-Inner,
.Button.Button_active:active:focus-visible .Button-Inner {
  background-color: var(--primary-surface-85);
}

.DisableAnimations .Button {
  transition: none !important;
}

.Button-Child {
  color: inherit;
  text-decoration: none;
}

.Button-Child,
.Button-Child:focus {
  outline: none;
}

.Button_stretch {
  height: 100%;
  flex-grow: 1;
}

.Button_stretch .Button-Inner {
  height: 100%;
}

.Button_disabled {
  opacity: 0.45;
  pointer-events: none;
}

.Button_disabled:not(.Button_theme_simple-reverse-blue) .Button-inner {
  background-image: none !important;
  box-shadow: none !important;
}

.Button_color_green .Button-Inner {
  background-color: var(--secondary-default);
  color: var(--content-on-color-white);
  transition: background-color var(--animation-speed-default) var(--animation-easing-default);
}

.Button_color_green.Button_disabled {
  opacity: 1;
}

.Button_color_green.Button_disabled .Button-Inner {
  background-color: var(--secondary-disabled);
}

.Button_color_green:hover .Button-Inner,
.Button-Proxy:hover .Button_color_green .Button-Inner,
.Button_color_green:focus-visible .Button-Inner,
.Button_color_green:active .Button-Inner {
  transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
}

.Button_color_green:hover .Button-Inner,
.Button-Proxy:hover .Button_color_green .Button-Inner,
.Button_color_green:focus-visible .Button-Inner {
  background-color: var(--secondary-surface-85);
}

.Button_color_green:active .Button-Inner,
.Button_color_green:hover:active .Button-Inner,
.Button-Proxy:active .Button_color_green .Button-Inner,
.Button-Proxy:hover:active .Button_color_green .Button-Inner,
.Button_color_green:focus-visible:active .Button-Inner,
.Button_color_green.Button_active .Button-Inner {
  background-color: var(--secondary-action);
}

.Button_color_green.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button_color_green.Button_active .Button-Inner,
.Button_color_green.Button_active:focus-visible .Button-Inner {
  background-color: var(--secondary-default);
}

.Button_color_green.Button_active:active .Button-Inner,
.Button_color_green.Button_active:active:hover .Button-Inner,
.Button-Proxy:active:hover .Button_color_green.Button_active .Button-Inner,
.Button_color_green.Button_active:active:focus-visible .Button-Inner {
  background-color: var(--secondary-surface-85);
}

@media screen and (prefers-color-scheme: dark) {
  .Button_color_green .Button-Inner {
    color: var(--content-on-color-default);
  }
}

.Button_color_darkgray .Button-Inner {
  background-color: var(--content-on-background-additional);
  color: var(--content-on-color-white);
  transition: background-color var(--animation-speed-default) var(--animation-easing-default);
}

.Button_color_darkgray.Button_disabled {
  opacity: 1;
}

.Button_color_darkgray.Button_disabled .Button-Inner {
  background-color: var(--content-on-background-minor);
}

.Button_color_darkgray:hover .Button-Inner,
.Button-Proxy:hover .Button_color_darkgray .Button-Inner,
.Button_color_darkgray:focus-visible .Button-Inner,
.Button_color_darkgray:active .Button-Inner {
  transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
}

.Button_color_darkgray:hover .Button-Inner,
.Button-Proxy:hover .Button_color_darkgray .Button-Inner,
.Button_color_darkgray:focus-visible .Button-Inner {
  background-color: var(--content-on-background-minor);
}

.Button_color_darkgray:active .Button-Inner,
.Button_color_darkgray:hover:active .Button-Inner,
.Button-Proxy:active .Button_color_darkgray .Button-Inner,
.Button-Proxy:hover:active .Button_color_darkgray .Button-Inner,
.Button_color_darkgray:focus-visible:active .Button-Inner,
.Button_color_darkgray.Button_active .Button-Inner {
  background-color: var(--content-on-background-default);
}

.Button_color_darkgray.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button_color_darkgray.Button_active .Button-Inner,
.Button_color_darkgray.Button_active:focus-visible .Button-Inner {
  background-color: var(--content-on-background-additional);
}

.Button_color_darkgray.Button_active:active .Button-Inner,
.Button_color_darkgray.Button_active:active:hover .Button-Inner,
.Button-Proxy:active:hover .Button_color_darkgray.Button_active .Button-Inner,
.Button_color_darkgray.Button_active:active:focus-visible .Button-Inner {
  background-color: var(--content-on-background-minor);
}

.Button_size_thin-side .Button-Inner {
  padding-right: 1em;
  padding-left: 1em;
}

/*
TODO: rework paddings.
Now here are strange values, because buttons need be consistent with old design
after .Button-Inner line-hieght was changed from normal to 1. normal is not good solution
because in this case buttons have different height on mac and windows.
*/
.Button_size_small .Button-Inner {
  padding: 0.56em 1em 0.503em;
}

.Button_size_big .Button-Inner {
  padding: 1em 3em;
}

/* It's by ui kit */
.Button_size_new-small .Button-Inner {
  padding: 14px 24px;
}

/* It's by ui kit */
.Button_size_new-big .Button-Inner {
  padding: 14px 40px;
}

.Button_size_thin .Button-Inner {
  padding: 10px 56px;
}

.Button_size_thin_mobile .Button-Inner {
  padding: 12px 30px;
}

/*
TODO: rework paddings.
Now here are strange values, because buttons need be consistent with old design,
after .Button-Inner line-hieght was changed from normal to 1. normal is not good solution
because in this case buttons have different height on mac and windows.
*/
.Button_size_biggest .Button-Inner {
  padding: 1.219em 3em;
}

.Button_size_square .Button-Inner {
  padding: 0.5em;
}

.Button_size_block {
  display: block;
  width: 100%;
}

.Button_size_block .Button-Inner {
  display: block;
  width: 100%;
  padding-right: 0.375em;
  padding-left: 0.375em;
}

.Button_text_smaller .Button-Text {
  position: relative;
  top: calc(-1 * ((1em - 0.875em) / 2));
  font-size: 0.875em;
}

.Button_text_bigger .Button-Text {
  font-size: 1.1428em;
}

.Button_text_biggest .Button-Text {
  font-size: 22px;
}

.Button_text_lighter {
  font-weight: 400;
}

.macOsX.firefox .Button_text_lighter {
  font-weight: 300;
}

.Button_theme_round {
  border-radius: 9999px;
}

.Button_theme_not-rounded {
  border-radius: 0;
}

.Button_theme_simple-reverse-blue .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--content-on-background-primary) inset;
  color: var(--content-on-background-primary);
  fill: var(--content-on-background-primary);
  transition: all var(--animation-speed-default) var(--animation-easing-default);
}

.Button_theme_simple-reverse-blue.Button_disabled {
  opacity: 0.45;
}

.Button_theme_simple-reverse-blue.Button_disabled .Button-Inner {
  background-color: var(--background-transparent);
}

.Button_theme_simple-reverse-blue:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-blue .Button-Inner,
.Button_theme_simple-reverse-blue:focus-visible .Button-Inner,
.Button_theme_simple-reverse-blue:active .Button-Inner {
  transition: all var(--animation-speed-zero) var(--animation-easing-default);
}

.Button_theme_simple-reverse-blue:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-blue .Button-Inner,
.Button_theme_simple-reverse-blue:focus-visible .Button-Inner {
  background-color: var(--primary-surface-10);
  box-shadow: 0 0 0 1px var(--primary-default) inset;
  color: var(--primary-default);
  fill: var(--primary-default);
}

.Button_theme_simple-reverse-blue:active .Button-Inner,
.Button_theme_simple-reverse-blue:hover:active .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-blue .Button-Inner,
.Button-Proxy:hover:active .Button_theme_simple-reverse-blue .Button-Inner,
.Button_theme_simple-reverse-blue:focus-visible:active .Button-Inner,
.Button_theme_simple-reverse-blue.Button_active .Button-Inner {
  background-color: var(--primary-surface-25);
  box-shadow: 0 0 0 1px var(--primary-action) inset;
  color: var(--primary-action);
  fill: var(--primary-action);
}

.Button_theme_simple-reverse-blue.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-blue.Button_active .Button-Inner,
.Button_theme_simple-reverse-blue.Button_active:focus-visible .Button-Inner {
  background-color: var(--primary-surface-10);
  box-shadow: 0 0 0 1px var(--primary-default) inset;
  color: var(--primary-default);
  fill: var(--primary-default);
}

.Button_theme_simple-reverse-blue.Button_active:active .Button-Inner,
.Button_theme_simple-reverse-blue.Button_active:active:hover .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-blue.Button_active .Button-Inner,
.Button-Proxy:active:hover .Button_theme_simple-reverse-blue.Button_active .Button-Inner,
.Button_theme_simple-reverse-blue.Button_active:active:focus-visible .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--primary-surface-85) inset;
  color: var(--primary-surface-85);
  fill: var(--primary-surface-85);
}

.Button_theme_simple-reverse-green .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--secondary-surface-85) inset;
  color: var(--secondary-surface-85);
  fill: var(--secondary-surface-85);
  transition: all var(--animation-speed-default) var(--animation-easing-default);
}

.Button_theme_simple-reverse-green.Button_disabled {
  opacity: 0.45;
}

.Button_theme_simple-reverse-green.Button_disabled .Button-Inner {
  background-color: var(--background-transparent);
}

.Button_theme_simple-reverse-green:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-green .Button-Inner,
.Button_theme_simple-reverse-green:focus-visible .Button-Inner,
.Button_theme_simple-reverse-green:active .Button-Inner {
  transition: all var(--animation-speed-zero) var(--animation-easing-default);
}

.Button_theme_simple-reverse-green:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-green .Button-Inner,
.Button_theme_simple-reverse-green:focus-visible .Button-Inner {
  background-color: var(--secondary-surface-10);
  box-shadow: 0 0 0 1px var(--secondary-default) inset;
  color: var(--secondary-default);
  fill: var(--secondary-default);
}

.Button_theme_simple-reverse-green:active .Button-Inner,
.Button_theme_simple-reverse-green:hover:active .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-green .Button-Inner,
.Button-Proxy:hover:active .Button_theme_simple-reverse-green .Button-Inner,
.Button_theme_simple-reverse-green:focus-visible:active .Button-Inner,
.Button_theme_simple-reverse-green.Button_active .Button-Inner {
  background-color: var(--secondary-surface-25);
  box-shadow: 0 0 0 1px var(--secondary-action) inset;
  color: var(--secondary-action);
  fill: var(--secondary-action);
}

.Button_theme_simple-reverse-green.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-green.Button_active .Button-Inner,
.Button_theme_simple-reverse-green.Button_active:focus-visible .Button-Inner {
  background-color: var(--secondary-surface-10);
  box-shadow: 0 0 0 1px var(--secondary-default) inset;
  color: var(--secondary-default);
  fill: var(--secondary-default);
}

.Button_theme_simple-reverse-green.Button_active:active .Button-Inner,
.Button_theme_simple-reverse-green.Button_active:active:hover .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-green.Button_active .Button-Inner,
.Button-Proxy:active:hover .Button_theme_simple-reverse-green.Button_active .Button-Inner,
.Button_theme_simple-reverse-green.Button_active:active:focus-visible .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--secondary-surface-85) inset;
  color: var(--secondary-surface-85);
  fill: var(--secondary-surface-85);
}

.Button_theme_simple-reverse-content-on-background .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--content-on-background-additional) inset;
  color: var(--content-on-background-additional);
  fill: var(--content-on-background-additional);
  transition: all var(--animation-speed-default) var(--animation-easing-default);
}

.Button_theme_simple-reverse-content-on-background.Button_disabled {
  opacity: 0.45;
}

.Button_theme_simple-reverse-content-on-background.Button_disabled .Button-Inner {
  background-color: var(--background-transparent);
}

.Button_theme_simple-reverse-content-on-background:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-content-on-background .Button-Inner,
.Button_theme_simple-reverse-content-on-background:focus-visible .Button-Inner,
.Button_theme_simple-reverse-content-on-background:active .Button-Inner {
  transition: all var(--animation-speed-zero) var(--animation-easing-default);
}

.Button_theme_simple-reverse-content-on-background:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-content-on-background .Button-Inner,
.Button_theme_simple-reverse-content-on-background:focus-visible .Button-Inner {
  background-color: var(--primary-surface-10);
  box-shadow: 0 0 0 1px var(--content-on-background-solid) inset;
  color: var(--content-on-background-solid);
  fill: var(--content-on-background-solid);
}

.Button_theme_simple-reverse-content-on-background:active .Button-Inner,
.Button_theme_simple-reverse-content-on-background:hover:active .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-content-on-background .Button-Inner,
.Button-Proxy:hover:active .Button_theme_simple-reverse-content-on-background .Button-Inner,
.Button_theme_simple-reverse-content-on-background:focus-visible:active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner {
  background-color: var(--content-on-background-muted);
  box-shadow: 0 0 0 1px var(--content-on-background-solid) inset;
  color: var(--content-on-background-solid);
  fill: var(--content-on-background-solid);
}

.Button_theme_simple-reverse-content-on-background.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active:focus-visible .Button-Inner {
  background-color: var(--primary-surface-10);
  box-shadow: 0 0 0 1px var(--content-on-background-solid) inset;
  color: var(--content-on-background-solid);
  fill: var(--content-on-background-solid);
}

.Button_theme_simple-reverse-content-on-background.Button_active:active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active:active:hover .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner,
.Button-Proxy:active:hover .Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active:active:focus-visible .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--content-on-background-additional) inset;
  color: var(--content-on-background-additional);
  fill: var(--content-on-background-additional);
}

.Button_theme_simple-reverse-content-on-background .Button-Inner {
  box-shadow: 0 0 0 1px var(--background-border) inset;
}

.Button_theme_simple-reverse-content-on-background:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-content-on-background .Button-Inner,
.Button_theme_simple-reverse-content-on-background:focus-visible .Button-Inner {
  box-shadow: 0 0 0 1px var(--background-transparent) inset;
}

.Button_theme_simple-reverse-content-on-background:active .Button-Inner,
.Button_theme_simple-reverse-content-on-background:hover:active .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-content-on-background .Button-Inner,
.Button-Proxy:hover:active .Button_theme_simple-reverse-content-on-background .Button-Inner,
.Button_theme_simple-reverse-content-on-background:focus-visible:active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner {
  box-shadow: 0 0 0 1px var(--background-transparent) inset;
}

.Button_theme_simple-reverse-content-on-background.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active:focus-visible .Button-Inner {
  box-shadow: 0 0 0 1px var(--primary-surface-10) inset;
}

.Button_theme_simple-reverse-content-on-background.Button_active:active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active:active:hover .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner,
.Button-Proxy:active:hover .Button_theme_simple-reverse-content-on-background.Button_active .Button-Inner,
.Button_theme_simple-reverse-content-on-background.Button_active:active:focus-visible .Button-Inner {
  box-shadow: 0 0 0 1px var(--primary-surface-10) inset;
}

.Button_theme_simple-reverse-content-on-color .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--content-on-color-white) inset;
  color: var(--content-on-color-white);
  fill: var(--content-on-color-white);
  transition: all var(--animation-speed-default) var(--animation-easing-default);
}

.Button_theme_simple-reverse-content-on-color.Button_disabled {
  opacity: 0.45;
}

.Button_theme_simple-reverse-content-on-color.Button_disabled .Button-Inner {
  background-color: var(--background-transparent);
}

.Button_theme_simple-reverse-content-on-color:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-content-on-color .Button-Inner,
.Button_theme_simple-reverse-content-on-color:focus-visible .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--content-on-color-white) inset;
  color: var(--content-on-color-white);
  fill: var(--content-on-color-white);
}

.Button_theme_simple-reverse-content-on-color:active .Button-Inner,
.Button_theme_simple-reverse-content-on-color:hover:active .Button-Inner,
.Button-Proxy:active .Button_theme_simple-reverse-content-on-color .Button-Inner,
.Button-Proxy:hover:active .Button_theme_simple-reverse-content-on-color .Button-Inner,
.Button_theme_simple-reverse-content-on-color:focus-visible:active .Button-Inner,
.Button_theme_simple-reverse-content-on-color.Button_active .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--content-on-color-white) inset;
  color: var(--content-on-color-white);
  fill: var(--content-on-color-white);
}

.Button_theme_simple-reverse-content-on-color.Button_active:hover .Button-Inner,
.Button-Proxy:hover .Button_theme_simple-reverse-content-on-color.Button_active .Button-Inner,
.Button_theme_simple-reverse-content-on-color.Button_active:focus-visible .Button-Inner {
  background-color: var(--background-transparent);
  box-shadow: 0 0 0 1px var(--content-on-color-white) inset;
  color: var(--content-on-color-white);
  fill: var(--content-on-color-white);
}
