.UserAvatar_background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-border);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.UserAvatar_clickable {
  cursor: pointer;
}

.UserAvatar_hightlighted {
  border: 1px solid var(--background-default);
  outline: 1px solid var(--content-on-background-primary);
}

.UserAvatar_size_100 {
    width: 100px;
    height: 100px;
    border-radius: 50px;

  &.UserAvatar_hightlighted {
    width: 98px;
    height: 98px;
  }
}

.UserAvatar_size_40 {
    width: 40px;
    height: 40px;
    border-radius: 20px;

    &.UserAvatar_hightlighted {
      width: 38px;
      height: 38px;
    }
}

.UserAvatar_size_32 {
    width: 32px;
    height: 32px;
    border-radius: 16px;

    &.UserAvatar_hightlighted {
      width: 30px;
      height: 30px;
    }
}

.UserAvatar_size_16 {
  width: 16px;
  height: 16px;
  border-radius: 8px;

  &.UserAvatar_hightlighted {
    width: 14;
    height: 14;
  }
}

.UserAvatar_edit {
    position: absolute;
    right: -5px;
    bottom: -5px;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 4px solid var(--background-default);
    border-radius: 20px;
    background: var(--secondary-default);
    cursor: pointer;
}

.UserAvatar_editIcon {
    margin-right: -3px;
    margin-bottom: -1px;
}
