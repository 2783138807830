.SecurityUniverse-StuckArea {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--background-default);
  box-shadow: var(--shadow-level-3);
}

.SecurityUniverse-ButtonWrapper {
  background-color: var(--background-default);
}

.SecurityUniverse-Wrapper {
  height: calc(100vh - 100px);
}

.SecurityUniverse-Iframe-Fullwidth {
  right: -9999px;
  left: -9999px;
  width: 100vw;
  margin: auto;
}

.SecurityUniverse-Header_mobile {
  position: relative;
  z-index: 1000;
}

.SecurityUniverse-Footer {
  position: absolute;
  left: 0;
  width: 100%;
}
