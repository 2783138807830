.Banner {
  --banner-default-padding: 16px;
  --banner-small-padding: 8px;

  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  padding-block: var(--banner-default-padding);
}

.Banner-Header {
  display: flex;
  justify-content: space-between;
  padding-inline: var(--banner-default-padding);
}

.Banner-Title,
.Banner-Description {
  padding-inline: var(--banner-default-padding);
}

.Banner-Description {
  padding-right: var(--banner-small-padding);
}

.Banner-Wrapper {
  height: 100%;
  border: none;
}

.Banner-Text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  hyphens: auto;
  -webkit-line-clamp: var(--banner-text-max-lines, 3);
  line-clamp: var(--banner-text-max-lines, 3);
  overflow-wrap: anywhere;
}
