.TodayPointer {
  position: absolute;
  z-index: 100;
  bottom: 0;
  height: 100%;
  pointer-events: none;
}

.TodayPointer::before,
.TodayPointer::after {
  display: block;
  background-color: var(--accent-default);
  content: '';
}

.TodayPointer::before {
  position: absolute;
  bottom: -0.28125rem;
  width: 0.5625rem;
  height: 0.5625rem;
  border-radius: 0.28125rem;
  margin-left: -0.28125rem;
}

.TodayPointer::after {
  position: absolute;
  bottom: 0;
  width: 0.0625rem;
  height: 20%;
  margin-left: calc(-0.0625rem / 2);
}

.TodayPointer-Text {
  position: absolute;
  bottom: 20%;
  padding: 0 0.625em;
  border-radius: 0.125em;
  background-color: var(--accent-default);
  transform: translateX(-50%);
}

.TodayPointer_desktop {
  &&::after {
    height: 27.5%; /* Fallback */
    height: calc(27.5% + 0.0625rem);
  }

  .TodayPointer-Text {
    bottom: 27.5%; /* Fallback */
    bottom: calc(27.5% + 0.0625rem);
  }
}
