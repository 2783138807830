.SegmentedControl {
  font-size: 0.875em;
  text-align: center;
}

.SegmentedControl-Inner {
  display: inline-flex;
}

.SegmentedControl-Segment {
  padding: 0.75em 2em;

  .SegmentedControl_fluid & {
    flex: 1 1 0;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.SegmentedControl-Segment_mobilised {
  @media (width <= 342px) {
    html .Root & {
      font-size: 0.75rem;
    }
  }
}

.SegmentedControl_size_big {
  font-size: 1em;
}

.SegmentedControl_size_small {
  .SegmentedControl-Segment {
    padding: 0.5em 1.5em;
  }
}

.SegmentedControl_size_smaller {
  .SegmentedControl-Segment {
    padding: 0.3em 1.5em;
  }
}

.SegmentedControl_center {
  margin: 0 auto;
}

.SegmentedControl_right {
  margin: 0 0 0 auto;
}

.SegmentedControl_fluid {
  .SegmentedControl-Inner {
    display: flex;
    width: 100%;
  }
}

.SegmentedControl_theme_tabs-light {
  .SegmentedControl-Segment {
    box-shadow: 0 -0.0625em 0 0 #f0f0f0 inset;

    &:active,
    &_active {
      box-shadow: 0 -0.125em 0 0 var(--status-success) inset;
    }
  }
}

.SegmentedControl_theme_slider-alike {
  font-weight: 500;

  .SegmentedControl-Segment {
    padding: 0.5em 1.75em;
    border-radius: 9999px;
    margin: 0.25em;

    &:not(:first-child, :last-child) {
      margin-right: 0;
      margin-left: 0;
    }

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }

    &.SegmentedControl_size_small {
      .SegmentedControl-Segment {
        padding: 0.25em 1.25em;
      }
    }
  }

  .SegmentedControl-Segment_active {
    background-color: var(--background-card);
    box-shadow: var(--shadow-level-1);
  }
}

.SegmentedControl_no-border-items {
  .SegmentedControl-Segment {
    border: none !important;
  }
}
