.PeriodSelect {
  display: flex;
  justify-content: space-around;
}

.PeriodSelect-Period {
  all: initial;
  position: relative;
  padding: 2px 12px;
  border-radius: 9999px;
  background: var(--background-minor);
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  transition:
    background var(--animation-speed-default) var(--animation-easing-default),
    box-shadow var(--animation-speed-default) var(--animation-easing-default),
    color var(--animation-speed-default) var(--animation-easing-default);
}

.PeriodSelect-Period:not(.PeriodSelect-Period_active) {
  box-shadow: 0 0 0 0 transparent inset;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background: var(--background-border);
    box-shadow: 0 0 9px -3px var(--background-border) inset;
    transition:
      background var(--animation-speed-zero) var(--animation-easing-default),
      box-shadow var(--animation-speed-zero) var(--animation-easing-default),
      color var(--animation-speed-zero) var(--animation-easing-default);
  }

  &::before {
    position: absolute;
    display: block;
    width: calc(100% + 8px);
    height: 48px;
    margin: auto;
    content: '';
    inset: -24px;
  }
}

.PeriodSelect-Period_active {
  background: var(--content-on-background-additional);
  box-shadow: 0 0 9px -3px var(--background-border) inset;
  transition:
    background var(--animation-speed-zero) var(--animation-easing-default),
    box-shadow var(--animation-speed-zero) var(--animation-easing-default),
    color var(--animation-speed-zero) var(--animation-easing-default);
}

.PeriodSelect-Name {
  margin: 0 -2px;
  letter-spacing: 0.0625em;
}
