.ErrorMessage {
  color: var(--status-error);
}

.ErrorMessage_theme_global-center {
  position: absolute;
  right: 0;
  left: 0;
  background-color: var(--background-default);
  text-align: center;
}

.ErrorMessage_inline {
  display: inline;
}
