.Dropdown {
  position: relative;
}

.Dropdown-Content {
  position: absolute;
  z-index: 2;
  top: -10px;
  right: 0;
  background: var(--background-default);
  box-shadow: var(--shadow-level-3);

  .Dropdown-Link {
    margin-right: -20px;
    margin-bottom: 2px;
  }
}

.Dropdown-Icon {
  margin-left: 8px;
  vertical-align: middle;
}

.Dropdown-Button {
  .Button-Child {
    display: flex;
    align-items: center;
  }
}

.Dropdown-Link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
  line-height: 1;

  &:hover .Dropdown-Icon {
    fill: var(--primary-link-hover) !important;
  }
}

.Dropdown_link {
  width: 100%;

  .Dropdown-Link {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .Dropdown-Content {
    top: -3px;
    right: -11px;
    width: 280px;

    .Dropdown-Link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.Dropdown_button {
  .Dropdown-Content {
    right: -3px;
  }

  &.Dropdown_desktop .Dropdown-Content {
    top: -23px;
  }

  .windows &.Dropdown_desktop .Dropdown-Content {
    top: -21px;
  }

  .macOsX.firefox &.Dropdown_desktop .Dropdown-Content {
    top: -22px;
  }

  .iOs &.Dropdown_phone .Dropdown-Content {
    top: -12px;
  }

  .android &.Dropdown_phone .Dropdown-Content {
    top: -14px;
  }

  .Dropdown-Icon {
    margin-left: 4px;
    transform: translateY(1px);
  }

  &.Dropdown_desktop .Dropdown-Icon {
    margin-bottom: 1px;
  }

  .Dropdown-Link {
    margin-bottom: 2px;
  }

  &.Dropdown_desktop .Dropdown-Link {
    margin-right: -12px;
  }

  &.Dropdown_phone .Dropdown-Link {
    margin-right: 4px;
  }

  .Text {
    font-size: 16px;
  }
}

.Dropdown_enter {
  opacity: 0;
  transform: translate3d(0, -8px, 0) scale3d(1, 0, 1);
  transform-origin: top center;
}

.Dropdown_enter_active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition:
    opacity var(--animation-speed-default) var(--animation-easing-default),
    transform var(--animation-speed-default) var(--animation-easing-default);
}

.Dropdown_leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transform-origin: top center;
  transition:
    opacity var(--animation-speed-default) var(--animation-easing-default),
    transform var(--animation-speed-default) var(--animation-easing-default);
}

.Dropdown_leave_active {
  opacity: 0;
  transform: translate3d(0, -8px, 0) scale3d(1, 0, 1);
}
