.PointOfInterestCard {
  --point-of-interest-gap: 4px;
  --point-of-interest-margin: calc(var(--point-of-interest-width, 0) / 2 + var(--point-of-interest-gap, 0));
  --point-of-interest-offest: calc(var(--point-of-interest-center, 0) - var(--point-of-interest-width, 0) / 2);

  position: absolute;
  bottom: auto;
  left: var(--point-of-interest-offest, 0);
  overflow: hidden;
  width: var(--point-of-interest-width, 0);
  background-color: var(--background-default);
  opacity: 0;
  pointer-events: none;
  transition:
    transform var(--animation-speed-default) var(--animation-easing-default),
    opacity var(--animation-speed-default) var(--animation-easing-default);

  [data-content-link-icon='true'] {
    opacity: 0.5;
    transition: opacity var(--animation-speed-default) var(--animation-easing-default);
  }

  @supports (backdrop-filter: blur(1px)) {
    background-color: var(--background-default-85);

    &::before {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(2px);
      content: '';
      opacity: 0;
      transition: opacity var(--animation-speed-default) easy-in-out;
    }

    /**
      There is an bug in safari, probably 'cuz of optimisation.
      If `backdrop-filter` applied to the element which was hidden by default
      when the element will appear, it willn't have the `backdrop-filter` effect.
      Slightly changing the amount will force safari to rerender and apply filter.
      https://stackoverflow.com/questions/69904893/webkit-backdrop-filter-not-working-on-safari
     */
    .safari &::before {
      backdrop-filter: blur(1.99px);
    }
  }
}

.PointOfInterestCard_desktop {
  transform: scale3d(0.875, 0.875, 1);
}

.PointOfInterestCard_position_left {
  margin-left: calc(-1 * var(--point-of-interest-margin, 0));
  transform-origin: top right;
}

.PointOfInterestCard_position_right {
  margin-left: var(--point-of-interest-margin, 0);
  transform-origin: top left;
}

.PointOfInterestCard_visible {
  opacity: 1;
  transition:
    transform var(--animation-speed-default) var(--animation-easing-default),
    opacity var(--animation-speed-zero) var(--animation-easing-default);

  &::before {
    opacity: 1;
    transition: opacity var(--animation-speed-default) var(--animation-easing-default);
  }

  .safari &::before {
    @supports (backdrop-filter: blur(1px)) {
      backdrop-filter: blur(2.01px);
    }
  }
}

.PointOfInterestCard_hard.PointOfInterestCard_visible {
  pointer-events: all;

  [data-content-link-icon='true'] {
    opacity: 1;
  }
}

.PointOfInterestCard_hard.PointOfInterestCard_visible.PointOfInterestCard_desktop {
  transform: scale3d(1, 1, 1);
}
