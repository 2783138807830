@define-mixin hidden {
  pointer-events: none;

  .Drawer-Backdrop {
    opacity: 0;
  }

  .Drawer-Inner {
    transform: translate3d(-102.5%, 0, 0);
  }
}

@define-mixin shown {
  pointer-events: all;

  .Drawer-Backdrop {
    opacity: 1;
  }

  .Drawer-Inner {
    transform: translate3d(0%, 0, 0);
  }
}

.Drawer {
  position: absolute;
  z-index: 1000;
  inset: 0;
}

.Drawer_enter {
  @mixin hidden;
}

.Drawer_enter_active {
  @mixin shown;
}

.Drawer_leave {
  @mixin shown;
}

.Drawer_leave_active {
  @mixin hidden;
}

.Drawer_hidden {
  display: none;
}

.Drawer-Header {
  width: 100%;
  margin-right: 0.0625rem;
}

.Drawer-Holder {
  display: block;
}

.Drawer-Icon {
  width: 2rem;
  height: 2rem;
  padding: 0.425rem 0;
}

.Drawer-Toggle {
  text-align: center;
}

.Drawer-Backdrop {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--background-overlay);
  opacity: 1;
  transition: opacity var(--animation-speed-slow) var(--animation-easing-default);
}

.Drawer-Inner {
  position: fixed;
  display: flex;
  overflow: hidden auto;
  width: 100%;
  max-width: calc(100% - 4rem);
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  background: var(--background-default);
  box-shadow: var(--shadow-level-3);
  font-size: 0.875rem;
  text-overflow: ellipsis;
  transition: transform var(--animation-speed-slow) var(--animation-easing-default);

  .Header-Inner {
    display: block;
  }
}
