.BottomTools {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 24px 24px 0 0;
  background: var(--background-default);
  box-shadow: var(--shadow-level-3);
}

.iOs {
  .BottomTools_withSafeArea {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
