.Paper {
  display: block;
}

.Paper_clickThroughPadding {
  pointer-events: none;

  > * {
    pointer-events: initial;
  }
}

.Paper_inline {
  display: inline-block;
}

.Paper_flex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  place-content: flex-start flex-start;
}

/* top */

.Paper_top_1 {
  padding-top: 1px;
}

.Paper_top_2 {
  padding-top: 2px;
}

.Paper_top_4 {
  padding-top: 4px;
}

.Paper_top_8 {
  padding-top: 8px;
}

.Paper_top_12 {
  padding-top: 12px;
}

.Paper_top_16 {
  padding-top: 16px;
}

.Paper_top_20 {
  padding-top: 20px;
}

.Paper_top_24 {
  padding-top: 24px;
}

.Paper_top_32 {
  padding-top: 32px;
}

.Paper_top_40 {
  padding-top: 40px;
}

.Paper_top_48 {
  padding-top: 48px;
}

.Paper_top_56 {
  padding-top: 56px;
}

.Paper_top_64 {
  padding-top: 64px;
}

.Paper_top_72 {
  padding-top: 72px;
}

.Paper_top_80 {
  padding-top: 80px;
}

.Paper_top_96 {
  padding-top: 96px;
}

.Paper_top_120 {
  padding-top: 120px;
}

/* right */

.Paper_right_1 {
  padding-right: 1px;
}

.Paper_right_2 {
  padding-right: 2px;
}

.Paper_right_4 {
  padding-right: 4px;
}

.Paper_right_8 {
  padding-right: 8px;
}

.Paper_right_12 {
  padding-right: 12px;
}

.Paper_right_16 {
  padding-right: 16px;
}

.Paper_right_20 {
  padding-right: 20px;
}

.Paper_right_24 {
  padding-right: 24px;
}

.Paper_right_32 {
  padding-right: 32px;
}

.Paper_right_40 {
  padding-right: 40px;
}

.Paper_right_48 {
  padding-right: 48px;
}

.Paper_right_56 {
  padding-right: 56px;
}

.Paper_right_64 {
  padding-right: 64px;
}

.Paper_right_72 {
  padding-right: 72px;
}

.Paper_right_80 {
  padding-right: 80px;
}

.Paper_right_96 {
  padding-right: 96px;
}

.Paper_right_120 {
  padding-right: 120px;
}

/* bottom */

.Paper_bottom_1 {
  padding-bottom: 1px;
}

.Paper_bottom_2 {
  padding-bottom: 2 px;
}

.Paper_bottom_4 {
  padding-bottom: 4px;
}

.Paper_bottom_8 {
  padding-bottom: 8px;
}

.Paper_bottom_12 {
  padding-bottom: 12px;
}

.Paper_bottom_16 {
  padding-bottom: 16px;
}

.Paper_bottom_20 {
  padding-bottom: 20px;
}

.Paper_bottom_24 {
  padding-bottom: 24px;
}

.Paper_bottom_32 {
  padding-bottom: 32px;
}

.Paper_bottom_40 {
  padding-bottom: 40px;
}

.Paper_bottom_48 {
  padding-bottom: 48px;
}

.Paper_bottom_56 {
  padding-bottom: 56px;
}

.Paper_bottom_64 {
  padding-bottom: 64px;
}

.Paper_bottom_72 {
  padding-bottom: 72px;
}

.Paper_bottom_80 {
  padding-bottom: 80px;
}

.Paper_bottom_96 {
  padding-bottom: 96px;
}

.Paper_bottom_120 {
  padding-bottom: 120px;
}

/* left */

.Paper_left_1 {
  padding-left: 1px;
}

.Paper_left_2 {
  padding-left: 2px;
}

.Paper_left_4 {
  padding-left: 4px;
}

.Paper_left_8 {
  padding-left: 8px;
}

.Paper_left_12 {
  padding-left: 12px;
}

.Paper_left_16 {
  padding-left: 16px;
}

.Paper_left_20 {
  padding-left: 20px;
}

.Paper_left_24 {
  padding-left: 24px;
}

.Paper_left_32 {
  padding-left: 32px;
}

.Paper_left_40 {
  padding-left: 40px;
}

.Paper_left_48 {
  padding-left: 48px;
}

.Paper_left_56 {
  padding-left: 56px;
}

.Paper_left_64 {
  padding-left: 64px;
}

.Paper_left_72 {
  padding-left: 72px;
}

.Paper_left_80 {
  padding-left: 80px;
}

.Paper_left_96 {
  padding-left: 96px;
}

.Paper_left_120 {
  padding-left: 120px;
}
