._new_List ._new_List-Item + ._new_List-Item {
  margin-top: -1px;
}

._new_List-ItemColumnarLayout {
  width: 100%;
}

._new_List_hideUnderlineOnLastItem ._new_List-Item:last-child ._new_List-ItemInner::after {
  display: none;
}

._new_List-Item {
  position: relative;
}

._new_List-ItemInner {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  color: var(--content-on-background-default);
  cursor: pointer;
  font: inherit;
  outline: 1px solid transparent;
  outline-offset: -1px;
  text-align: left;
  text-decoration: none;
  transition:
    color var(--animation-speed-default) var(--animation-easing-default),
    background-color var(--animation-speed-default) var(--animation-easing-default),
    opacity var(--animation-speed-default) var(--animation-easing-default),
    outline-color var(--animation-speed-default) var(--animation-easing-default);
}

._new_List-ItemInner::after {
  position: absolute;
  right: 16px;
  bottom: 0;
  left: 16px;
  display: block;
  height: 1px;
  background: var(--background-border);
  content: '';
}

._new_List-ItemInner:focus-visible {
  outline-color: var(--content-on-background-default);
  transition: none;
}

._new_List-Item_active ._new_List-ItemInner,
._new_List-ItemInner:active {
  background-color: var(--background-border);
  transition: none;
}

._new_List-Item_disabled ._new_List-ItemInner {
  pointer-events: none;
  transition: none;
}

._new_List-Item_disabled ._new_List-ItemInner * {
  color: var(--content-on-background-muted);
}

._new_List-Item_disabled ._new_List-ItemInner .Icon {
  fill: var(--content-on-background-muted) !important;
}

._new_List-Item_phone ._new_List-ItemInner::after {
  right: 16px;
  left: 16px;
}

._new_List-Item_desktop ._new_List-ItemInner::after {
  right: 0;
  left: 0;
}

._new_List-Item_shorterBoard ._new_List-ItemInner::after {
  right: 16px;
  left: 16px;
}

._new_List-Item_desktop ._new_List-ItemInner:hover {
  color: var(--primary-link-hover);
  transition: none;
}
