.DateRangeDropdown-Close {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  fill: var(--content-on-background-default);

  &:hover {
    fill: var(--content-on-background-additional);
  }
}

.DateRangeDropdown-Input {
  position: relative;
  width: 150px;
  box-sizing: border-box;
  padding: 6px 9px;
  border: 1px solid var(--background-border);
  border-radius: 4px;
  appearance: none;
  background: var(--background-default);
  color: var(--content-on-background-default);
  font-family: var(--font-family-default);
  font-size: 16px;
  line-height: 20px;
  outline: none;

  &:focus-visible {
    border-color: var(--primary-default);
  }

  .MobileLayout & {
    width: 100%;
  }

  &.DateRangeDropdown-Input_dark {
    color-scheme: dark;
  }
}

.Layout_phone .DateRangeDropdown-Input {
  width: 100%;
}
