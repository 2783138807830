.ItemWithIcon {
  display: flex;
  width: 100%;
}

.ItemWithIcon-Content {
  display: flex;
  min-width: 0;
  flex: 1 1 0;
  flex-direction: column;
  justify-items: center;
  place-content: center center;
}

.ItemWithIcon_inline {
  display: inline-flex;
  width: auto;
}

.ItemWithIcon_iconAlign_top {
  .ItemWithIcon-Icon {
    display: flex;
    align-items: flex-start;
  }
}

.ItemWithIcon_iconAlign_center {
  .ItemWithIcon-Icon {
    display: flex;
    align-items: center;
  }
}

.ItemWithIcon_iconAlign_bottom {
  .ItemWithIcon-Icon {
    display: flex;
    align-items: flex-end;
  }
}

.ItemWithIcon_contentAlign_top {
  .ItemWithIcon-Content {
    align-self: flex-start;
  }
}

.ItemWithIcon_contentAlign_center {
  .ItemWithIcon-Content {
    align-self: center;
  }
}

.ItemWithIcon_selfAlign_center {
  justify-content: center;

  .ItemWithIcon-Content {
    flex: initial;
  }
}
