.GettingStartedCard {
  position: relative;
  background-color: var(--primary-default);
}

.GettingStartedCard-Info {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
