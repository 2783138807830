.HoldingInformation-Label {
  position: relative;
  display: flex;
  align-self: flex-end;
}

.HoldingInformation-Description {
  position: absolute;
  top: 3px;
  right: 0;
}

.HoldingInformation-Description .Tooltip-Tip {
  right: -1rem;
  left: unset;
}
