.ErrorBlock,
.ErrorBlock-Inner {
  width: 100%;
  border-radius: 5.2px; /* it's like button default border-radius radius, but in pixels, it has to be with decimals to match it exactly */
}

.ErrorBlock {
  min-height: 48px;
  background: var(--background-default);
}

.ErrorBlock-Inner {
  padding-top: 16px;
  padding-bottom: 16px;
  background: var(--status-error-10);
}
