.ColumnarLayout {
  display: flex;
  border-color: inherit;
  margin: 0 -1rem;
}

.ColumnarLayout-Column {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  padding: 0 1rem;
}

.ColumnarLayout-Column_strict {
  width: 0;
}

.ColumnarLayout-Column_size {
  @for $i from 1 to 24 {
    &_$i {
      flex-grow: $(i);

      &.ColumnarLayout-Column_strict {
        flex-basis: calc(100% / $(i));
      }
    }
  }
}

.ColumnarLayout-Column_size_0 {
  flex-grow: 0;

  &.ColumnarLayout-Column_strict {
    flex-basis: 100%;
  }
}

.ColumnarLayout-Column_noShrink {
  flex-shrink: 0;
}

.ColumnarLayout-Column_justifyContent_center {
  justify-content: center;
}

.ColumnarLayout-Column_justifyContent_space-between {
  justify-content: space-between;
}

.ColumnarLayout_bordered {
  .ColumnarLayout-Column:not(:first-child) {
    border-color: inherit;
    border-left-width: 0.0625rem;
    border-left-style: solid;
  }
}

.ColumnarLayout_inline {
  display: inline-flex;
}

.ColumnarLayout_flexible {
  .ColumnarLayout-Column {
    flex-basis: initial;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

.ColumnarLayout_flex_0 {
  flex-grow: 0;
}

.ColumnarLayout_fluid {
  /* TODO: WTF? */
  width: calc(100% + 2rem);
  flex-grow: 1;
}

.ColumnarLayout_align-columns_center {
  align-items: center;
}

.ColumnarLayout_align-columns_flex-start {
  align-items: flex-start;
}

.ColumnarLayout_align-columns_flex-end {
  align-items: flex-end;
}

.ColumnarLayout_align-columns_baseline {
  align-items: baseline;
}

.ColumnarLayout_column-content_flex-start {
  align-items: flex-start;
}

.ColumnarLayout_column-content_flex-end {
  align-items: flex-end;
}

.ColumnarLayout_column-content_center {
  align-items: center;
}

.ColumnarLayout_column-overflow_hidden {
  .ColumnarLayout-Column {
    overflow: hidden;
  }
}

.ColumnarLayout_padding_no {
  margin-right: 0;
  margin-left: 0;

  & > .ColumnarLayout-Column {
    padding-right: 0;
    padding-left: 0;
  }

  &.ColumnarLayout_fluid {
    width: 100%;
  }
}

.ColumnarLayout_padding_mini {
  margin-right: -0.625em;
  margin-left: -0.625em;

  & > .ColumnarLayout-Column {
    padding-right: 0.625em;
    padding-left: 0.625em;
  }
}

.ColumnarLayout_padding_micro {
  margin-right: -0.375em;
  margin-left: -0.375em;

  & > .ColumnarLayout-Column {
    padding-right: 0.375em;
    padding-left: 0.375em;
  }
}

.ColumnarLayout_padding_nano {
  margin-right: -0.25em;
  margin-left: -0.25em;

  & > .ColumnarLayout-Column {
    padding-right: 0.25em;
    padding-left: 0.25em;
  }
}

.ColumnarLayout_padding_pico {
  margin-right: -0.03125em;
  margin-left: -0.03125em;

  & > .ColumnarLayout-Column {
    padding-right: 0.03125em;
    padding-left: 0.03125em;
  }
}

.ColumnarLayout_padding_small {
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  & > .ColumnarLayout-Column {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.ColumnarLayout_padding_modest {
  margin-right: -0.625rem;
  margin-left: -0.625rem;

  & > .ColumnarLayout-Column {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

.ColumnarLayout_padding_big {
  margin-right: -1.5rem;
  margin-left: -1.5rem;

  & > .ColumnarLayout-Column {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.ColumnarLayout_flexDirection_column,
.ColumnarLayout_flexDirection_column-reverse {
  margin: -1rem 0;

  & > .ColumnarLayout-Column {
    padding: 1rem 0;
  }

  &.ColumnarLayout_fluid {
    width: auto;

    /* TODO: WTF? */
    height: calc(100% + 2rem);
  }

  &.ColumnarLayout_padding_no {
    margin: 0;

    &.ColumnarLayout_fluid {
      height: 100%;
    }

    & > .ColumnarLayout-Column {
      padding: 0;
    }
  }

  &.ColumnarLayout_padding_modest {
    margin: -0.625rem 0;

    & > .ColumnarLayout-Column {
      padding: 0.625rem 0;
    }
  }

  &.ColumnarLayout_padding_nano {
    margin: -0.5rem 0;

    & > .ColumnarLayout-Column {
      padding: 0.5rem 0;
    }
  }

  &.ColumnarLayout_padding_pico {
    margin: -0.0625rem 0;

    & > .ColumnarLayout-Column {
      padding: 0.0625rem 0;
    }
  }
}

.ColumnarLayout_direction_column {
  flex-direction: column;
}

.ColumnarLayout_direction_column-reverse {
  flex-direction: column-reverse;
}

.ColumnarLayout_justifyContent_space-between {
  justify-content: space-between;
}

.ColumnarLayout_justifyContent_center {
  justify-content: center;
}

.ColumnarLayout_flexWrap_wrap {
  flex-wrap: wrap;
}

/* Legacy */

.ColumnarLayout_resetBasis {
  .ColumnarLayout-Column {
    flex-basis: initial;
  }
}

.ColumnarLayout_ieInlineBlock {
  .ie & {
    &,
    .ColumnarLayout,
    .ColumnarLayout-Column {
      display: inline-block;
    }
  }
}

.ColumnarLayout-Column_ieTooltip {
  .ie & {
    margin-bottom: -0.125em;
    line-height: 1;
    vertical-align: bottom;
  }
}

.ColumnarLayout-Column_sticky {
  position: sticky;
  top: 48px;
  height: fit-content;
}
