.PlainNotification_phone {
  position: relative;
  margin: -1px 0;
}

.PlainNotification_phone:first-child::before,
.PlainNotification_phone::after {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--background-border);
  content: '';
}

.PlainNotification_phone:first-child::before {
  top: 0;
}

.PlainNotification_phone::after {
  bottom: 0;
}
