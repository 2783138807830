.ActionNotification-Body {
  background-color: var(--primary-surface-65);
  transition: background-color var(--animation-speed-default) var(--animation-easing-default);

  @media screen and (prefers-color-scheme: dark) {
    background-color: var(--primary-surface-85);
  }
}

.ActionNotification-Body_disabled {
  background-color: var(--primary-surface-45);
  transition: none;

  @media screen and (prefers-color-scheme: dark) {
    background-color: var(--primary-surface-65);
  }
}

.ActionNotification-Link {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ActionNotification-Link:hover + .ActionNotification-Body {
  background-color: var(--primary-surface-85);
  transition: none;

  @media screen and (prefers-color-scheme: dark) {
    background-color: var(--primary-default);
  }
}

.ActionNotification-Link + .ActionNotification-Body .Notification-LinkInner {
  position: relative;
  z-index: 2;
}
