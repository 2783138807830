.TimePeriodField {
  .Slider_phone {
    .Slider-ValueAsCaption {
      position: absolute;
      top: 1.5em;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
}

.ie {
  .TimePeriodField {
    .Slider-ValueAsCaption {
      .ColumnarLayout {
        display: flex;
      }
    }
  }
}
