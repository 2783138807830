.ReportFormatIcon {
  margin: calc(-1 * var(--report-format-icon-size, 0) / 2) 0;
}

.ReportFormatIcon .icon-body {
  fill: var(--content-on-background-minor);
}

.ReportFormatIcon .icon-outline {
  fill: var(--background-border);
  fill-opacity: 1 !important;
  opacity: 1 !important;
}
