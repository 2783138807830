.MobileLayoutFooterButton {
  padding: 12px 0;
  padding-bottom: 24px;
  pointer-events: none;
}

.MobileLayoutFooterButton + .MobileLayoutFooterButton {
  padding-top: 0;
}

.MobileLayoutFooterButton:first-child {
  padding-top: 16px;
}

.MobileLayoutFooterButton:last-child {
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

.MobileLayoutFooterButton-Background {
  border-radius: 5.2px; /* it's like button default border-radius radius, but in pixels, it has to be with decimals to match it exactly */
  background-color: var(--background-default);
  pointer-events: initial;
}

.MobileLayoutFooterButton_opaque {
  background-color: var(--background-default);
}
