.Question_Title_wrapper {
  min-height: 152px;
}

.Question_Headline_help {
  vertical-align: middle;
}

.Question_Answers_wrapper {
  width: 100%;
  min-height: 200px;
}

.Question_Answers {
  width: 100%;
}

.Question_Selectable_card {
  display: flex;
  height: 100%;
  align-items: flex-start;
}

.Question_Selectable_card_center {
  justify-content: center;
}
