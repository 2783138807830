.AnimatedVisibilityToggle {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--animation-speed-slow) var(--animation-easing-default);
}

.AnimatedVisibilityToggle_visible {
  opacity: 1;
  pointer-events: inherit;
}
