.LegendMark {
  display: inline-flex;
  align-items: center;
}

.LegendMark-Color {
  display: inline-block;
  width: 2.125em;
  height: 0.375em;
  opacity: 0.375;
}

.LegendMark-Text {
  margin-left: 0.5em;
}
