.BuySellTabs_desktop {
  .BuySellTabs-Tab {
    padding-bottom: 8px;
  }
}

.BuySellTabs_phone {
  margin-top: -24px;

  .BuySellTabs-Tab {
    font-size: 16px;
  }
}
