.LifePlanBanner {
    position: relative;
    overflow: hidden;
    height: 160px;
}

.LifePlanBanner_Image {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
}

.LifePlanBanner_Content {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
}
