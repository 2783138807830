.StoryThumbnail {
  --new-story-thumbnail-border-radius: 6px;

  display: flex;
  max-width: 104px;
  height: 100% !important;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: flex-start !important; /* override glider classes */
  border: 0;
  border-radius: var(--new-story-thumbnail-border-radius);
  margin: 0 6px;
  aspect-ratio: 1 / 1;
  background-color: var(--primary-default);
  background-size: cover;
  box-shadow:
    var(--shadow-level-1),
    0 0 0 1px var(--background-transparent);
  cursor: pointer;
  transition: box-shadow var(--animation-speed-default) var(--animation-easing-default);
  user-select: none;
}

.StoryThumbnail:first-child {
  margin-left: 0;
}

.StoryThumbnail:last-child {
  margin-right: 0;
}

.StoryThumbnail:focus {
  outline: none;
}

.StoryThumbnail:focus-visible {
  box-shadow:
    var(--shadow-level-2),
    0 0 0 1px var(--background-default),
    0 0 0 2px var(--content-on-background-default);
  transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
}

.StoryThumbnail:hover {
  box-shadow:
    var(--shadow-level-1),
    0 0 0 1px var(--background-default),
    0 0 0 4px var(--primary-surface-45);
  transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
}

.StoryThumbnail:active,
.StoryThumbnail:hover:active,
.StoryThumbnail:focus-visible:active {
  display: flex;
  box-shadow:
    var(--shadow-level-1),
    0 0 0 1px var(--background-default),
    0 0 0 4px var(--secondary-surface-65);
}

.StoryThumbnail-Text {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  width: 100%;
  max-height: 100%;
  padding-bottom: 8px;
  -webkit-box-orient: vertical;
  hyphens: auto;
  isolation: isolate;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  overflow-wrap: anywhere;
  padding-inline: 4px;
  text-wrap: balance;
}

.StoryThumbnail-Text::before {
  position: absolute;
  z-index: -1;
  display: block;
  border-radius: var(--new-story-thumbnail-border-radius);
  background: linear-gradient(180deg, rgba(0 0 0 / 0%) 0%, rgba(0 0 0 / 0.65%) 100%);
  content: '';
  inset: 0;
}

.StoryThumbnail_mobile {
  margin-right: 3px;
}

.StoryThumbnail_wide {
  width: 228px !important; /* to overlap Glider's style attribute */
  max-width: 100%;
  aspect-ratio: 2.1923 / 1;
}
