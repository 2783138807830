.Header {
  position: relative;
  z-index: 999;
  width: 100%;
  flex-shrink: 0;
  font-size: 0.875em;

  .Sticked &,
  &.Header_withShadow,
  &.HideOnPhones {
    box-shadow: var(--shadow-level-2);
  }
}

.Header-Sticked {
  background-color: var(--background-default);
}

.Header-Inner {
  position: relative;
  display: flex;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: space-between;
}

.Header-Sections {
  display: inherit;
  height: 100%;
  flex-direction: inherit;
  flex-grow: 1;
  align-items: inherit;
  justify-content: inherit;
  margin: 0 -1rem;
}

.Header-Section {
  display: inherit;
  flex-direction: inherit;
  align-items: inherit;
  justify-content: inherit;
}

.Header-Right {
  right: 1rem;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: -16px;
    white-space: nowrap;
  }

  > div > .Link:not(.PlainLink),
  > .Link:not(.PlainLink) {
    display: inline-block;
    padding: 6px 14px;

    > .Icon {
      margin-top: -2px;
    }
  }
}

.Header-RightInner {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 16em;
  }
}

.Header-Menu {
  display: flex;
  width: 100%;
}

.Header-Drawer {
  font-size: 1.1428em;

  .Header-Inner {
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.5;
  }

  .Header-Section {
    width: 100%;

    &_flex {
      flex: 1 1 0;
      justify-content: flex-start;
    }
  }

  .Header-Greetings {
    width: 100%;
  }

  .Header-GreetingsText {
    line-height: 1.3;
  }
}

.Header_desktop {
  font-size: 1em;

  .Header-Greetings {
    margin-right: 1.1428em;
    font-size: 0.875em;
    text-align: right;
  }

  .Header-MenuLinks {
    margin-right: -1rem;
  }

  .Header-MenuItem {
    padding-right: 1.25rem;
  }

  .Header-DropdownTab {
    padding-right: 2em;
    padding-left: 2em;

    .Dropdown_Label {
      display: block;
      padding-top: 1px;
    }

    &:hover {
      .Dropdown_Label {
        .Dropdown-Link {
          color: var(--content-on-background-default);
        }

        &::after {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          height: 0.25em;
          background: var(--secondary-default);
          content: '';
          pointer-events: none;
          transition: background var(--animation-speed-default) var(--animation-easing-default);
        }
      }
    }
  }

  .Header-DropdownTab .Dropdown-Link.Link {
    color: var(--content-on-background-additional);
  }

  .Header-DropdownTab .Dropdown-Link .Dropdown-Icon {
    fill: var(--content-on-background-additional) !important;
  }

  .Header-MenuFooter {
    padding-right: 0.25rem;
  }

  .Header-Right {
    position: static;
    right: 0;
    line-height: initial;

    .Button_theme_round {
      margin-right: -0.125em;
    }
  }

  .Header-Inner {
    position: static;
    height: 3.5rem;
    align-items: center;
    justify-content: space-between;

    .macOsX & {
      transform: none;
    }
  }

  .Header-Section {
    height: 100%;

    &:last-child {
      justify-content: flex-end;
    }
  }

  .Header-Logo {
    min-width: 12rem;
  }

  .Header-Menu {
    width: auto;
    height: 100%;
    align-self: flex-start;

    /* Align first menu item to left edge of dashboard content */
    transform: translateX(-1.25rem);
  }
}

.Header_phone {
  .Header-Inner {
    height: 48px;
  }
}

.Header-Line {
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin: 1.5rem 0;
}

.Header-Drawer_wider {
  .Drawer-Inner {
    max-width: calc(100% - 1.25rem);
  }
}

.UserGreetings {
  .SvgIcon svg {
    max-width: 16px;
  }
}

.UserGreetings-Username {
  @media (width <= 1250px) {
    display: inline-block;
    overflow: hidden;
    max-width: 110px;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
  }

  @media (width <= 1180px) {
    max-width: 60px;
  }
}
