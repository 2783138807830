.PortfolioTypeCard {
  position: relative;
  overflow: hidden;
  background: var(--background-card);
}

.PortfolioTypeCard-Line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6px;
}

.PortfolioTypeCard-Line_DIY {
  background-color: var(--secondary-default);
}

.PortfolioTypeCard-Line_MANAGED {
  background-color: var(--primary-default);
}

.PortfolioTypeCard-Line_MANAGED_DEFAULT {
  background-color: var(--primary-default);
}

.PortfolioTypeCard-Line_SELF_SELECTED {
  background-color: var(--self-selected-default);
}

.PortfolioTypeCard-Icon {
  margin: auto;
}

.PortfolioTypeCard-IconContainer {
  position: relative;
}

.PortfolioTypeCard-Badge {
  position: absolute;
  top: 26px;
  right: 0;
}
