.WithLabel {
  .WithLabel-Field {
    margin-right: 0.5em;
  }
}

.WithLabel_size_normal {
  margin-bottom: -2px;

  .WithLabel-Field {
    margin-top: 2px;
  }
}

.WithLabel_size_smaller {
  margin-bottom: -1px;
}
