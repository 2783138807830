@keyframes moveBox {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(10px);
  }
}

@keyframes moveOpenBox1Desktop {
  0% {
    transform: 0;
  }

  50% {
    transform: translate3d(0, 150%, 0) scale3d(1.8, 1.8, 1);
  }

  100% {
    transform: translate3d(0, -240%, 0) scale3d(1.8, 1.8, 1);
  }
}

@keyframes moveOpenBox2Desktop {
  0% {
    transform: 0;
  }

  50% {
    transform: translate3d(0, 150%, 0) scale3d(1.8, 1.8, 1);
  }

  100% {
    transform: translate3d(0, 150%, 0) scale3d(1.8, 1.8, 1);
  }
}

@keyframes moveOpenBox1Mobile {
  0% {
    transform: 0;
  }

  50% {
    transform: translate3d(0, calc(var(--vh, 1vh) * 40), 0) scale3d(1.8, 1.8, 1);
  }

  100% {
    transform: translate3d(0, -240%, 0) scale3d(1.8, 1.8, 1);
  }
}

@keyframes moveOpenBox2Mobile {
  0% {
    transform: 0;
  }

  50% {
    transform: translate3d(0, calc(var(--vh, 1vh) * 40), 0) scale3d(1.8, 1.8, 1);
  }

  100% {
    transform: translate3d(0, calc(var(--vh, 1vh) * 40), 0) scale3d(1.8, 1.8, 1);
  }
}

.ClaimBonusBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center flex-end;
}

.ClaimBonusBox-StarsHolder {
  position: absolute;
}

.ClaimBonusBox-Star1,
.ClaimBonusBox-Star2,
.ClaimBonusBox-Star3 {
  position: absolute;
  transition:
    filter 4s var(--animation-easing-default),
    margin 4s var(--animation-easing-default);
  will-change: filter, margin;
}

.ClaimBonusBox-Star1 {
  margin: -228px 0 0 68px;
}

.ClaimBonusBox-Star2 {
  margin: -180px 0 0 -156px;
}

.ClaimBonusBox-Star3 {
  margin: -97px 0 0 112px;
}

.ClaimBonusBox-Box1,
.ClaimBonusBox-Box2 {
  position: absolute;
  animation: 4s linear infinite moveBox;
  transition:
    opacity var(--animation-speed-slow) var(--animation-easing-default),
    transform var(--animation-speed-slow) var(--animation-easing-default);
  will-change: opacity, transform;
}

.ClaimBonusBox-Box1 {
  clip-path: inset(0 0 54.5% 0);
  opacity: 1;
}

.ClaimBonusBox-Box2 {
  clip-path: inset(45% 0 0 0);
}

.ClaimBonusBox-Value {
  color: var(--primary-default);
  opacity: 0;
  transition:
    opacity var(--animation-speed-slow) var(--animation-easing-default),
    transform var(--animation-speed-slow) var(--animation-easing-default);
  will-change: opacity, transform;
}

.ClaimBonusBox-Button {
  transform: translate3d(0, 380%, 0) scale3d(0.35, 0.35, 1);
  transition: transform var(--animation-speed-slow) var(--animation-easing-default);
  will-change: transform;
}

.ClaimBonusBox-Preloader {
  filter: brightness(1.25);
  transform: scale3d(0.75, 0.75, 1) translate3d(0, -2px, 0);
}

.ClaimBonusBox_open {
  .ClaimBonusBox-Box1 {
    animation: 1.5s ease-in-out forwards moveOpenBox1Desktop;
    will-change: transform;
  }

  .ClaimBonusBox-Box2 {
    animation: 1.5s ease-in-out forwards moveOpenBox2Desktop;
    will-change: transform;
  }

  .ClaimBonusBox-Value {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(2, 2, 1);
    transition-delay: 1.9s;
  }

  .ClaimBonusBox-Button {
    transform: translate3d(0, 75%, 0) scaleX(1);
    transition-delay: 2.3s;
  }
}

.ClaimBonusBox_desktop {
  width: 312px;
  height: 229px;

  &.ClaimBonusBox_open {
    .ClaimBonusBox-Star1 {
      margin: -203px 0 0 -155px;
    }

    .ClaimBonusBox-Star2 {
      margin: -97px 0 0 -207px;
    }

    .ClaimBonusBox-Star3 {
      margin: -136px 0 0 149px;
    }
  }
}

.ClaimBonusBox_phone {
  width: calc(100vw - 16px * 2);
  height: 64px;

  .ClaimBonusBox-Button {
    transform: translate3d(0, 500%, 0) scale3d(1, 1, 1);
  }

  &.ClaimBonusBox_open {
    .ClaimBonusBox-Box1 {
      animation: 1.5s ease-in-out forwards moveOpenBox1Mobile;
    }

    .ClaimBonusBox-Box2 {
      animation: 1.5s ease-in-out forwards moveOpenBox2Mobile;
    }

    .ClaimBonusBox-Star1 {
      margin: -215px 0 0 -135px;
    }

    .ClaimBonusBox-Star2 {
      margin: -19px 0 0 -98px;
    }

    .ClaimBonusBox-Star3 {
      margin: -71px 0 0 107px;
    }

    .ClaimBonusBox-Button {
      transform: translate3d(0, calc(var(--vh, 1vh) * 20 - 40px), 0) scale3d(1, 1, 1);
      transition-delay: 2.3s;
    }
  }
}
