.InlineHelp {
  position: relative;
  display: inline-flex;
  width: 2em;
  height: 2em;
  align-items: center;
  justify-content: center;
  margin: -2em 0;
  cursor: default;
  cursor: pointer;
  vertical-align: middle;

  &::before {
    position: absolute;
    display: block;
    width: 2.75rem;
    height: 2.75rem;
    margin: auto;
    content: '';
    inset: -100%;
  }
}

.InlineHelp-Icon {
  position: relative;
  top: -0.0625em;
  flex-shrink: 0;
  margin-left: 0.5rem;
  cursor: pointer;

  svg {
    overflow: visible;
  }
}
