.Menu_MenuSection {
  background: var(--background-card-opaque);
}

.Menu_MenuSection_desktop {
  border-radius: 16px;
  box-shadow: var(--shadow-level-4);
}

.Menu_MenuSection_mobile {
  border-top: 1px solid var(--background-border);
}

.Menu_MenuSection_mobile.Menu_MenuSection_dark_theme {
  background: var(--background-default);
}
