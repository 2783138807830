.FilterGroup {
  display: flex;
  align-items: center;
}

.FilterGroup > * {
  flex: 0 1 0;
}

.FilterGroup-MoreButton > * {
  padding: 9px 12px !important;
}

.FilterGroup_fitContent {
  width: fit-content;
}

.FilterGroup_wrap {
  flex-wrap: wrap;
  margin: -4px;

  > * {
    padding: 0 !important;
    margin: 4px;
  }
}
