.DateRangeInputs-Input {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 9px;
  border: 1px solid var(--background-border);
  border-radius: 4px;
  appearance: none;
  background: var(--background-default);
  color: var(--content-on-background-default);
  font-family: var(--font-family-default);
  font-size: 16px;
  line-height: 20px;
  outline: none;
}

.DateRangeInputs-Input:focus-visible {
  border-color: var(--primary-default);
}

.DateRangeInputs-Input:not(.DateRangeInputs-Input_dark) {
  color-scheme: light;
}

.DateRangeInputs-Input_dark {
  color-scheme: dark;
}
