.RiskField {
  .Slider-ValueAsCaption {
    .ColumnarLayout {
      display: flex;
      overflow: inherit;
    }
  }

  .Slider_phone:not(.Slider_absolute) {
    margin-bottom: 0;

    & + .SliderWTicks-Ticks {
      top: 2.25em;

      .SliderWTicks-Tick_recommended::after {
        top: 1.3em;
      }
    }

    .Slider-ValueAsCaption {
      height: auto;
      padding-top: 5.5px;
      padding-bottom: 5.5px;
    }
  }
}

.ie {
  .RiskField {
    .Slider-ValueAsCaption {
      .ColumnarLayout {
        display: flex;
      }
    }
  }
}
