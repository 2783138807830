.DonutChart {
  position: relative;
}

.DonutChart,
.DonutChart-Svg {
  overflow: hidden;
}

.DonutChart-Child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
