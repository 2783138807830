.MaxLines {
  position: relative;
  overflow: hidden;
}

.MaxLines-Gradient {
  display: none;
  pointer-events: none;
}

.MaxLines_exceeds {
  .MaxLines-Gradient {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 10em;
  }
}
