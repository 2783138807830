.ElementHighlighter {
  border-radius: 0;
  outline: 12px solid transparent;
}

.ElementHighlighter_transition  {
  transition: outline-color 0.3s ease, background-color 0.3s ease;
}

.ElementHighlighter_outline {
  outline: 12px solid var(--secondary-surface-10);
}

.ElementHighlighter_background {
  background-color: var(--secondary-surface-10);
}
