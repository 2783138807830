.IncomeTwrChart-Chart {
  padding-bottom: 1.6em;
}

.IncomeTwrChart-Legend {
  align-items: center;
}

.IncomeHTwrChart-Empty {
  /* Hack to rewrite Chart’s css specificity */

  &&& {
    padding-left: 0;
  }
}
