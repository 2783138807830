.DividendsChart {
  position: relative;
}

.DividendsChart-DividendsReceived {
  border: 1px solid var(--background-border);
  border-radius: 6px;
  background: var(--background-default);
}

.DividendsChart-Legend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.DividendsChart-Chart {
  padding-bottom: 1.6em;
}

.DividendsChart-PointOfInterestCard {
  padding-left: 0 !important;
}

.DividendsChart-Empty {
  /* Hack to rewrite Chart’s css specificity */
  &&& {
    padding-left: 0;
  }
}

.DividendsChart-NoDividendsPlaceholder {
  position: absolute;
  border: 2px dashed var(--background-border);
  border-radius: 6px;
  background: var(--background-default);
  inset: 0;
}
