.Term {
  width: 100%;
}

.Term-Core {
  margin: -0.25em -0.25em -0.25em 0;
}

.Term-Info {
  width: 100%;
}

.Term-TimePeriod {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0.5em 0;
}

.Term-TimePeriod_center {
  justify-content: center;
}

.Term-Icon {
  margin: -0.5em 0.125em -0.5em -0.5em;
}

.Term-Tags {
  color: var(--content-on-background-default);
  font-size: 0.875em;
}

.Term-Label {
  padding: 0.375em 0 0.25em;
  font-size: 0.875em;
  opacity: 0.5;
}

.Term-TagsHolder {
  width: 100%;
}

.Term-Tag {
  display: inline;

  &::after {
    content: ', ';
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.Term-Description {
  margin-top: 1.5em;
  color: var(--content-on-background-default);
  font-size: 0.875em;
}
