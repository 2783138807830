.AltusProgressBar-ProgressBar,
.AltusProgressBar-WarningMessage {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}

.AltusProgressBar-Card {
  background-color: var(--accent-default);
}

.AltusProgressBar-Step {
  position: relative;
  padding-bottom: 16px;

  &:first-child {
    .AltusProgressBar-PointName {
      left: 0;
    }
  }

  &:last-child {
    .AltusProgressBar-PointName {
      right: 0;
      left: unset;
    }
  }

  &:not(:last-child)::before {
    position: absolute;
    top: 6px;
    left: 14px;
    width: 9999px;
    height: 4px;
    background-color: var(--accent-action);
    content: '';
  }
}

.AltusProgressBar-Step_active {
  .AltusProgressBar-Point {
    background-color: var(--content-on-color-white);
  }
}

.AltusProgressBar-Step_completed {
  .AltusProgressBar-Point {
    background-color: var(--secondary-default);
  }

  &:not(:last-child)::before {
    background-color: var(--secondary-default);
  }
}

.AltusProgressBar-Step_warning {
  .AltusProgressBar-Point {
    background-color: var(--status-warning);
  }
}

.AltusProgressBar-Point {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: var(--accent-action);
}

.AltusProgressBar-Point_warning {
  background-color: var(--status-warning);
}

.AltusProgressBar-PointName {
  position: absolute;
  right: -500px;
  bottom: 0;
  left: -500px;
}

.AltusProgressBar-Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.AltusProgressBar-Info {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.AltusProgressBar-Title {
  display: flex;
}

.AltusProgressBar-Name {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
