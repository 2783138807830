.ProgressBar {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}

.ProgressBar-Point {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #6B6BF0;
}

.ProgressBar-Step {
  position: relative;
  padding-bottom: 16px;

  &:first-child {
    .ProgressBar-StepName {
      left: 0;
    }
  }

  &:last-child {
    .ProgressBar-StepName {
      right: 0;
      left: unset;
    }
  }
}

.ProgressBar-Step:not(:last-child)::before {
  position: absolute;
  top: 6px;
  left: 16px;
  width: 9999px;
  height: 4px;
  background-color: #6B6BF0;
  content: "";
}

.ProgressBar-Step_completed {
  &:not(:last-child)::before {
    background-color: var(--secondary-default);
  }
}

.ProgressBar-Point_active {
  background-color: white;
}

.ProgressBar-StepName {
  position: absolute;
  right: -500px;
  bottom: 0;
  left: -500px;
  white-space: nowrap;
}
