@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes anti-rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.Combobox {
  position: relative;
  outline: none;

  .Combobox-List {
    position: absolute;
    z-index: 999;
    top: 100%;
    overflow: scroll;
    width: 100%;
    max-height: 12em;
    border-width: 1px;
    border-top: 0;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .Combobox-ListItem {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-right: 0.75em;
    padding-left: 0.75em;
    border-right: 0;
    border-left: 0;
    margin-top: -1px;
    background: transparent;
    color: var(--content-on-background-default);
    outline: none;
    transition: all var(--animation-speed-default) var(--animation-easing-default);

    &:first-child {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover,
    &:focus-visible,
    &:active,
    &:hover:active {
      z-index: 2;
      transition:
        background-color var(--animation-speed-zero) var(--animation-easing-default),
        color var(--animation-speed-zero) var(--animation-easing-default);
    }

    &:hover,
    &:focus-visible {
      background-color: var(--primary-surface-85);
      color: var(--content-on-color-default);
    }

    &:active,
    &:hover:active,
    &:focus-visible:active {
      background-color: var(--primary-action);
      color: var(--content-on-color-default);
    }
  }

  .Combobox-Input {
    padding-right: 2em;
  }
}

.Combobox_haveItems {
  .Combobox-Input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.Combobox_loading {
  &::before,
  &::after {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    display: block;
    width: 1em;
    height: 1em;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    content: '';
    opacity: 0.5;
  }

  &::before {
    border-right-color: var(--primary-default);
    border-bottom-color: var(--primary-default);
    animation: rotate-animation 1s linear var(--animation-speed-zero) infinite;
  }

  &::after {
    border-right-color: var(--secondary-default);
    border-bottom-color: var(--secondary-default);
    animation: anti-rotate-animation 0.625s linear var(--animation-speed-zero) infinite;
  }
}
