.SuitablePortfolio {
  position: relative;
  display: inline-block;
  margin: -100% -1px -100% 4px;
  vertical-align: middle;
}

.SuitablePortfolio_fixForOldEditGoal {
  bottom: -2px;
}

.SuitablePortfolio::after {
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  margin: auto;
  content: '';
  inset: -9999px;
}
