.MobileLayout {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  padding: 0;
  margin: 0;

  * {
    -webkit-tap-highlight-color: var(--background-transparent);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
  }
}

.MobileLayout-Element {
  display: flex;
}

.MobileLayout-Header {
  display: block;
}

.MobileLayout-Content {
  display: flex;
  overflow: hidden scroll;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
}

.MobileLayout-Footer {
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.MobileLayout-PhantomFooter * {
  opacity: 0;
  pointer-events: none !important; /* to override the value of child components */
}
