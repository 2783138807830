.SvgIcon {
  display: block;

  svg {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.SvgIcon_fluid {
  &,
  svg {
    width: 100%;
    height: 100%;
  }
}

.SvgIcon_fill {
  * {
    fill: inherit;
  }
}
