.AssetOneliner {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.AssetOneliner-Color {
  display: inline-block;
  width: 0.75em;
  height: 0.75em;
  border-radius: 0.375em;
  margin-right: 0.5em;
}

.AssetOneliner-Amount {
  margin-right: 0.375em;
}

.AssetOneliner-Name {
  font-size: 0.75em;
}

.AssetOnelinerGroup {
  overflow: hidden;
  height: 100%;
}

.AssetOnelinerGroup_ie-flex {
  .ie &.ColumnarLayout {
    display: flex;
  }
}

.AssetOnelinerGroup_right {
  display: block;
  text-align: right;

  .ColumnarLayout-Column {
    display: inline-block;
    text-align: right !important;
  }
}
