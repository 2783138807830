.ScrollToElementIfOutsideViewport {
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 4rem;
  left: 0;
  width: fit-content;
  margin: auto;
  transition:
    opacity var(--animation-speed-default) var(--animation-easing-default),
    transform var(--animation-speed-default) var(--animation-easing-default);
}

.ScrollToElementIfOutsideViewport-Button {
  border-radius: 9999px;
  box-shadow: var(--shadow-level-5);
}

.ScrollToElementIfOutsideViewport_enter,
.ScrollToElementIfOutsideViewport_leave {
  opacity: 0;
  transform: translateY(100%);
}

.ScrollToElementIfOutsideViewport_enter_active {
  opacity: 1;
  transform: translateY(0);
}

.ScrollToElementIfOutsideViewport_leave_active {
  opacity: 0;
  transform: translateY(100%);
}
