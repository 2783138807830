.FileUpload {
  width: 100%;
}

.FileUpload-Label {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-top: 2em;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto;

  & + .FileUpload-DropzoneHolder {
    margin-top: 0.875em;
  }
}

.FileUpload-DropzoneHolder {
  display: flex;
  width: 100%;
  flex: 1;
  margin: 0 auto;
}

.FileUpload-Dropzone {
  all: initial;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  border: 2px dashed var(--background-border);
  border-radius: 0.75rem;
  color: var(--content-on-background-default);
  cursor: pointer;
  font-family: inherit;
  font-size: initial;
  font-weight: inherit;
  transition: border var(--animation-speed-default) var(--animation-easing-default);
}

.FileUpload-Preloader {
  z-index: 3;
}

.FileUpload-Preloader_status {
  inset: -0.5rem;
}

.FileUpload-ImageHolder {
  position: relative;
  overflow: hidden;
}

.FileUpload-Image {
  padding: 1em 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  > .Icon {
    font-size: 0;
    text-align: center;

    svg {
      width: 6.25rem;
      height: 6.25rem;
    }
  }
}

.FileUpload-UploadedImage {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
}

.FileUpload-Status {
  position: absolute;
  top: 1em;
  bottom: 1em;
  left: 50%;
  width: 6.25rem;
  height: 6.25rem;
  box-sizing: border-box;
  padding: 1em;
  background: transparent;
  color: var(--content-on-color-default);
  text-align: center;
  transform: translateX(-50%);
}

.FileUpload-Status_done,
.FileUpload-Status_failed,
.FileUpload-Status_pending {
  border-radius: 50%;
  background: var(--status-success-45);
}

.FileUpload-Status_failed {
  background: var(--status-error-45);
}

.FileUpload-Status_loading {
  background: var(--background-default);
}

.FileUpload-Message {
  padding: 0 1em;
}

.FileUpload-Dropzone:hover,
.FileUpload-Dropzone:focus-visible {
  border-color: var(--primary-surface-45);
  transition: border var(--animation-speed-zero) var(--animation-easing-default);
}

.FileUpload-ButtonHolder {
  padding: 1em 1.5em 2rem;
  text-align: center;
}

.FileUpload_disabled {
  .FileUpload-Dropzone {
    border-color: transparent;
    pointer-events: none;
  }

  .FileUpload-Button {
    visibility: hidden;
  }
}

.FileUpload_flex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  .FileUpload-Label {
    flex-grow: 1;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
}

.FileUpload_desktop {
  .FileUpload-Dropzone {
    background-color: var(--background-default);
  }

  .FileUpload-Label {
    & + .FileUpload-DropzoneHolder {
      margin-top: 2em;
    }
  }
}

.FileUpload_phone {
  width: auto;

  .Card_phone {
    & + & {
      margin-top: 1em;
    }
  }

  .FileUpload-Label {
    width: 100%;
    max-width: none;
    box-sizing: border-box;
    padding: 0;

    &.Text {
      padding-right: 1rem;
      font-size: 1rem;
      line-height: 1.2;
    }
  }

  .FileUpload-Dropzone {
    border-style: none;
  }

  .FileUpload-Status {
    top: 0;
    bottom: 0;
    left: 0;
    transform: none;
  }

  .FileUpload-ButtonHolder {
    padding: 1em 0 0;
    text-align: left;
  }

  .FileUpload-LabelHolder {
    display: flex;

    .FileUpload-ImageHolder,
    .FileUpload-Image {
      position: relative;
      width: 4.375rem;
      height: 4.375rem;
      flex-basis: 4.375rem;
      flex-shrink: 0;
      padding: 0;
    }

    .FileUpload-ImageHolder {
      margin: -0.125em 0 0 -0.175em;
    }

    .FileUpload-Image,
    .FileUpload-UploadedImage,
    .FileUpload-Status {
      overflow: hidden;
      width: 4.375rem;
      height: 4.375rem;
      border-radius: 3rem;
    }

    .FileUpload-Image > .Icon {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    .SvgIcon {
      margin-top: -0.1em;
    }
  }

  &.FileUpload_disabled {
    .FileUpload-Button {
      visibility: visible;
    }
  }
}
