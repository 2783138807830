.PriceChart-IncomeLine {
  overflow: hidden;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: -5px;
  margin-left: -5px;
}

.PriceChart-Empty {
  /* Hack to rewrite Chart’s css specificity */
  &&& {
    box-sizing: border-box;
    padding-left: 0;
    border: 2px dashed var(--background-border);
    border-radius: 8px;
  }
}

.PriceChart-NumeralAxis {
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);
}

.PriceChart_tooltipShown .PriceChart-NumeralAxis {
  opacity: 0;
  transition: opacity var(--animation-speed-zero) var(--animation-easing-default);
}

.PriceChart_empty .PriceChart-Chart {
  padding-bottom: 49px;
}

.PriceChart_desktop.PriceChart_empty .PriceChart-Chart {
  padding-bottom: 73px;
}
