.LegendNumber {
  display: inline-flex;
  align-items: center;
}

.LegendNumber-Tooltip {
  margin-bottom: -0.125em;
  line-height: 0;
  vertical-align: text-top;

  .ie & {
    width: 1.1875em;
  }
}

.LegendNumber-TooltipIcon {
  .ie & {
    width: 1.25em;
  }
}

.LegendNumber-Text {
  display: flex;
  align-items: center;
  margin-top: 0.125em;
  margin-right: 0.75em;
}

.LegendNumber-Values {
  display: flex;

  > .LegendNumber-Value:not(:first-child) {
    &::before {
      display: inline-block;
      width: 0.0625em;
      height: 0.675em;
      margin-right: 0.5em;
      margin-left: 0.5em;
      background-color: var(--background-border);
      content: '';
    }
  }
}

.LegendNumber_multiline {
  flex-direction: column;

  > .LegendNumber-Value:not(:first-child) {
    &::before {
      content: none;
    }
  }
}

.LegendNumber_desktop {
  .LegendNumber-Tooltip {
    margin-left: 0.5em;
  }
}

.LegendNumber_phone {
  display: flex;
  flex-direction: column;

  .LegendNumber-Tooltip {
    margin-bottom: -0.25em;
  }

  &.LegendNumber_left {
    align-items: flex-start;
  }

  .LegendNumber-Text {
    margin: 0.1em 0;
    margin-right: 0.5rem;
    font-size: 0.875em;
  }

  &:not(.LegendNumber_oneline) {
    &.LegendNumber_right {
      align-items: flex-end;

      .Tooltip-Tip {
        right: -1em;
        left: unset;

        .ie & {
          left: auto;
        }
      }
    }

    .LegendNumber-Text {
      margin-right: 0;
    }
  }

  .LegendNumber-Values {
    margin: 0.1em 0;

    > *:not(:first-child)::before {
      height: 0.75em;
      margin-right: 0.375em;
      margin-left: 0.375em;
    }
  }

  &.LegendNumber_oneline:not(.LegendNumber_multiline) {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }
}
