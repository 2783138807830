.DesktopLayout {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-height: 100vh;
  flex-flow: column;
  padding: 0;
  margin: 0;
}

.DesktopLayout-Element {
  width: 100%;
  margin: 0 auto;
}

.DesktopLayout-Header {
  z-index: 2;
}

.DesktopLayout-Element_scrollable {
  display: block;
  overflow: hidden scroll;
  flex-direction: column;
}

.DesktopLayout-Content {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 544px;
  flex-direction: column;
  flex-grow: 2;
  margin: 0 auto;
}

.DesktopLayout-Content_withGap {
  padding-top: 40px;
}

.DesktopLayout-Footer {
  display: block;
}

.DesktopLayout-Footer_withGap {
  margin-top: 120px;
}

.DesktopLayout_fullSize {
  width: 100vw;
  height: 100vh;
}
