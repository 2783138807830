.Transaction {
  padding-right: 0;
  padding-left: 0;
  border-top: 1px solid var(--background-border);
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid var(--background-border);
  }

  &.Transaction_phone {
    padding-right: calc(1em - 0.0625rem);
    padding-left: calc(1em - 0.0625rem);
  }
}

.Transactions-Total {
  margin-top: -16px;
  margin-bottom: -16px;

  .MobileLayout & {
    margin-bottom: -22px;
  }
}

.Transactions-Headline {
  padding-top: 16px;
  padding-right: 0;
  padding-left: 0;
  margin: -0.75em 0;
  margin-top: 0;
}

.Transactions-Preloader {
  position: relative;
  margin-top: 64px;
  margin-bottom: 120px;

  .MobileLayout & {
    bottom: 32px;
    margin-top: 100px;
  }
}

.Pending-Orders {
  padding-right: 0;
  padding-left: 0;
  border-top: 1px solid var(--background-border);
  color: var(--content-on-background-primary);
  cursor: pointer;

  &:hover {
    color: var(--primary-link-hover);
  }

  .MobileLayout & {
    padding: 21px calc(1em - 0.0625rem) 21px calc(1em - 0.0625rem);
  }
}

.Pending-Orders:last-child {
  border-bottom: 1px solid var(--background-border);
}

.Transaction-Icon {
  flex: 0 0 auto;
  margin: -0.8em 0.375em -1.2em -0.425em;
}

.Transaction-Icon_clock {
  margin: -0.8em 0.1em -1.25em -0.8em;
}

.Transaction-Type {
  display: flex;
  align-items: center;

  .Transaction_phone & {
    margin-bottom: 0.35em;
  }

  .Transaction:hover & {
    color: var(--primary-link-hover);
  }
}

.Transaction-State-Wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;

  .Transaction_phone & {
    flex-direction: column;
  }
}

.Transaction-State {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 0.4em;
}

.Transaction-Details {
  position: absolute;
  top: -10px;
  left: 110px;
  display: flex;
  padding: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  .Transaction_phone & {
    position: absolute;
    top: -23px;
  }
}

.Transaction-Money {
  .Transaction_phone & {
    margin-bottom: 0.25em;
  }
}

.Transaction-MoneyPrefix {
  display: inline-block;
  width: 0.34rem;
}

.Transaction-Description {
  margin-top: 1.5em;
  margin-left: 1.75em;

  .Transaction_phone & {
    margin-top: 1.2em;
    margin-left: 0;
  }
}

.Transaction_phone {
  margin-right: -1rem;
  margin-left: -1rem;

  &.Transaction:last-child {
    border-bottom: 0;
  }
}

.Transactions-MobileWrapper {
  margin-right: -1rem;
  margin-left: -1rem;
}
