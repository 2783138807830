._new_SelectableCard {
  position: relative;
  display: block;
  width: 100%;
  padding: initial;
  border: initial;
  border-radius: 6px;
  margin: initial;
  appearance: none;
  background: var(--background-card);
  box-shadow: var(--shadow-level-3);
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  text-decoration: initial;
  transition: box-shadow var(--animation-speed-default) var(--animation-easing-default);
  user-select: text;
  white-space: inherit;

  &:focus {
    outline: none;
  }
}

._new_SelectableCard_flex {
  display: flex;
  align-items: flex-start;
}

._new_SelectableCard:not(._new_SelectableCard_selected, ._new_SelectableCard_error) {
  &:hover {
    box-shadow: var(--shadow-level-4);
    transition: box-shadow var(--animation-speed-fast) var(--animation-easing-default);
  }

  &:focus-visible {
    box-shadow:
      var(--shadow-level-4),
      0 0 0 3px var(--status-success-45);
    transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
  }
}

._new_SelectableCard_selected {
  box-shadow: 0 0 0 2px var(--status-success-65) inset;

  &:hover {
    box-shadow: 0 0 0 1.5px var(--status-success-65) inset;
    transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
  }

  &:focus-visible {
    box-shadow:
      0 0 0 1.5px var(--status-success-65) inset,
      0 0 0 3px var(--status-success-65);
    transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
  }
}

._new_SelectableCard_disabled {
  opacity: 0.8;
  pointer-events: none;

  &,
  &:hover,
  &:focus-visible {
    box-shadow: 0 0 0 1px var(--background-border);
    transition: none;
  }
}

._new_SelectableCard_error {
  box-shadow: 0 0 0 2px var(--status-error-65) inset;

  &:hover {
    box-shadow: 0 0 0 1.5px var(--status-error-65) inset;
    transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
  }

  &:focus-visible {
    box-shadow:
      0 0 0 1.5px var(--status-error-65) inset,
      0 0 0 3px var(--status-error-65);
    transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
  }
}
