/* override ReactVirtualized styles */
.ReactVirtualized__Grid,
.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.VirtualList {
  display: flex;
}

.VirtualList-Stub {
  width: 0;
}

.VirtualList-Row.Row_isScrolling {
  pointer-events: none;
}
