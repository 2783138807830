.Form {
  display: block;
  max-width: 100%;
}

.Form-Fieldset {
  padding: initial;
  border: initial;
  margin: initial;

  & + & {
    margin-top: 1em;
  }
}

.Form-Fieldset_grow {
  flex-grow: 1;
}

.Form-Fieldset_margin-next {
  & + .Form-Fieldset {
    margin-top: 3em;
  }
}

.Form_flex {
  display: flex;
  flex-direction: column;
}

.Form_desktop {
  min-width: 20em;

  .Width > &,
  .Paper > & {
    min-width: 100%;
  }
}
