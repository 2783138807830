.SharePortfolioBannerContainer {
  position: relative;
}

.SharePortfolioBanner {
  z-index: 1;
}

.SharePortfolioBanner .SharePortfolioBannerTopBlock {
  border-bottom: 1px solid var(--background-border);
}

.SharePortfolioBanner .SharingEnabledLabel {
  align-self: self-end;
  border-radius: 4px;
  background: var(--background-minor);
  white-space: nowrap;
}

.SharePortfolioBanner .SharingEnabledLabel_active {
  background: var(--secondary-default);
}

/* position it over original link to prevent banner hover when hovering link */
.SharePortfolioBannerTermsLink {
  position: absolute;
  z-index: 2;
  bottom: 0;
  bottom: 24px;
  left: 12px;
}

.SharePortfolioBannerTermsLink_desktop {
  bottom: 32px;
  left: 24px;
}
