.StackedBar {
  width: 6em;
  margin: auto;
  text-align: center;

  .macOsX.firefox & {
    font-weight: 300;
  }

  @media (width <= 374px) {
    html .Root & {
      margin: 0;
      font-size: 0.625em;
    }
  }
}

.StackedBar-Label,
.StackedBar-Total {
  text-align: center !important;
}

.StackedBar-Label {
  margin: 0 -1em;
  margin-bottom: -0.125em;
  font-size: 0.875em;
  font-weight: 400;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

.StackedBar-InlineHelp {
  display: block;
  font-size: 1em;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
}

.StackedBar-Total {
  margin-top: 0;
  margin-bottom: 0.25em;
}

.StackedBar-Stack {
  position: relative;
  box-shadow: -2em 2em 0 var(--background-minor);

  &::before {
    position: absolute;
    z-index: 0;
    display: block;
    width: 6em;
    height: 2em;
    background: var(--background-minor);
    content: '';
    transform: skewX(-45deg) translate3d(-1em, 0, 0);

    .safari & {
      z-index: -1;
    }
  }

  * {
    position: relative;
    z-index: 1;
  }
}
