/* latin-ext */
@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url('https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url('https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url('https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-display: swap;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url('https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.Typography {
  padding: 0;
  margin: 0;
}

.Typography > *:first-child {
  margin-top: 0;
}

.Typography > *:last-child {
  margin-bottom: 0;
}

/* fonts */
.Typography_font_default {
  font-family: inherit;
}

.Typography_font_Poppins {
  font-family: var(--font-family-poppins);
}

/* colors */

.Typography_color_default {
  color: var(--content-on-background-default);
  fill: var(--content-on-background-default);
}

.Typography_color_additional {
  color: var(--content-on-background-additional);
  fill: var(--content-on-background-additional);
}

.Typography_color_minor {
  color: var(--content-on-background-minor);
  fill: var(--content-on-background-minor);
}

.Typography_color_muted {
  color: var(--content-on-background-muted);
  fill: var(--content-on-background-muted);
}

.Typography_color_warning {
  color: var(--status-warning);
  fill: var(--status-warning);
}

.Typography_color_error {
  color: var(--status-error);
  fill: var(--status-error);
}

.Typography_color_primary_default {
  color: var(--primary-default);
  fill: var(--primary-default);
}

.Typography_color_on_background_primary {
  color: var(--content-on-background-primary);
  fill: var(--content-on-background-primary);
}

.Typography_color_secondary_default {
  color: var(--secondary-default);
  fill: var(--secondary-default);
}

.Typography_color_on_color_default {
  color: var(--content-on-color-default);
  fill: var(--content-on-color-default);
}

.Typography_color_on_color_black {
  color: var(--content-on-color-black);
  fill: var(--content-on-color-black);
}

.Typography_color_on_color_white {
  color: var(--content-on-color-white);
  fill: var(--content-on-color-white);
}

.Typography_color_inherit {
  color: inherit;
}

.Typography_color_stocks {
  color: var(--stocks);
  fill: var(--stocks);
}

.Typography_color_bonds {
  color: var(--bonds);
  fill: var(--bonds);
}

.Typography_color_alternatives {
  color: var(--alternatives);
  fill: var(--alternatives);
}

.Typography_color_green {
  color: var(--status-success);
  fill: var(--status-success);
}

.Typography_color_red {
  color: var(--status-error);
  fill: var(--status-error);
}

.Typography_color_white {
  color: var(--content-on-color-white);
  fill: var(--content-on-color-white);
}

.Typography_color_on_color_red {
  color: var(--content-on-color-red);
  fill: var(--content-on-color-red);
}

.Typography_color_accent {
  color: var(--content-on-background-accent);
  fill: var(--content-on-background-accent);
}

/* sizes */
.Typography_size_8 {
  font-size: 8px;

  &.Typography_lineHeight_small {
    line-height: 10px;
  }

  &.Typography_lineHeight_medium {
    line-height: 12px;
  }
}

.Typography_size_10 {
  font-size: 10px;

  &.Typography_lineHeight_small {
    line-height: 12px;
  }

  &.Typography_lineHeight_medium {
    line-height: 16px;
  }
}

.Typography_size_12 {
  font-size: 12px;

  &.Typography_lineHeight_small {
    line-height: 14px;
  }

  &.Typography_lineHeight_medium {
    line-height: 18px;
  }
}

.Typography_size_14 {
  font-size: 14px;

  &.Typography_lineHeight_small {
    line-height: 16px;

    .windows & {
      line-height: 17px;
    }
  }

  &.Typography_lineHeight_medium {
    line-height: 22px;
  }
}

.Typography_size_16 {
  font-size: 16px;

  &.Typography_lineHeight_small {
    line-height: 20px;
  }

  &.Typography_lineHeight_medium {
    line-height: 24px;
  }
}

.Typography_size_18 {
  font-size: 18px;

  &.Typography_lineHeight_small {
    line-height: 22px;
  }

  &.Typography_lineHeight_medium {
    line-height: 28px;
  }
}

.Typography_size_20 {
  font-size: 20px;

  &.Typography_lineHeight_small {
    line-height: 24px;
  }

  &.Typography_lineHeight_medium {
    line-height: 30px;
  }
}

.Typography_size_24 {
  font-size: 24px;

  &.Typography_lineHeight_small {
    line-height: 28px;
  }

  &.Typography_lineHeight_medium {
    line-height: 36px;
  }
}

.Typography_size_28 {
  font-size: 28px;

  &.Typography_lineHeight_small {
    line-height: 36px;
  }

  &.Typography_lineHeight_medium {
    line-height: 46px;
  }
}

.Typography_size_32 {
  font-size: 32px;

  &.Typography_lineHeight_small {
    line-height: 38px;
  }

  &.Typography_lineHeight_medium {
    line-height: 48px;
  }
}

.Typography_size_36 {
  font-size: 36px;

  &.Typography_lineHeight_small {
    line-height: 42px;
  }

  &.Typography_lineHeight_medium {
    line-height: 54px;
  }
}

.Typography_size_40 {
  font-size: 40px;

  &.Typography_lineHeight_small {
    line-height: 44px;
  }

  &.Typography_lineHeight_medium {
    line-height: 58px;
  }
}

.Typography_size_48 {
  font-size: 48px;

  &.Typography_lineHeight_small {
    line-height: 58px;
  }

  &.Typography_lineHeight_medium {
    line-height: 72px;
  }
}

.Typography_size_54 {
  font-size: 54px;

  &.Typography_lineHeight_small {
    line-height: 64px;
  }

  &.Typography_lineHeight_medium {
    line-height: 80px;
  }
}

.Typography_size_inherit {
  font-size: inherit;

  &.Typography_lineHeight_small {
    line-height: inherit;
  }

  &.Typography_lineHeight_medium {
    line-height: inherit;
  }
}

/* weights */
.Typography_weight_regular {
  font-weight: 400;
}

.Typography_weight_semibold {
  font-weight: 600;
}

.Typography_weight_bold {
  font-weight: 700;
}

.Typography_weight_inherit {
  font-weight: inherit;
}

/* aligns */
.Typography_align_left {
  text-align: left;
}

.Typography_align_center {
  text-align: center;
}

.Typography_align_right {
  text-align: right;
}

/* numerics */
.Typography_numeric_tabular {
  font-variant-numeric: tabular-nums;
}

/* overflows */
.Typography_overflow_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Typography_overflow_break-word {
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.Typography_overflow_nowrap {
  white-space: nowrap;
}

/* text-wrap */

.Typography_textWrap_wrap {
  text-wrap: wrap;
}

.Typography_textWrap_nowrap {
  text-wrap: nowrap;
}

.Typography_textWrap_balance {
  text-wrap: balance;
}

.Typography_textWrap_pretty {
  text-wrap: pretty;
}

.Typography_textWrap_stable {
  text-wrap: stable;
}

.Typography_wordBreak_break-all {
  word-break: break-all;
}

/* uppercase */
.Typography_uppercase {
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

/* inline */
.Typography_inline {
  display: inline-block;
}

.Typography_lineHeight_inherit {
  line-height: inherit;
}
