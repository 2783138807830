.GrowthGraph-TabsLine {
  position: relative;
  display: flex;
}

.GrowthGraph-TabsLineBorder {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--background-border);
}

.GrowthGraph-Tabs {
  position: relative;
  z-index: 1;

  .GrowthGraph_phone & {
    width: 100%;
  }

  .GrowthGraph_desktop & {
    width: 21rem;
  }
}

.GrowthGraph-Tab {
  .GrowthGraph_desktop & {
    padding: 0 1.25rem;
    line-height: 3.3rem;
  }

  .GrowthGraph_phone & {
    padding: 0 1.75rem;
    line-height: 3.5;
  }

  &:first-child {
    padding-left: 0;
  }

  .Tab-Inner {
    width: 100%;
  }
}

.GrowthGraph-Info {
  display: flex;
  justify-content: space-between;
  padding-top: 1.25rem;
  padding-bottom: 1rem;
}

.GrowthGraph-InfoClm {
  display: flex;
  flex-flow: column wrap-reverse;
}

.GrowthGraph-InfoClm_contributions {
  justify-content: flex-end;
  margin-left: 1rem;
  text-align: right;
}

.GrowthGraph-InfoLabel {
  order: 2;
  white-space: nowrap;
}

.GrowthGraph-InfoCenteredText {
  line-height: 20px;
  white-space: nowrap;

  .GrowthGraph_phone & {
    font-size: 0.75rem;
  }

  .GrowthGraph_desktop & {
    font-size: 16px;
  }
}

.GrowthGraph-InfoValue {
  font-weight: 400;
  white-space: nowrap;

  .GrowthGraph_phone & {
    overflow: hidden;
    order: 1;
    margin-top: 0.2rem;
    margin-bottom: 4px;
    font-size: 1.25rem;
    line-height: 1.1;
  }

  .GrowthGraph_desktop & {
    overflow: hidden;
    order: 1;
    margin-top: 0.2rem;
    margin-bottom: 8px;
    font-size: 1.875rem;
    line-height: 1.1;
  }
}

.GrowthGraph-InfoTwrValues {
  white-space: normal;
}

.GrowthGraph-InfoTwrPercents {
  position: relative;
  display: inline-block;
  margin: 0.05em 0;
  white-space: nowrap;
}

.GrowthGraph-InfoTwrPercents_green {
  color: var(--status-success);
}

.GrowthGraph-InfoTwrPercents_red {
  color: var(--status-error);
}

.GrowthGraph-TwrTip {
  top: 2.1rem;
  left: -6rem;
  width: calc(100vw - 2rem);
  max-width: 29rem;

  &::before,
  &::after {
    top: -0.25rem;
    left: 6.375rem;
  }
}

.GrowthGraph-ContibutionDestopToggle {
  margin-right: 0.5rem;
  margin-left: 1rem;
  vertical-align: bottom;

  .GrowthGraph-Info_multiline .GrowthGraph-InfoClm_contributions & {
    margin-left: -0.25rem;
  }
}

.GrowthGraph-ContibutionDestopToggleLabel {
  margin-right: 0.125rem;
}

.GrowthGraph-ContibutionPhoneToggle {
  margin-left: 0.5rem;
  vertical-align: bottom;
}
