.Hint-Highlighted-Area {
  display: block !important;
}

.reactour__popover::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: var(--rtp-arrow-border-top);
  border-right: var(--rtp-arrow-border-right);
  border-bottom: var(--rtp-arrow-border-bottom);
  border-left: var(--rtp-arrow-border-left);
  content: '';
  inset: var(--rtp-arrow-top, auto) var(--rtp-arrow-right, auto) var(--rtp-arrow-bottom, auto)
    var(--rtp-arrow-left, auto);
}
