.FirstTopupBanner_Card {
  background-color: var(--background-purple);
}

.FirstTopupBanner_Close {
  position: absolute;
  z-index: 10;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
}
