.Logo {
  .ie & {
    .SvgIcon {
      width: 11.125rem;
    }
  }
}

.Logo-Primary {
  fill: var(--primary-default) !important;
}

.Logo-Content {
  fill: var(--content-on-background-default) !important;
}
