.Reports-Report {
  position: relative;
}

.Reports_desktop {
  display: table;
  border-collapse: collapse;

  .Reports-Head {
    display: table-header-group;
  }

  .Reports-Report {
    display: table-row;
    border-top: 1px solid var(--background-border);
  }

  .Reports-CreatedAt,
  .Reports-DocumentName,
  .Reports-Download,
  .Reports-Report::after {
    display: table-cell;
    vertical-align: middle;
  }

  .Reports-CreatedAt {
    width: 150px;
  }

  .Reports-DocumentName {
    white-space: nowrap;
  }

  .Reports-Download {
    width: 0;
  }

  .Reports-Download > * {
    margin: -8px 0;
  }

  .Reports-Report::after {
    width: 100%;
    content: '';
  }
}

.Reports_phone {
  margin: 0 -16px;

  .Reports-Head,
  .Reports-Report {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .Reports-DocumentName {
    padding-right: 12px;
    padding-left: 16px;
  }

  .Reports-CreatedAt {
    padding-right: 16px;
  }
}
