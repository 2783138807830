.CollectionGroup_List {
  --collection-group-column-count: 2;

  display: grid;
  gap: 16px;
  grid-template-columns: repeat(var(--collection-group-column-count), 1fr);
}

.CollectionGroup_List_featured {
  --collection-group-column-count: 3;
}

.CollectionGroup_Item {
  min-height: 144px;
  user-select: none;
}

.CollectionGroup_List_featured .CollectionGroup_Item {
  flex-basis: 30%;
  flex-grow: 1;
}

.CollectionGroup-Wrapper {
  display: flex;
  align-items: center;
}

.CollectionGroup-Popup .Tooltip {
  height: fit-content;
}

.CollectionGroup-Popup .Tooltip-Subject {
  display: unset;
}
