.FilterElement {
  position: relative;
}

.FilterElement-Name {
  overflow: hidden;
  align-self: center;
  overflow-wrap: break-word;
}

.FilterElement-InputHolder {
  display: flex;
  height: var(--filter-element-line-height, 24px);
  justify-content: center;
}

.FilterElement .FilterElement-Input {
  position: static;
}

.FilterElement .FilterElement-RealInput {
  border: 1px solid transparent;
  border-radius: 0;
  background-color: transparent;
  opacity: 1;
  outline: none;
  transition:
    background-color var(--animation-speed-default) var(--animation-easing-default),
    border-color var(--animation-speed-default) var(--animation-easing-default);
}

.FilterElement .FilterElement-RealInput:hover {
  background-color: var(--primary-surface-10);
  transition: none;
}

.FilterElement .FilterElement-RealInput:focus-visible {
  border-color: var(--content-on-background-default);
  transition: none;
}

.FilterElement_withLine + .FilterElement_withLine,
.FilterElement_phone + .FilterElement_phone {
  margin-top: -1px;
}

.FilterElement_withLine:not(:last-child)::after,
.FilterElement_phone:not(:last-child)::after {
  position: absolute;
  right: 16px;
  bottom: 0;
  left: 16px;
  display: block;
  height: 1px;
  background: var(--background-border);
  content: '';
}

.FilterElement_phone .FilterElement-RealInput:hover,
.FilterElement_phone .FilterElement-RealInput:active {
  background-color: var(--background-border);
  transition: none;
}
