/* BEGIN fix autofill */

/* Chrome https://stackoverflow.com/a/68240841 */
html .Root .Input:-webkit-autofill,
html .Root .Input:-webkit-autofill:hover,
html .Root .Input:-webkit-autofill:focus {
  transition:
    background-color 666666s 0s,
    color 666666s 0s;
}

/* 1Password */
html .Root .Input[data-com-onepassword-filled] {
  transition:
    background-color 666666s 0s,
    color 666666s 0s;
}

/* END fix autofill */

.Input {
  display: block;
  width: 100%;
  height: 2em;
  box-sizing: border-box;
  padding: 0.2em 0.75em;
  border: 1px solid var(--background-border);
  border-radius: 4px;
  margin: initial;
  appearance: none;
  background: var(--background-default);
  box-shadow: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  textarea& {
    max-width: 100%;
    height: auto;
  }

  .Headline & {
    border-width: 0.0625em;
  }

  &:invalid {
    box-shadow: initial;
    outline: initial;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: var(--primary-default);
  }
}

.Input-Holder {
  position: relative;
  display: inline-block;
  width: 100%;
}

.Input-Error {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  border-radius: 0.325rem;
  background: var(--status-error);
  opacity: 0;
  transform: translate3d(0, -0.5em, 0) rotate3d(1, 0, 0, 90deg);
  transform-origin: 0.5em center;
  transition:
    opacity var(--animation-speed-default) var(--animation-easing-default),
    transform var(--animation-speed-default) var(--animation-easing-default);
}

.Input-Checkalike {
  display: inline-block;
  min-width: 1.125em;
  margin-right: 0.5em;
  vertical-align: top;

  .Input {
    display: block;
    height: auto;
  }
}

.Input_w-size {
  width: auto;
}

.Input_inline {
  display: inline-block;
  width: auto;
}

.Input_type_number,
.Input_type_money,
.Input_type_date {
  appearance: none;
}

.Input_text_bold {
  font-weight: 500;
}

.Input_size_bigger {
  font-size: 1.125em;
}

.Input_size_big {
  font-size: 1.5em;
}

.Input_size_small {
  font-size: 0.875em;
}

.Input_size_unpadded {
  height: auto;
  padding: 0.1em 0.25em;
}

.Input_align_center {
  text-align: center;
}

.Input_theme_borderless {
  &,
  &:hover,
  &:focus-visible {
    border-color: transparent;

    &.Input_error {
      border-color: var(--status-error);
    }
  }
}

.Input_theme_no-h-padding {
  padding-right: 0;
  padding-left: 0;
}

.Input_theme_transparent {
  &,
  &.Input_disabled {
    background-color: transparent;
  }
}

.Input_color_blue {
  color: var(--primary-default);
}

.Input_color_blue_to_white {
  color: var(--primary-default);

  &.Input_dark_theme {
      color: var(--content-on-background-default);
  }
}

.Input_error {
  &,
  &:hover,
  &:focus-visible {
    border-color: var(--status-error);
    color: var(--status-error);
  }

  & + .Input-Error {
    opacity: 1;
    transform: translate3d(0, -0.5em, 0) rotate3d(1, 0, 0, 0);
    transition:
      opacity var(--animation-speed-fast) var(--animation-easing-default),
      transform var(--animation-speed-fast) var(--animation-easing-default);
  }
}

.Input_disabled {
  background-color: var(--background-muted);
  color: var(--content-on-background-muted);
}

.Input_uppercase {
  text-transform: uppercase;
}

.Input_flatbottom {
  border-radius: 0.325rem 0.325rem 0 0;
}

html:not(.firefox) .Input_type_money:not(.Input_long_placeholder) {
  &:placeholder-shown {
    padding-left: 1.3em;

    &::placeholder {
      position: relative;
      left: -0.55em;
    }
  }

  &.Input_size_unpadded {
    &:placeholder-shown {
      padding-left: 0.8em;

      &::placeholder {
        position: relative;
        left: -0.55em;
      }
    }
  }
}
