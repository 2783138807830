.ConnectedList {
  --connected-list-accent-color: var(--primary-default);
  --connected-list-number-size: 32px;
  --connected-list-border-thickness: 2px;
  --connected-list-gap: 32px;

  display: grid;
  padding: 0;
  margin: 0;
  gap: var(--connected-list-gap);
  list-style: none;
}

.ConnectedList-Item {
  position: relative;
  display: grid;
  margin: 0;
  gap: 12px;
  grid-template-columns: auto 1fr;
}

.ConnectedList-Number {
  display: grid;
  width: var(--connected-list-number-size);
  box-sizing: border-box;
  border: var(--connected-list-border-thickness) solid var(--connected-list-accent-color);
  border-radius: 50%;
  aspect-ratio: 1;
  color: var(--connected-list-accent-color);
  place-content: center;
}

.ConnectedList-Item:not(:last-of-type) .ConnectedList-Number::after {
  position: absolute;
  top: var(--connected-list-number-size);
  left: calc(var(--connected-list-number-size) / 2);
  display: block;
  width: var(--connected-list-border-thickness);
  height: calc(100% - var(--connected-list-number-size) + var(--connected-list-gap));
  background-color: var(--connected-list-accent-color);
  content: '';
}
