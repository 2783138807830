.Typeahead {
  position: relative;

  /* it's nested for overflowing Input styles */
  .Typeahead-Label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.2em 0.8125em;
    border: none;
    border-color: transparent;
    background: transparent;
    line-height: 1.7;
    pointer-events: none;

    .windows & {
      padding: 0.25rem 0.875rem;
      line-height: 1.6;
    }
  }
}

.Typeahead-Fill {
  all: initial;
  position: absolute;
  top: -0.75em;
  bottom: -0.75em;
  display: block;
  width: 2em;
  padding: 0.75em 1em;
  margin: auto;
  margin: 0 0 0 -0.75em;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  -webkit-tap-highlight-color: var(--background-transparent);
  transition: opacity var(--animation-speed-fast) var(--animation-easing-default);
}

.Typeahead-Fill_visible {
  opacity: 1;
  pointer-events: auto;
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);
}

.Typeahead-FillIcon {
  margin: auto;
}
