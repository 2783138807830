.HorizontalChart-Column {
  padding-right: 0.5em;
  padding-left: 0.5em;
  text-align: right;
}

.HorizontalChart-ColumnTitle {
  color: var(--content-on-background-additional);
  font-size: 1em;
  font-weight: 400;
}

.HorizontalChart-ColumnValue {
  font-weight: 500;
  text-align: right;
}

.HorizontalChart-Item {
  display: flex;
  align-items: center;
  padding: 0.375em 0.5em;
  margin: 0 -0.5em;
}

.HorizontalChart-Item_margin-top {
  margin-top: 1em;
}

.HorizontalChart-Headline {
  display: flex;
  align-items: baseline;
}

.HorizontalChart-Label {
  overflow: hidden;
  flex: 1 1 0;
  padding-right: 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.HorizontalChart-Weight {
  flex: 0 1 17%;
}

.HorizontalChart-TotalValue {
  flex: 0 1 22%;
  font-weight: 500;
  text-align: right;

  &.Headline {
    color: var(--content-on-background-additional);
    font-size: 1em;
    font-weight: 400;
  }
}

.HorizontalChart-Chart {
  height: 0.625em;
  flex: 0 1 8.5%;
}

.HorizontalChart-Line {
  max-width: 100%;
  height: 100%;
}

.HorizontalChart_clickable {
  .HorizontalChart-Item {
    cursor: pointer;
    transition: background-color var(--animation-speed-default) var(--animation-easing-default);

    &:active,
    &:hover {
      background-color: var(--background-muted);
      transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
    }
  }

  .HorizontalChart-Item_selected {
    background-color: var(--background-muted);
    cursor: default;
    transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
  }
}

.HorizontalChart_wide-hover {
  .HorizontalChart_clickable .HorizontalChart-Item {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 3.5rem;
      content: '';
      transition: background-color var(--animation-speed-default) var(--animation-easing-default);
    }

    &::before {
      left: -3.5rem;
    }

    &::after {
      right: -3.5rem;
    }

    &:hover {
      &::before,
      &::after {
        background-color: var(--background-muted);
        transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
      }
    }
  }

  .HorizontalChart-Item_selected {
    &::before,
    &::after {
      background-color: var(--background-muted);
    }
  }
}

.HorizontalChart_phone {
  .HorizontalChart-Item {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }

  .HorizontalChart-TotalValue {
    flex: 0 1 23%;

    &_large {
      flex: 0 1 32%;
    }
  }
}
