._new_Card {
  position: relative;
  display: block;
  width: 100%;
  background-color: var(--background-card);

  &:focus {
    outline: none;
  }
}

._new_Card_level_-1 {
  background-color: var(--background-muted);
}

._new_Card_level_0 {
  box-shadow: 0 0 0 1px var(--background-border) inset;

  &._new_Card_borderWidth_0 {
    box-shadow: none;
  }
}

._new_Card_level_2 {
  box-shadow: var(--shadow-level-2);
}

._new_Card_level_3 {
  box-shadow: var(--shadow-level-3);
}

._new_Card_borderRadius_6 {
  border-radius: 6px;
}

._new_Card_borderRadius_8 {
  border-radius: 8px;
}

._new_Card_borderRadius_10 {
  border-radius: 10px;
}

._new_Card_color_primary-surface-10 {
  box-shadow: 0 0 0 1px var(--primary-surface-10) inset;

  &._new_Card_borderWidth_2 {
    box-shadow: 0 0 0 2px var(--primary-surface-10) inset;
  }
}

._new_Card_color_primary-surface-25 {
  box-shadow: 0 0 0 1px var(--primary-surface-25) inset;

  &._new_Card_borderWidth_2 {
    box-shadow: 0 0 0 2px var(--primary-surface-25) inset;
  }
}

._new_Card_color_accent-default {
  box-shadow: 0 0 0 1px var(--accent-default) inset;

  &._new_Card_borderWidth_2 {
    box-shadow: 0 0 0 2px var(--accent-default) inset;
  }
}

._new_Card_color_status-warning {
  box-shadow: 0 0 0 1px var(--status-warning) inset;

  &._new_Card_borderWidth_2 {
    box-shadow: 0 0 0 2px var(--status-warning) inset;
  }
}

._new_Card_color_primary-default {
  box-shadow: 0 0 0 1px var(--primary-default) inset;

  &._new_Card_borderWidth_2 {
    box-shadow: 0 0 0 2px var(--primary-default) inset;
  }
}

._new_Card_color_background-default {
  background-color: var(--background-default);
}

._new_Card_color_content-on-color-white {
  background-color: var(--content-on-color-white);
}
