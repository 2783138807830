.ManagedNavigationBar-TypeSubtitle {
  padding: 2px 8px;
  border-radius: 12px;
  background-color: var(--primary-default);
}

.Goal-TitleLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.Goal-Title {
  margin: 0;
  margin-top: -0.2rem;
  font-size: 1.5rem;
  line-height: 1;
}

.Goal-TitleIcon {
  width: 1.125rem;
  height: 1.125rem;
}

.Goal-TitleEdit {
  margin-left: 0.5rem;
}

.Goal-ButtonsGroup {
  .ie11 & {
    margin-left: auto;
  }
}

.Goal_phone .Portfolio-MoreMenu {
  min-width: 15.5rem;
  padding-right: 0.6rem;
  padding-left: 0.5rem;
}

.Goal_desktop .Portfolio-MoreMenu {
  min-width: 17rem;
  padding-right: 1.7rem;
  padding-left: 1.25rem;
}
