.MergeIcons {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}

.MergeIcons-Icon {
  position: absolute;
  inset: 0;

  > .Icon {
    position: absolute;
    margin: auto;
    inset: 0;
  }
}
