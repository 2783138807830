.ContextMenuList {
  width: 195px;
  border-radius: 8px;
  background-color: var(--background-card-opaque);
  box-shadow: var(--shadow-level-4);
}

.ContextMenuList-Item {
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.ContextMenuList-Item:not(:last-child) {
  border-bottom: 1px solid var(--background-border)
}
