.SecurityOrderItem-Content {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: minmax(100px, auto) max-content;
}

.SecurityOrderItem-Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
