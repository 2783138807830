.QuickStartModal_SavingsPlanCard {
  background-color: var(--primary-default);
}

.QuickStartModal_LumpSumCard {
  border: 1px solid var(--background-purple);
  background-color: var(--background-default);
  box-shadow: none;
  cursor: pointer;
}
