.SliderWTicks {
  position: relative;
}

.SliderWTicks-Ticks {
  position: absolute;
  top: 2.4em;
  right: 0.5rem;
  left: 0.75rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.125rem;
}

.SliderWTicks-Tick {
  width: 0.0625rem;
  height: 0.375rem;
  background: var(--content-on-background-muted);
}

.SliderWTicks-Tick_recommended {
  position: relative;
  height: 0.95rem;
  margin-top: -0.6rem;
  background-color: var(--content-on-background-muted);

  &::after {
    position: absolute;
    top: 1.4em;
    left: -1.25rem;
    color: var(--secondary-default);
    content: attr(data-recommended-text);
    font-size: 0.75rem;
  }

  &:first-child::after {
    left: -0.9rem;
  }

  &:last-child::after {
    left: -2.25rem;
  }

  .SliderWTicks-Tick_left.& {
    &::after {
      right: -6rem;
      left: -0.125rem;
      text-align: left;
    }
  }

  .SliderWTicks-Tick_right.& {
    &::after {
      right: -0.125rem;
      left: -6rem;
      text-align: right;
    }
  }
}
