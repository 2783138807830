.HeadlineWBacklink {
  position: relative;
  flex-shrink: 0;
  text-align: center;
}

.HeadlineWBacklink-Holder {
  max-width: 45em;
  margin: auto;
}

.HeadlineWBacklink-BackIcon {
  display: inline-block;
  height: 4.5em;
  margin: -1.75em 0 -1.5em -0.75em;
  vertical-align: middle;
}

.HeadlineWBacklink-BackText {
  margin-left: 0.5rem;
  color: var(--content-on-background-default);
  font-size: 1rem;
  transition: color var(--animation-speed-default) var(--animation-easing-default);

  .HeadlineWBacklink-Back:hover & {
    color: inherit;
    transition: color var(--animation-speed-zero) var(--animation-easing-default);
  }
}

.HeadlineWBacklink-Subheadline {
  margin-top: -0.125em;
  opacity: 0.75;
}

.HeadlineWBacklink_phone {
  .HeadlineWBacklink-Holder {
    display: flex;
    flex-direction: column-reverse;
  }

  .HeadlineWBacklink-Back {
    margin-bottom: 1em;
    text-align: left;
  }
}

.HeadlineWBacklink_desktop {
  .HeadlineWBacklink-Back {
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    overflow: hidden;
    max-width: 16.8%;
    height: 1.625em;
    padding: 0.5em 0 0.5em 0.25em;
    margin: 0 0 0 -0.25em;
    font-size: 0.875em;
    text-overflow: ellipsis;
    transform: translateY(-50%);
    white-space: nowrap;
  }

  .HeadlineWBacklink-Subheadline {
    margin-top: 0.5em;
  }

  &.HeadlineWBacklink_w-back {
    padding-right: 17.5%;
    padding-left: 17.5%;
  }
}
