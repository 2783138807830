.IsaTransferPanel {
  border-radius: 8px;
  background: var(--accent-default);
  cursor: pointer;
  user-select: none;
}

.IsaTransferPanel:hover {
  background: var(--accent-action);
}

.IsaTransferPanel_content {
  display: flex;
  align-items: center;
}
