.ReferralCode-Banner {
  background-color: var(--primary-default);
  padding-block: 20px;
  padding-inline: 16px;
}

.ReferralCode-BannerTitle {
  text-align: left;
}

.ReferralCode-BannerWrapper {
  display: grid;
  max-width: 960px;
  gap: 32px;
  grid-template-columns: 1fr auto;
  margin-inline: auto;
  place-items: center;
}

.ReferralCode-BannerPicture {
  width: 360px;
  aspect-ratio: 1;
}

.ReferralCode-BannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ReferralCode-Card {
  display: grid;
  gap: 32px;
  place-items: center;
}

.ReferralCode-Button .Button-Inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

@media (width < 1024px) {
  .ReferralCode-BannerWrapper {
    gap: unset;
    grid-template-columns: 1fr;
  }

  .ReferralCode-Banner {
    padding-block: 24px;
  }

  .ReferralCode-BannerTitle {
    font-size: 40px;
    text-align: center;
  }

  .ReferralCode-BannerText {
    display: none;
  }

  .ReferralCode-BannerPicture {
    width: 287px;
  }
}
