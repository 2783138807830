.ChartTooltip {
  z-index: 1;
}

.ChartTooltip-Card,
.ChartTooltip-Stub {
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);
  will-change: opacity;
}

.ChartTooltip-Card {
  position: absolute;
  z-index: 2;
  margin: 8px 12px;
  opacity: 0;
}

.ChartTooltip-Stub {
  position: relative;
  opacity: 1;
}

.ChartTooltip_visible {
  .ChartTooltip-Card,
  .ChartTooltip-Stub {
    transition: opacity var(--animation-speed-zero) var(--animation-easing-default);
  }

  .ChartTooltip-Card {
    opacity: 1;
  }

  .ChartTooltip-Stub {
    opacity: 0;
  }
}

.ChartTooltip_pointerTransparent {
  .ChartTooltip-Card {
    pointer-events: none;
  }
}

.ChartTooltip_fullSize {
  .ChartTooltip-Card {
    display: flex;
    align-items: center;
    margin: 0;
    inset: 0;
    place-content: center center;
  }
}
