.CollectionHeader {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.CollectionHeader_phone {
  align-items: end;
}

.CollectionHeader_title {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  hyphens: auto;
  -webkit-line-clamp: 2;
  overflow-wrap: anywhere;
}

.CollectionHeader_phone .CollectionHeader_title {
  -webkit-line-clamp: 3;
}
