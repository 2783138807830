:root {
  --font-family-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  --font-family-poppins: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  --animation-speed-zero: 0ms;
  --animation-speed-fast: 125ms;
  --animation-speed-default: 325ms;
  --animation-speed-slow: 500ms;
  --animation-speed-number-zero: 5e-324;
  --animation-speed-number-fast: 125;
  --animation-speed-number-default: 325;
  --animation-speed-number-slow: 500;
  --animation-easing-default: cubic-bezier(0.55, 0, 0.1, 1);
  --stocks: #B158A7;
  --bonds: #56CDDA;
  --alternatives: #FDC032;
  --cash: #9BB7E3;
  --cash-default: rgb(255, 199, 68);
  --self-selected-default: rgb(0, 191, 232);
  --primary-action: rgb(59, 77, 235);
  --primary-default: rgb(59, 59, 245);
  --primary-disabled: rgb(90, 107, 252, 0.45);
  --primary-surface-85: rgba(90, 107, 252, 0.85);
  --primary-surface-65: rgba(90, 107, 252, 0.65);
  --primary-surface-45: rgba(90, 107, 252, 0.45);
  --primary-surface-25: rgba(90, 107, 252, 0.25);
  --primary-surface-10: rgba(90, 107, 252, 0.1);
  --primary-surface-5: rgba(90, 107, 252, 0.05);
  --primary-link-hover: rgb(255, 19, 43);
  --secondary-action: rgb(78, 146, 45);
  --secondary-default: rgb(106, 214, 48);
  --secondary-disabled: rgba(24, 43, 23, 0.45);
  --secondary-surface-85: rgba(102, 181, 55, 0.85);
  --secondary-surface-65: rgba(86, 152, 50, 0.65);
  --secondary-surface-45: rgba(105, 208, 66, 0.45);
  --secondary-surface-25: rgba(107, 215, 57, 0.25);
  --secondary-surface-10: rgba(86, 156, 47, 0.2);
  --secondary-link-hover: rgb(255, 179, 42);
  --secondary-link-hover-45: rgba(255, 179, 42, 0.45);
  --background-default: rgb(28, 28, 28);
  --background-default-85: rgba(28, 28, 28, 0.85);
  --background-overlay: rgba(42, 42, 42, 0.85);
  --background-overlay-opaque: rgb(40, 40, 40);
  --background-muted: rgba(33, 33, 33, 0.85);
  --background-minor: rgba(39, 39, 39, 0.85);
  --background-minor-opaque: rgb(37, 37, 37);
  --background-border: rgba(138, 138, 138, 0.45);
  --background-border-opaque: rgb(77, 77, 77);
  --background-transparent: rgba(28, 28, 28, 0);
  --background-thumb: rgb(255, 255, 255);
  --background-card: rgba(118, 132, 152, 0.2);
  --background-card-opaque: rgb(45, 48, 50);
  --background-primary: rgb(59, 59, 245);
  --background-primary-action: rgb(25, 43, 194);
  --background-purple: rgb(151, 71, 255);
  --background-and-borders-muted: rgba(33, 33, 33, 0.85);
  --content-on-background-solid: rgb(255, 255, 255);
  --content-on-background-default: rgba(255, 255, 255, 0.9);
  --content-on-background-additional: rgba(255, 255, 255, 0.7);
  --content-on-background-minor: rgba(255, 255, 255, 0.6);
  --content-on-background-muted: rgba(255, 255, 255, 0.5);
  --content-on-background-accent: rgba(181, 124, 255, 1);
  --content-on-background-primary: rgb(118, 148, 255);
  --content-on-background-outstanding: rgb(174, 232, 53);
  --content-on-color-default: rgba(0, 0, 0, 0.9);
  --content-on-color-additional: rgba(0, 0, 0, 0.7);
  --content-on-color-minor: rgba(0, 0, 0, 0.45);
  --content-on-color-muted: rgba(0, 0, 0, 0.25);
  --content-on-color-white: rgba(255, 255, 255, 1);
  --content-on-color-red: rgba(255, 147, 140, 1);
  --content-on-color-black: rgba(0, 0, 0, 0.9);
  --status-error: rgb(255, 130, 122);
  --status-error-65: rgba(255, 130, 122, 0.65);
  --status-error-45: rgba(255, 130, 122, 0.45);
  --status-error-25: rgba(255, 130, 122, 0.25);
  --status-error-10: rgba(255, 97, 87, 0.1);
  --status-success-action: rgb(51, 125, 5);
  --status-success: rgb(106, 214, 48);
  --status-success-65: rgba(106, 214, 48, 0.65);
  --status-success-45: rgba(106, 214, 48, 0.45);
  --status-success-25: rgba(106, 214, 48, 0.25);
  --status-success-10: rgba(94, 235, 37, 0.1);
  --status-warning: rgb(255, 179, 42);
  --status-warning-65: rgba(255, 179, 42, 0.65);
  --status-warning-45: rgba(255, 179, 42, 0.45);
  --status-warning-25: rgba(255, 179, 42, 0.25);
  --status-warning-10: rgba(255, 173, 28, 0.1);
  --accent-default: #9747FF;
  --accent-action: #622EA6;
  --accent-disabled: #351959;
  --accent-surface-45: #442073;
  --accent-surface-25: #351959;
  --accent-surface-10: #261240;
  --outstanding-default: rgb(174, 232, 53);
  --outstanding-action: rgb(131, 197, 0);
  --outstanding-disabled: rgb(72, 108, 0);
  --outstanding-surface-45: rgb(106, 159, 0);
  --outstanding-surface-25: rgb(72, 108, 0);
  --outstanding-surface-10: rgb(37, 55, 0);
  --shadow-level-1: 0px  2px  6px      rgba(0, 0, 0, 0.24);
  --shadow-level-2: 0px  3px 10px -1px rgba(0, 0, 0, 0.28);
  --shadow-level-3: 0px  4px 14px -1px rgba(0, 0, 0, 0.36);
  --shadow-level-4: 0px  6px 12px -1px rgba(0, 0, 0, 0.48);
  --shadow-level-5: 0px 14px 40px -2px rgba(0, 0, 0, 0.64);
}