@font-face {
  ascent-override: 87.5%;
  descent-override: 12.5%;
  font-family: 'Segoe UI';
  font-weight: 200;
  line-gap-override: 25%;
  src: local('Segoe UI Light');
}

@font-face {
  ascent-override: 87.5%;
  descent-override: 12.5%;
  font-family: 'Segoe UI';
  font-weight: 300;
  line-gap-override: 25%;
}

@font-face {
  ascent-override: 87.5%;
  descent-override: 12.5%;
  font-family: 'Segoe UI';
  font-weight: 400;
  line-gap-override: 25%;
  src: local('Segoe UI');
}

@font-face {
  ascent-override: 87.5%;
  descent-override: 12.5%;
  font-family: 'Segoe UI';
  font-weight: 600;
  line-gap-override: 25%;
  src: local('Segoe UI Semibold');
}

@font-face {
  ascent-override: 87.5%;
  descent-override: 12.5%;
  font-family: 'Segoe UI';
  font-weight: 700;
  line-gap-override: 25%;
  src: local('Segoe UI Bold');
}

@font-face {
  ascent-override: 87.5%;
  descent-override: 12.5%;
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 400;
  line-gap-override: 25%;
  src: local('Segoe UI Italic');
}

@font-face {
  ascent-override: 87.5%;
  descent-override: 12.5%;
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 700;
  line-gap-override: 25%;
  src: local('Segoe UI Bold Italic');
}

html,
body,
.Root {
  all: initial;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--background-default);
  color: var(--content-on-background-default);
  fill: var(--content-on-background-default);
  font-family: var(--font-family-default);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.5;
  text-size-adjust: 100%; /* Prevent mobile devices to adjust font size according to their accessibility guidelines */

  .iOs & {
    font-weight: 300;
  }

  .android & {
    display: flex;
    width: 100%;
    font-family: Roboto, Lato, sans-serif;

    [data-reactroot] {
      width: 100%;
    }
  }
}

a:not([class]) {
  color: var(--primary-default, blue);
  text-decoration: none;
  transition: color var(--animation-speed-default) var(--animation-easing-default);
}

a:not([class]):hover {
  color: var(--primary-link-hover, blue);
  transition: color var(--animation-speed-zero) var(--animation-easing-default);
}

ul,
ol {
  padding-left: 1.2em;

  li {
    margin: 0.5em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Layout {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  flex-flow: column;
  padding: 0;
  margin: 0;

  * {
    -webkit-tap-highlight-color: var(--background-transparent);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
  }
}

.LayoutElement {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  > .ContentHolder {
    flex-grow: 1;
  }
}

.LayoutElement_main {
  display: flex;
  overflow: hidden scroll;
  flex-basis: 0%;
  flex-grow: 1;
}

.Layout-Holder {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.Layout-Holder_tunnel-steps {
  @media (width <= 1023px) {
    html .Root & {
      padding-bottom: 2rem;
    }
  }
}

.Layout_phone {
  width: 100%;
  width: 100vw;

  .Layout-Content:not(.Layout-YearlyReview) {
    min-height: calc(100vh - 48px);
  }
}

.Layout_desktop {
  .Layout-Content {
    min-height: 34rem;
  }
}
