.Link {
  color: var(--content-on-background-primary);
  cursor: pointer;
  fill: var(--content-on-background-primary);
  outline: none !important;
  text-decoration: none;
  transition:
    color var(--animation-speed-default) var(--animation-easing-default),
    text-decoration-color var(--animation-speed-default) var(--animation-easing-default),
    fill var(--animation-speed-default) var(--animation-easing-default);

  &:hover,
  &:focus-visible {
    color: var(--primary-link-hover);
    fill: var(--primary-link-hover);
    transition: none;
  }

  .DisableAnimations & {
    transition: none !important;
  }
}

.Link_active {
  &,
  &:hover,
  &:focus-visible {
    color: var(--content-on-background-default);
    cursor: default;
    fill: var(--content-on-background-default);
    transition: none;
  }
}

.Link_underline {
  text-decoration-line: underline;
}

.Link_color_white {
  position: relative;
  color: var(--content-on-color-default);
  fill: var(--content-on-color-default);
  text-decoration-color: var(--content-on-color-minor);
  text-decoration-line: underline;

  &:hover,
  &:focus-visible {
    color: var(--secondary-link-hover);
    fill: var(--secondary-link-hover);
    text-decoration-color: var(--secondary-link-hover-45);
  }

  &.Link_no-underline {
    text-decoration-color: transparent !important;
  }
}

.Link_color_gray {
  color: var(--content-on-background-minor);
  fill: var(--content-on-background-minor);

  &:hover,
  &:focus-visible {
    color: var(--content-on-background-default);
    fill: var(--content-on-background-default);
  }
}

.Link_color_black {
  color: var(--content-on-background-default);
  fill: var(--content-on-background-default);

  &:hover,
  &:focus-visible {
    color: var(--primary-link-hover);
    fill: var(--primary-link-hover);
  }
}

.Link_color_on-color-black {
  color: var(--content-on-color-black);
  fill: var(--content-on-color-black);

  &:hover,
  &:focus-visible {
    color: var(--primary-link-hover);
    fill: var(--primary-link-hover);
  }
}

.Link_color_content-on-background-default {
  color: var(--content-on-background-default);
  fill: var(--content-on-background-default);

  &:hover,
  &:focus-visible {
    color: var(--primary-link-hover);
    fill: var(--primary-link-hover);
  }
}

.Link_color_lightgrayToBlack {
  color: var(--content-on-background-additional);
  fill: var(--content-on-background-additional);

  &.Link_active,
  &:hover,
  &:focus-visible {
    color: var(--content-on-background-default);
    fill: var(--content-on-background-default);
  }
}

.Link_color_minorToRed {
  color: var(--content-on-background-minor);
  fill: var(--content-on-background-minor);

  &.Link_active,
  &:hover,
  &:focus-visible {
    color: var(--primary-link-hover);
    fill: var(--primary-link-hover);
  }
}

.Link_color_mutedToRed {
  color: var(--content-on-background-muted);
  fill: var(--content-on-background-muted);

  &.Link_active,
  &:hover,
  &:focus-visible {
    color: var(--primary-link-hover);
    fill: var(--primary-link-hover);
  }
}

.Link_color_onColorWhiteToRed {
  color: var(--content-on-color-white);
  fill: var(--content-on-color-white);

  &.Link_active,
  &:hover,
  &:focus-visible {
    color: var(--content-on-color-red);
    fill: var(--content-on-color-red);
  }
}

.Link_disabled {
  color: var(--primary-disabled);
  cursor: default;
  fill: var(--primary-disabled);

  &:hover,
  &:focus-visible {
    color: var(--primary-disabled);
    fill: var(--primary-disabled);
    transition: none;
  }
}
