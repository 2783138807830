.NewOptimalPortfolioModal-Icon,
.NewOptimalPortfolioModal-Headline {
  margin-bottom: 12px;
}

.NewOptimalPortfolioModal-Buttons {
  padding-bottom: 40px;
}

.NewOptimalPortfolioModal-Button {
  margin-top: 16px;
}

.PortfolioChangedModalContent-Grid {
  margin-top: 56px;
  margin-bottom: 56px;
}

.PortfolioChangedModalContent-Cell {
  padding: 26px;
  border: 2px solid var(--primary-surface-10);
  border-radius: 4px;
  text-align: left;
}

.PortfolioChangedModalContent-Arrow {
  max-width: 70px;
  align-items: flex-end;
  justify-content: center;
}

.PortfolioChangedModalContent-Buttons {
  margin-top: 54px;
  margin-bottom: 52px;
}

.NewOptimalPortfolioModal_phone {
  .PortfolioChangedModalContent-Grid {
    margin: 40px 0;
  }

  .PortfolioChangedModalContent-Cell {
    padding: 18px 10px;
  }

  .PortfolioChangedModalContent-Arrow {
    max-width: 14px;
    margin-right: -8px;
    margin-left: -8px;
  }

  .PortfolioChangedModalContent-Buttons {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

.NewOptimalPortfolioModal_tinyPhone {
  .PortfolioChangedModalContent-Arrow {
    max-width: none;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-right: 0;
    margin-left: 0;

    .Arrow {
      width: 24px;
      transform: rotate(90deg);
    }
  }

  .RiskAttentionModalContent-Buttons {
    margin-top: 20px;
    margin-bottom: 24px;
  }
}

/* Necessary evil, remove that after yearly review modal will be rewritten to new layout ones  */
.iOs .Sticked_insideModal .NewOptimalPortfolioModal-Buttons .Button-Inner {
  padding-bottom: 1em !important;
}
