.MobileDIYPortfolio-Buttons {
  display: flex;
  justify-content: center;
}

.MobileDIYPortfolio-TypeSubtitle {
  padding: 2px 8px;
  border-radius: 12px;
  background-color: var(--secondary-default);
}
