.PortfolioSelectableCard {
  background-color: var(--background-card-opaque);
  user-select: none;
}

.PortfolioSelectableCard_desktop {
  min-height: 108px;
  padding-top: 6px;
}

.PortfolioSelectableCard_phone {
  min-height: 88px;
}

.PortfolioSelectableCard_additionalPaddingBottom {
  padding-bottom: 6px;
}
