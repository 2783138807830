.Login-Wrapper {
  display: grid;
  min-height: 770px;
  align-items: center;
  background: var(--primary-default);
}

.Login-Card {
  box-shadow:
    0 -4px 10px 0 rgba(0 0 0 / 4%),
    0 0 4px 0 rgba(0 0 0 / 4%),
    0 12px 32px 0 rgba(0 0 0 / 10%);
}

.Login-Input {
  font-size: 16px;
  padding-block: 17px;
}

.Login-Checkbox {
  line-height: 16px;
}

.Login-Checkbox.WithLabel_size_normal {
  margin-bottom: 0;
}

.Login-Checkbox.WithLabel_size_normal .WithLabel-Field {
  margin-top: 0;
}

.Login-Checkbox .Label-Text {
  margin-top: 2px;
  margin-bottom: 0;
}
