.EditGrowthPortfolio {
  position: relative;
  overflow: inherit;
}

.EditGrowthPortfolio-Wrapper {
  position: relative;
}

.EditGrowthPortfolio-Chart {
  margin-right: 0 !important;
  margin-bottom: 2.5rem !important;
  margin-left: 0 !important;
}

.EditGrowthPortfolio-Chart_updated {
  margin-bottom: 1rem !important;
}

.EditGrowthPortfolio-Probability {
  margin-right: -1px !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;

  .ProjectionsChart-Tooltip {
    display: block;
    width: auto;
    border: 0;
  }
}

.EditGrowthPortfolio-PresetLabel {
  display: flex;
  white-space: nowrap;
}

.EditGrowthPortfolio-ChartHeadline {
  position: relative;
  z-index: 9;
  padding-bottom: 1.5rem;
}

.EditGrowthPortfolio-Footer {
  border: none;
}

.EditGrowthPortfolio-Buttons {
  position: sticky;
  z-index: 999;
  bottom: -0.625em;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5em;

  &:empty {
    display: none;
  }
}

.EditGrowthPortfolio-FormColumns {
  .EditGrowthPortfolio-FormColumn:nth-child(1) {
    flex-grow: 0.8;
  }

  .EditGrowthPortfolio-FormColumn:nth-child(3) {
    flex-grow: 0.7;
  }
}

.EditGrowthPortfolio-Message {
  display: inline-block;
}

.EditGrowthPortfolio_phone {
  position: relative;

  &::after {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 320px;
    background-color: var(--background-muted);
    visibility: visible;
  }

  .EditGrowthPortfolio-Chart {
    margin-bottom: 0.75rem !important;
  }

  .EditGrowthPortfolio-Probability {
    position: relative;
    z-index: 5;
  }

  .EditGrowthPortfolio-Buttons {
    bottom: 0;
    padding-bottom: 1em;
    margin-top: 0.375em;

    .Button {
      &:last-child {
        margin-top: 0.625em;
      }

      .Button-Inner {
        min-width: 12.3em;
      }
    }
  }

  .EditGrowthPortfolio-Message {
    margin-top: 1.125em;
  }
}

.EditGrowthPortfolio_desktop {
  width: 100%;
  margin-bottom: 2rem !important;
}
