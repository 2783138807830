.Empty_StepsRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Empty_phone {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
