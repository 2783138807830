.Delete {
  position: relative;
}

.Delete-Link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 2em;
  height: 2em;
  padding: 0.2em;
  margin: auto;
}

.Delete-Icon {
  margin-top: -0.0625em;
}
