.YearlyReviewRetakeForm-List {
  margin-bottom: 20px;
}

.YearlyReviewRetakeForm-SubInputText {
  margin-top: 18px;
  margin-bottom: 20px;
}

.YearlyReviewRetakeForm_phone {
  .YearlyReviewRetakeForm-SubInputText {
    margin-top: 26px;
    margin-bottom: 28px;
  }

  .YearlyReviewRetakeForm-Buttons {
    flex-direction: column-reverse;

    .ColumnarLayout-Column:first-child {
      margin-top: 12px;
    }
  }
}
