.EditableSecurity {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: var(--background-default);
}

.EditableSecurity-Weight {
  flex: 0 0 92px;
}

.EditableSecurity-Delete {
  position: relative;
  display: flex;
  height: 32px;
  align-items: center;
}

.EditableSecurity-DeleteIcon {
  width: 18px;
  height: 18px;
  margin-right: -3px;
  margin-left: -3px;
}

.EditableSecurity-Delete::after {
  position: absolute;
  top: -24px;
  right: 0;
  bottom: -24px;
  display: block;
  width: 48px;
  height: 48px;
  margin: auto;
  content: '';
}

.EditableSecurity_new {
  background: var(--primary-surface-10);
}

.EditableSecurity_delete_space {
  .EditableSecurity-Delete {
    opacity: 0;
    pointer-events: none;
  }
}

.EditableSecurity_desktop {
  .EditableSecurity-Delete {
    height: 48px;
  }
}
