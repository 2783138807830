.PresetCard {
    height: 104px;
    cursor: pointer;
}

.PresetCard:hover {
  background-color: var(--background-overlay-opaque);
}

.PresetCard_Risk {
    border-radius: 6px;
    background: var(--background-overlay);
}

.PresetCard_ImageContainer {
    overflow: hidden;
    height: 104px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

.PresetCard_ImageContainer_mobile {
    max-width: 150px;
}

.PresetCard_Image {
    height: 104px;
}
