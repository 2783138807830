.PhoneInput {
  position: relative;
}

.PhoneInput-Input::placeholder {
  color: inherit;
}

.PhoneInput:not(.PhoneInput_partiallyHidden) .PhoneInput-Input {
  padding-left: 2.5em;
}

.PhoneInput-Flags {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5em;
  height: 100%;
}

.PhoneInput-Flag {
  position: absolute;
  width: 16px;
  height: 11px;
  margin: auto;
  background-image: url('./flags.png');
  inset: 0 0 0 calc(0.5em - 0.0625rem);
}

.PhoneInput-Flag_ad {
  background-position: -16px 0;
}

.PhoneInput-Flag_ae {
  background-position: -32px 0;
}

.PhoneInput-Flag_af {
  background-position: -48px 0;
}

.PhoneInput-Flag_ag {
  background-position: -64px 0;
}

.PhoneInput-Flag_ai {
  background-position: -80px 0;
}

.PhoneInput-Flag_al {
  background-position: -96px 0;
}

.PhoneInput-Flag_am {
  background-position: -112px 0;
}

.PhoneInput-Flag_ao {
  background-position: -128px 0;
}

.PhoneInput-Flag_ar {
  background-position: -144px 0;
}

.PhoneInput-Flag_as {
  background-position: -160px 0;
}

.PhoneInput-Flag_at {
  background-position: -176px 0;
}

.PhoneInput-Flag_au {
  background-position: -192px 0;
}

.PhoneInput-Flag_aw {
  background-position: -208px 0;
}

.PhoneInput-Flag_az {
  background-position: -224px 0;
}

.PhoneInput-Flag_ba {
  background-position: -240px 0;
}

.PhoneInput-Flag_bb {
  background-position: 0 -11px;
}

.PhoneInput-Flag_bd {
  background-position: -16px -11px;
}

.PhoneInput-Flag_be {
  background-position: -32px -11px;
}

.PhoneInput-Flag_bf {
  background-position: -48px -11px;
}

.PhoneInput-Flag_bg {
  background-position: -64px -11px;
}

.PhoneInput-Flag_bh {
  background-position: -80px -11px;
}

.PhoneInput-Flag_bi {
  background-position: -96px -11px;
}

.PhoneInput-Flag_bj {
  background-position: -112px -11px;
}

.PhoneInput-Flag_bm {
  background-position: -128px -11px;
}

.PhoneInput-Flag_bn {
  background-position: -144px -11px;
}

.PhoneInput-Flag_bo {
  background-position: -160px -11px;
}

.PhoneInput-Flag_br {
  background-position: -176px -11px;
}

.PhoneInput-Flag_bs {
  background-position: -192px -11px;
}

.PhoneInput-Flag_bt {
  background-position: -208px -11px;
}

.PhoneInput-Flag_bw {
  background-position: -224px -11px;
}

.PhoneInput-Flag_by {
  background-position: -240px -11px;
}

.PhoneInput-Flag_bz {
  background-position: 0 -22px;
}

.PhoneInput-Flag_ca {
  background-position: -16px -22px;
}

.PhoneInput-Flag_cd {
  background-position: -32px -22px;
}

.PhoneInput-Flag_cf {
  background-position: -48px -22px;
}

.PhoneInput-Flag_cg {
  background-position: -64px -22px;
}

.PhoneInput-Flag_ch {
  background-position: -80px -22px;
}

.PhoneInput-Flag_ci {
  background-position: -96px -22px;
}

.PhoneInput-Flag_ck {
  background-position: -112px -22px;
}

.PhoneInput-Flag_cl {
  background-position: -128px -22px;
}

.PhoneInput-Flag_cm {
  background-position: -144px -22px;
}

.PhoneInput-Flag_cn {
  background-position: -160px -22px;
}

.PhoneInput-Flag_co {
  background-position: -176px -22px;
}

.PhoneInput-Flag_cr {
  background-position: -192px -22px;
}

.PhoneInput-Flag_cu {
  background-position: -208px -22px;
}

.PhoneInput-Flag_cv {
  background-position: -224px -22px;
}

.PhoneInput-Flag_cw {
  background-position: -240px -22px;
}

.PhoneInput-Flag_cy {
  background-position: 0 -33px;
}

.PhoneInput-Flag_cz {
  background-position: -16px -33px;
}

.PhoneInput-Flag_de {
  background-position: -32px -33px;
}

.PhoneInput-Flag_dj {
  background-position: -48px -33px;
}

.PhoneInput-Flag_dk {
  background-position: -64px -33px;
}

.PhoneInput-Flag_dm {
  background-position: -80px -33px;
}

.PhoneInput-Flag_do {
  background-position: -96px -33px;
}

.PhoneInput-Flag_dz {
  background-position: -112px -33px;
}

.PhoneInput-Flag_ec {
  background-position: -128px -33px;
}

.PhoneInput-Flag_ee {
  background-position: -144px -33px;
}

.PhoneInput-Flag_eg {
  background-position: -160px -33px;
}

.PhoneInput-Flag_er {
  background-position: -176px -33px;
}

.PhoneInput-Flag_es {
  background-position: -192px -33px;
}

.PhoneInput-Flag_et {
  background-position: -208px -33px;
}

.PhoneInput-Flag_fi {
  background-position: -224px -33px;
}

.PhoneInput-Flag_fj {
  background-position: -240px -33px;
}

.PhoneInput-Flag_fk {
  background-position: 0 -44px;
}

.PhoneInput-Flag_fm {
  background-position: -16px -44px;
}

.PhoneInput-Flag_fo {
  background-position: -32px -44px;
}

.PhoneInput-Flag_fr,
.PhoneInput-Flag_bl,
.PhoneInput-Flag_mf {
  background-position: -48px -44px;
}

.PhoneInput-Flag_ga {
  background-position: -64px -44px;
}

.PhoneInput-Flag_gb {
  background-position: -80px -44px;
}

.PhoneInput-Flag_gd {
  background-position: -96px -44px;
}

.PhoneInput-Flag_ge {
  background-position: -112px -44px;
}

.PhoneInput-Flag_gf {
  background-position: -128px -44px;
}

.PhoneInput-Flag_gh {
  background-position: -144px -44px;
}

.PhoneInput-Flag_gi {
  background-position: -160px -44px;
}

.PhoneInput-Flag_gl {
  background-position: -176px -44px;
}

.PhoneInput-Flag_gm {
  background-position: -192px -44px;
}

.PhoneInput-Flag_gn {
  background-position: -208px -44px;
}

.PhoneInput-Flag_gp {
  background-position: -224px -44px;
}

.PhoneInput-Flag_gq {
  background-position: -240px -44px;
}

.PhoneInput-Flag_gr {
  background-position: 0 -55px;
}

.PhoneInput-Flag_gt {
  background-position: -16px -55px;
}

.PhoneInput-Flag_gu {
  background-position: -32px -55px;
}

.PhoneInput-Flag_gw {
  background-position: -48px -55px;
}

.PhoneInput-Flag_gy {
  background-position: -64px -55px;
}

.PhoneInput-Flag_hk {
  background-position: -80px -55px;
}

.PhoneInput-Flag_hn {
  background-position: -96px -55px;
}

.PhoneInput-Flag_hr {
  background-position: -112px -55px;
}

.PhoneInput-Flag_ht {
  background-position: -128px -55px;
}

.PhoneInput-Flag_hu {
  background-position: -144px -55px;
}

.PhoneInput-Flag_id {
  background-position: -160px -55px;
}

.PhoneInput-Flag_ie {
  background-position: -176px -55px;
}

.PhoneInput-Flag_il {
  background-position: -192px -55px;
}

.PhoneInput-Flag_in {
  background-position: -208px -55px;
}

.PhoneInput-Flag_io {
  background-position: -224px -55px;
}

.PhoneInput-Flag_iq {
  background-position: -240px -55px;
}

.PhoneInput-Flag_ir {
  background-position: 0 -66px;
}

.PhoneInput-Flag_is {
  background-position: -16px -66px;
}

.PhoneInput-Flag_it {
  background-position: -32px -66px;
}

.PhoneInput-Flag_jm {
  background-position: -48px -66px;
}

.PhoneInput-Flag_jo {
  background-position: -64px -66px;
}

.PhoneInput-Flag_jp {
  background-position: -80px -66px;
}

.PhoneInput-Flag_ke {
  background-position: -96px -66px;
}

.PhoneInput-Flag_kg {
  background-position: -112px -66px;
}

.PhoneInput-Flag_kh {
  background-position: -128px -66px;
}

.PhoneInput-Flag_ki {
  background-position: -144px -66px;
}

.PhoneInput-Flag_km {
  background-position: -160px -66px;
}

.PhoneInput-Flag_kn {
  background-position: -176px -66px;
}

.PhoneInput-Flag_kp {
  background-position: -192px -66px;
}

.PhoneInput-Flag_kr {
  background-position: -208px -66px;
}

.PhoneInput-Flag_kw {
  background-position: -224px -66px;
}

.PhoneInput-Flag_ky {
  background-position: -240px -66px;
}

.PhoneInput-Flag_kz {
  background-position: 0 -77px;
}

.PhoneInput-Flag_la {
  background-position: -16px -77px;
}

.PhoneInput-Flag_lb {
  background-position: -32px -77px;
}

.PhoneInput-Flag_lc {
  background-position: -48px -77px;
}

.PhoneInput-Flag_li {
  background-position: -64px -77px;
}

.PhoneInput-Flag_lk {
  background-position: -80px -77px;
}

.PhoneInput-Flag_lr {
  background-position: -96px -77px;
}

.PhoneInput-Flag_ls {
  background-position: -112px -77px;
}

.PhoneInput-Flag_lt {
  background-position: -128px -77px;
}

.PhoneInput-Flag_lu {
  background-position: -144px -77px;
}

.PhoneInput-Flag_lv {
  background-position: -160px -77px;
}

.PhoneInput-Flag_ly {
  background-position: -176px -77px;
}

.PhoneInput-Flag_ma {
  background-position: -192px -77px;
}

.PhoneInput-Flag_mc {
  background-position: -208px -77px;
}

.PhoneInput-Flag_md {
  background-position: -224px -77px;
}

.PhoneInput-Flag_me {
  height: 12px;
  background-position: -112px -154px;
}

.PhoneInput-Flag_mg {
  background-position: 0 -88px;
}

.PhoneInput-Flag_mh {
  background-position: -16px -88px;
}

.PhoneInput-Flag_mk {
  background-position: -32px -88px;
}

.PhoneInput-Flag_ml {
  background-position: -48px -88px;
}

.PhoneInput-Flag_mm {
  background-position: -64px -88px;
}

.PhoneInput-Flag_mn {
  background-position: -80px -88px;
}

.PhoneInput-Flag_mo {
  background-position: -96px -88px;
}

.PhoneInput-Flag_mp {
  background-position: -112px -88px;
}

.PhoneInput-Flag_mq {
  background-position: -128px -88px;
}

.PhoneInput-Flag_mr {
  background-position: -144px -88px;
}

.PhoneInput-Flag_ms {
  background-position: -160px -88px;
}

.PhoneInput-Flag_mt {
  background-position: -176px -88px;
}

.PhoneInput-Flag_mu {
  background-position: -192px -88px;
}

.PhoneInput-Flag_mv {
  background-position: -208px -88px;
}

.PhoneInput-Flag_mw {
  background-position: -224px -88px;
}

.PhoneInput-Flag_mx {
  background-position: -240px -88px;
}

.PhoneInput-Flag_my {
  background-position: 0 -99px;
}

.PhoneInput-Flag_mz {
  background-position: -16px -99px;
}

.PhoneInput-Flag_na {
  background-position: -32px -99px;
}

.PhoneInput-Flag_nc {
  background-position: -48px -99px;
}

.PhoneInput-Flag_ne {
  background-position: -64px -99px;
}

.PhoneInput-Flag_nf {
  background-position: -80px -99px;
}

.PhoneInput-Flag_ng {
  background-position: -96px -99px;
}

.PhoneInput-Flag_ni {
  background-position: -112px -99px;
}

.PhoneInput-Flag_nl,
.PhoneInput-Flag_bq {
  background-position: -128px -99px;
}

.PhoneInput-Flag_no {
  background-position: -144px -99px;
}

.PhoneInput-Flag_np {
  background-position: -160px -99px;
}

.PhoneInput-Flag_nr {
  background-position: -176px -99px;
}

.PhoneInput-Flag_nu {
  background-position: -192px -99px;
}

.PhoneInput-Flag_nz {
  background-position: -208px -99px;
}

.PhoneInput-Flag_om {
  background-position: -224px -99px;
}

.PhoneInput-Flag_pa {
  background-position: -240px -99px;
}

.PhoneInput-Flag_pe {
  background-position: 0 -110px;
}

.PhoneInput-Flag_pf {
  background-position: -16px -110px;
}

.PhoneInput-Flag_pg {
  background-position: -32px -110px;
}

.PhoneInput-Flag_ph {
  background-position: -48px -110px;
}

.PhoneInput-Flag_pk {
  background-position: -64px -110px;
}

.PhoneInput-Flag_pl {
  background-position: -80px -110px;
}

.PhoneInput-Flag_pm {
  background-position: -96px -110px;
}

.PhoneInput-Flag_pr {
  background-position: -112px -110px;
}

.PhoneInput-Flag_ps {
  background-position: -128px -110px;
}

.PhoneInput-Flag_pt {
  background-position: -144px -110px;
}

.PhoneInput-Flag_pw {
  background-position: -160px -110px;
}

.PhoneInput-Flag_py {
  background-position: -176px -110px;
}

.PhoneInput-Flag_qa {
  background-position: -192px -110px;
}

.PhoneInput-Flag_re {
  background-position: -208px -110px;
}

.PhoneInput-Flag_ro {
  background-position: -224px -110px;
}

.PhoneInput-Flag_rs {
  background-position: -240px -110px;
}

.PhoneInput-Flag_ru {
  background-position: 0 -121px;
}

.PhoneInput-Flag_rw {
  background-position: -16px -121px;
}

.PhoneInput-Flag_sa {
  background-position: -32px -121px;
}

.PhoneInput-Flag_sb {
  background-position: -48px -121px;
}

.PhoneInput-Flag_sc {
  background-position: -64px -121px;
}

.PhoneInput-Flag_sd {
  background-position: -80px -121px;
}

.PhoneInput-Flag_se {
  background-position: -96px -121px;
}

.PhoneInput-Flag_sg {
  background-position: -112px -121px;
}

.PhoneInput-Flag_sh {
  background-position: -128px -121px;
}

.PhoneInput-Flag_si {
  background-position: -144px -121px;
}

.PhoneInput-Flag_sk {
  background-position: -160px -121px;
}

.PhoneInput-Flag_sl {
  background-position: -176px -121px;
}

.PhoneInput-Flag_sm {
  background-position: -192px -121px;
}

.PhoneInput-Flag_sn {
  background-position: -208px -121px;
}

.PhoneInput-Flag_so {
  background-position: -224px -121px;
}

.PhoneInput-Flag_sr {
  background-position: -240px -121px;
}

.PhoneInput-Flag_ss {
  background-position: 0 -132px;
}

.PhoneInput-Flag_st {
  background-position: -16px -132px;
}

.PhoneInput-Flag_sv {
  background-position: -32px -132px;
}

.PhoneInput-Flag_sx {
  background-position: -48px -132px;
}

.PhoneInput-Flag_sy {
  background-position: -64px -132px;
}

.PhoneInput-Flag_sz {
  background-position: -80px -132px;
}

.PhoneInput-Flag_tc {
  background-position: -96px -132px;
}

.PhoneInput-Flag_td {
  background-position: -112px -132px;
}

.PhoneInput-Flag_tg {
  background-position: -128px -132px;
}

.PhoneInput-Flag_th {
  background-position: -144px -132px;
}

.PhoneInput-Flag_tj {
  background-position: -160px -132px;
}

.PhoneInput-Flag_tk {
  background-position: -176px -132px;
}

.PhoneInput-Flag_tl {
  background-position: -192px -132px;
}

.PhoneInput-Flag_tm {
  background-position: -208px -132px;
}

.PhoneInput-Flag_tn {
  background-position: -224px -132px;
}

.PhoneInput-Flag_to {
  background-position: -240px -132px;
}

.PhoneInput-Flag_tr {
  background-position: 0 -143px;
}

.PhoneInput-Flag_tt {
  background-position: -16px -143px;
}

.PhoneInput-Flag_tv {
  background-position: -32px -143px;
}

.PhoneInput-Flag_tw {
  background-position: -48px -143px;
}

.PhoneInput-Flag_tz {
  background-position: -64px -143px;
}

.PhoneInput-Flag_ua {
  background-position: -80px -143px;
}

.PhoneInput-Flag_ug {
  background-position: -96px -143px;
}

.PhoneInput-Flag_us {
  background-position: -112px -143px;
}

.PhoneInput-Flag_uy {
  background-position: -128px -143px;
}

.PhoneInput-Flag_uz {
  background-position: -144px -143px;
}

.PhoneInput-Flag_va {
  background-position: -160px -143px;
}

.PhoneInput-Flag_vc {
  background-position: -176px -143px;
}

.PhoneInput-Flag_ve {
  background-position: -192px -143px;
}

.PhoneInput-Flag_vg {
  background-position: -208px -143px;
}

.PhoneInput-Flag_vi {
  background-position: -224px -143px;
}

.PhoneInput-Flag_vn {
  background-position: -240px -143px;
}

.PhoneInput-Flag_vu {
  background-position: 0 -154px;
}

.PhoneInput-Flag_wf {
  background-position: -16px -154px;
}

.PhoneInput-Flag_ws {
  background-position: -32px -154px;
}

.PhoneInput-Flag_ye {
  background-position: -48px -154px;
}

.PhoneInput-Flag_za {
  background-position: -64px -154px;
}

.PhoneInput-Flag_zm {
  background-position: -80px -154px;
}

.PhoneInput-Flag_zw {
  background-position: -96px -154px;
}
