.Switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .Switcher-Switch {
    height: 32px;
  }

  .react-toggle-track {
    width: 52px;
    height: 32px;
  }

  .react-toggle-thumb {
    top: 2px;
    left: 2px;
    width: 28px;
    height: 28px;
  }

  .react-toggle .react-toggle-track,
  .react-toggle:hover .react-toggle-track {
    background-color: var(--background-border);
  }

  .react-toggle--checked .react-toggle-track,
  .react-toggle--checked:hover .react-toggle-track {
    background-color: var(--secondary-default);
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 22px;
  }

  .react-toggle .react-toggle-thumb {
    border: none;
    background-color: var(--background-thumb);
    box-shadow: none;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }
}

.Switcher_multiline {
  align-items: flex-start;

  .Switcher-Switch {
    padding-top: 4px;
  }
}

.Switcher-Label {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.Switcher_phone {
  flex-direction: row-reverse;
  justify-content: space-between;

  .Switcher-Label {
    margin-right: 24px;
    margin-left: 0;
  }
}

.Switcher_smallDistance .Switcher-Label {
  margin-left: 12px;
}

.Switcher_smallDistance.Switcher_phone .Switcher-Label {
  margin-right: 12px;
  margin-left: 0;
}
