.SecurityLink {
  .SecurityLink-Content {
    transition: all var(--animation-speed-default) var(--animation-easing-default);
  }

  &:focus-visible {
    .SecurityLink-Content {
      background: var(--background-minor);
      transition: none;
    }
  }

  &:hover,
  &:active {
    .SecurityLink-Content {
      background: var(--primary-surface-10);
      transition: none;
    }
  }
}

.SecurityLink_loading {
  pointer-events: none;
}

.SecurityLink_desktop .SecurityLink-Content {
  border: 1px solid var(--background-default);
  border-radius: 2px;
}
