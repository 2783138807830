.IncomeProjectionsChart {
  position: relative;
}

.IncomeProjectionsChart-LegendMark {
  margin-bottom: 0.75em;
}

.IncomeProjectionsChart-Chart {
  padding-bottom: 1.6em;
}

.IncomeProjectionsChart-Past {
  .IncomeProjectionsChart-Column:last-child {
    .IncomeProjectionsChart-Tick {
      padding-right: 0.5em;
      margin-right: -0.5em;
    }

    .IncomeProjectionsChart-Text {
      margin-left: -0.5em;
    }
  }
}

.IncomeProjectionsChart-Future {
  .IncomeProjectionsChart-Column:first-child {
    .IncomeProjectionsChart-Tick {
      padding-left: 0.5em;
      margin-left: -0.5em;
    }

    .IncomeProjectionsChart-Text {
      margin-right: -0.5em;
    }
  }
}

.IncomeProjectionsChart-Table {
  margin-top: 1em;

  .IncomeProjectionsChart-TablePast + .IncomeProjectionsChart-TableFuture {
    margin-left: 3em;
  }
}

.IncomeProjectionsChart-Empty {
  position: absolute;
  top: 0;
  bottom: 0;
}

.IncomeProjectionsChart-Tooltip {
  .Tooltip-Tip {
    right: -1em;
    left: unset;

    .ie & {
      left: auto;
    }
  }
}

.IncomeProjectionsChart_phone {
  .IncomeProjectionsChart-LegendNumber {
    .LegendNumber-Tooltip {
      margin-left: -0.375em;
    }
  }
}
