.AddressPeriodSelect {
  display: flex;
}

.AddressPeriodSelect-Label {
  flex-grow: 1;
  margin-top: 48px;
}

.AddressPeriodSelect-Select {
  position: relative;
  width: 88px;
}

.AddressPeriodSelect_tinyPhone {
  .AddressPeriodSelect-Select {
    width: 64px;
  }
}

.AddressPeriodSelect-Error {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 2px;
  white-space: normal;
}
