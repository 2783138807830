.LineChart {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 12px;
  border-radius: 4px;
  background: var(--primary-surface-10);
}

.LineChart_Item {
  min-width: 1px;
  height: 100%;
  margin-right: 2px;
}

.LineChart_Item:last-child {
  margin-right: 0;
}
