/* .AddressSearch {

} */

.AddressSearch-Selected {
  padding: 1.125rem;
  border-radius: 0.325rem;
  margin-top: 1rem;
  background-color: var(--background-minor);

  @media screen and (prefers-color-scheme: dark) {
    background-color: var(--background-muted);
  }
}

.AddressSearch-EditLink {
  display: block;
  margin-top: 1rem;
}
