.Arrow {
  position: relative;
  width: 100%;
  height: 0.0625rem;
  background-color: var(--content-on-background-solid);

  &::before,
  &::after {
    position: absolute;
    right: 0;
    width: 0.375rem;
    height: 0.0625rem;
    background-color: var(--content-on-background-solid);
    content: '';
  }

  &::before {
    bottom: 0.125rem;
    transform: rotate(45deg);
  }

  &::after {
    top: 0.125rem;
    transform: rotate(-45deg);
  }
}
