.NewPeriodSelect {
  display: flex;
  padding: 2px;
  border-radius: 6px;
  background-color: var(--background-muted);
}

.NewPeriodSelect-PeriodWrapper {
  flex-basis: 0;
  flex-grow: 1;
}

.NewPeriodSelect-Period {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 2px 0;
  border: initial;
  border-radius: 6px;
  appearance: none;
  background: initial;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  outline: 0;
  text-align: center;
  transition:
    background var(--animation-speed-default) var(--animation-easing-default),
    box-shadow var(--animation-speed-default) var(--animation-easing-default),
    color var(--animation-speed-default) var(--animation-easing-default),
    border var(--animation-speed-default) var(--animation-easing-default);
}

.NewPeriodSelect-Period:not(.NewPeriodSelect-Period_active) {
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background: var(--background-default);
    transition:
      background var(--animation-speed-zero) var(--animation-easing-default),
      box-shadow var(--animation-speed-zero) var(--animation-easing-default),
      color var(--animation-speed-zero) var(--animation-easing-default);
  }

  &::before {
    position: absolute;
    display: block;
    width: calc(100% + 8px);
    height: 48px;
    margin: auto;
    content: '';
    inset: -24px;
  }

  &:focus-visible {
    padding: 1px 0;
    border: 1px solid var(--content-on-background-default);
  }
}

.NewPeriodSelect-Period:active {
  border: none !important;

  &:not(.NewPeriodSelect-Period_phone) {
    padding: 2px 0 !important;
  }
}

.NewPeriodSelect-Period_active {
  z-index: 1;
  border: none !important;
  background: var(--background-card-opaque);
  box-shadow: var(--shadow-level-2);
  pointer-events: none;
  transition:
    background var(--animation-speed-zero) var(--animation-easing-default),
    box-shadow var(--animation-speed-zero) var(--animation-easing-default),
    color var(--animation-speed-zero) var(--animation-easing-default);
}

.NewPeriodSelect-Period_phone {
  padding: 0;
}
