.SavingsPlanSetup_Input {
  border-width: 2px;
  background: var(--content-on-color-white);
}

.SavingsPlanSetup_Input.Input_error {
  border-color: var(--content-on-color-red);
  color: var(--content-on-color-red);
}

.SavingsPlanSetup_ButtonBackground {
  background: var(--primary-default);
}
