.Hr {
  all: initial;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--background-border);
}

.Hr_primary-surface-25 {
  background-color: var(--primary-surface-25);
}

.Hr_relative {
  position: relative;
  z-index: 1000;
}
