.AddressList {
  position: relative;
}

.AddressList-Items {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  max-height: 15rem;
  border: 1px solid var(--background-border);
  border-radius: 0.325rem;
  background-color: var(--background-default);
  overflow-y: auto;

  .AddressList_phone & {
    position: static;
  }
}

.AddressList-Item {
  padding: 0.625rem 0.75rem;
  border-bottom: 1px solid var(--background-border);
  cursor: pointer;
  transition:
    background-color var(--animation-speed-default) var(--animation-easing-default),
    color var(--animation-speed-default) var(--animation-easing-default);

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &:focus-visible,
  &:active,
  &:hover:active {
    transition:
      background-color var(--animation-speed-zero) var(--animation-easing-default),
      color var(--animation-speed-zero) var(--animation-easing-default);
  }

  &:hover,
  &:focus-visible {
    background-color: var(--primary-surface-85);
    color: var(--content-on-color-default);
  }

  &:active,
  &:hover:active,
  &:focus-visible:active {
    background-color: var(--primary-action);
    color: var(--content-on-color-default);
  }
}
