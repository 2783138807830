.SelectRemote {
  position: relative;
}

.SelectRemote-List {
  position: absolute;
  z-index: 1;
  top: 2.25rem;
  right: 0;
  left: 0;
  max-height: 10rem;
  border-radius: 0 0 0.325rem 0.325rem;
  border-right: 1px solid var(--background-border);
  border-bottom: 1px solid var(--background-border);
  border-left: 1px solid var(--background-border);
  background-color: var(--background-default);
  overflow-y: auto;
}

.SelectRemote-List_relative {
  position: relative;
  top: 0;
}

.SelectRemote-List-Item {
  padding: 0.625rem 0.75rem;
  border-bottom: 1px solid var(--background-border);
  cursor: pointer;
  transition:
    background-color var(--animation-speed-default) var(--animation-easing-default),
    color var(--animation-speed-default) var(--animation-easing-default);

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &:focus-visible,
  &:active,
  &:hover:active {
    transition:
      background-color var(--animation-speed-zero) var(--animation-easing-default),
      color var(--animation-speed-zero) var(--animation-easing-default);
  }

  &:hover,
  &:focus-visible {
    background-color: var(--primary-surface-85);
    color: var(--content-on-color-default);
  }

  &:active,
  &:hover:active,
  &:focus-visible:active {
    background-color: var(--primary-action);
    color: var(--content-on-color-default);
  }
}
