.TwoColumns_synchronizedScroll {
  position: relative;

  .TwoColumns-SidebarContent {
    position: fixed;
    top: 0;
    overflow: scroll;
    height: calc(100vh - var(--two-columns-offset-top, 0) - var(--two-columns-offset-bottom, 0));
    padding: var(--two-columns-offset-top, 0) 40px 0;
    margin: 0 -40px;
    -ms-overflow-style: none;
    pointer-events: none;
    scrollbar-width: none;
    will-change: padding-top;

    > * {
      pointer-events: all;
    }

    @supports (height: 100dvh) {
      height: calc(100dvh - var(--two-columns-offset-top, 0) - var(--two-columns-offset-bottom, 0));
    }
  }

  .TwoColumns-SidebarContent::-webkit-scrollbar {
    display: none;
  }
}

.TwoColumns_stickySidebar {
  position: relative;
}

.TwoColumns-Sidebar_unpinAtBottom {
  justify-content: flex-end;

  .TwoColumns-SidebarContent {
    position: static;
    display: contents;
  }
}
