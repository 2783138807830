.IncomeBars,
.IncomeBars-Column {
  height: 100%;
}

.IncomeBars {
  align-items: flex-end;
  justify-content: center;
}

.IncomeBars-Column {
  max-width: 6.25em;
}

.IncomeBars-Tick,
.IncomeBars-Part {
  width: 100%;
}

.IncomeBars-Tick {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.IncomeBars-Part {
  opacity: 0.375;
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);
}

.IncomeBars-Part_active {
  opacity: 0.5;
  transition: opacity var(--animation-speed-zero) var(--animation-easing-default);
}

.IncomeBars-Part_withLine::before {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: calc(50% - 1px);
  width: 1px;
  height: 100%;
  border-right: 1px solid var(--content-on-background-additional);
  content: ' ';
}

.IncomeBars_horde {
  .IncomeBars-Column:first-child {
    .IncomeBars-TextNode {
      padding-left: calc(50% - 1.25em);
      margin-right: -9999%;
      text-align: left;
    }
  }

  .IncomeBars-Column:last-child {
    .IncomeBars-TextNode {
      padding-right: calc(50% - 1.25em);
      margin-left: -9999%;
      text-align: right;
    }
  }
}
