.Global-Preloader {
  z-index: 1001;

  .Modal-Window {
    justify-content: center;
  }

  .Modal-Backdrop {
    background-color: var(--background-overlay-opaque);
  }
}

.GlobalPreloader-TextHolder {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 15rem;
  transform: translate(-50%, -50%);
}

.GlobalPreloader-Text {
  margin-top: 170px;
  color: var(--secondary-default);
  text-align: center;
}

.GlobalPreloader-Icon {
  text-align: center;
}

.GlobalPreloader-Text,
.GlobalPreloader-Icon {
  &_enter {
    opacity: 0;
    transition: opacity var(--animation-speed-slow) linear;
  }

  &_enter_active {
    opacity: 1;
  }

  &_leave {
    opacity: 1;
  }

  &_leave_active {
    opacity: 0;
  }
}
