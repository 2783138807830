.FileUploader {
  all: initial;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  border: 2px dashed var(--background-border);
  border-radius: 6px;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: initial;
  font-weight: inherit;
}

.FileUploader-ImageHolder {
  position: relative;

  svg {
    width: 100px;
    height: 100px;
  }
}

.FileUploader-Status {
  position: absolute;
  width: 100px;
  height: 100px;
  inset: 0;
}

.FileUploader-Status_done,
.FileUploader-Status_failed,
.FileUploader-Status_pending {
  border-radius: 50%;
  background: var(--status-success-45);
}

.FileUploader-Status_failed {
  background: var(--status-error-25);
}

.FileUploader:hover,
.FileUploader:focus-visible {
  border-color: var(--content-on-background-muted);
}

.FileUploader_disabled {
  border-color: transparent;
  pointer-events: none;

  .FileUploader-Button {
    visibility: hidden;
  }

  .FileUploader-Icon {
    visibility: hidden;
  }
}

.FileUploader_phone {
  border-style: none;

  .FileUploader-ImageHolder {
    svg {
      width: 72px;
      height: 72px;
    }
  }

  .FileUploader-Status {
    width: 72px;
    height: 72px;
  }
}
