.Chip {
  display: inline-block;
  border-radius: 9999px;
  background-color: var(--primary-surface-10);
  color: var(--content-on-background-primary);
  cursor: pointer;
  fill: var(--content-on-background-primary);
  line-height: 1;
  transition: background-color var(--animation-speed-default) var(--animation-easing-default);
}

.Chip:hover {
  background-color: var(--primary-surface-25);
  transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
}

.Chip_disabled {
  color: var(--primary-disabled);
}

.Chip-Icon,
.Chip-Content {
  position: relative;
}

.Chip-Icon {
  top: 0.25px;
  margin-right: -1px;
}

.Chip-Content {
  top: -0.5px;
}
