.ContentHolder {
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  flex-direction: column;
}

.ContentHolder_stretch {
  flex-grow: 1;
}
