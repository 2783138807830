.SelectableCard {
  display: block;
  width: 100%;
}

.SelectableCard-Card {
  border: 0;
  background-color: var(--background-card);
  transition: box-shadow var(--animation-speed-fast) var(--animation-easing-default);

  &.Card {
    margin: 0 auto;
  }
}

.SelectableCard:hover:not(.SelectableCard_selected),
.SelectableCard:focus-visible:not(.SelectableCard_selected) {
  .SelectableCard-Card {
    box-shadow: var(--shadow-level-4);
    transition: box-shadow var(--animation-speed-default) var(--animation-easing-default);
  }
}

.SelectableCard_selected {
  color: var(--content-on-background-default);
  fill: var(--content-on-background-default);

  .SelectableCard-Card {
    box-shadow: 0 0 0 2px var(--status-success-65) inset;
  }
}

.SelectableCard:hover,
.SelectableCard:focus-visible {
  color: var(--status-error);
  fill: var(--status-error);
}

.SelectableCard_stretch {
  display: flex;
  flex-grow: 1;

  .SelectableCard-Card {
    display: flex;
    flex-basis: 0;
    flex-flow: column wrap;
    flex-grow: 1;
  }

  .SelectableCard-Body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.SelectableCard_center {
  .SelectableCard-Card {
    justify-content: center;
  }

  .SelectableCard-Body {
    flex-grow: initial;
  }
}
