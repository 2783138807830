.PortfolioChart-TabsLine {
  position: relative;
  display: flex;
}

.PortfolioChart-TabsLineBorder {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.0625em;
  background: var(--background-border);
}

.PortfolioChart-Tabs {
  position: relative;
  z-index: 1;
}

.PortfolioChart_desktop {
  .PortfolioChart-Tabs {
    width: 21em;
  }

  .PortfolioChart-Tab {
    padding: 0 1.25em;
    line-height: normal;

    .Tab-Inner {
      width: 100%;
      padding: 1.357em 0 1.143em;
    }
  }

  .PortfolioChart-Tab:first-child {
    padding-left: 0;
  }
}

.PortfolioChart_phone {
  .PortfolioChart-Tabs {
    width: 100%;
  }

  .PortfolioChart-Tab {
    padding: 0 1.75em;
    line-height: normal;

    .Tab-Inner {
      padding: 1.357em 0 1.143em;
    }
  }
}
