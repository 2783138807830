.PhoneNumberSetting_PhoneContainer {
  margin-left: -16px;
}

.PhoneNumberSetting_Phone {
  overflow: hidden;
  max-width: 40px;
  height: auto;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  border: none;
  background-color: var(--background-default);
}
