.HorizontalChartGroupWithSummary-AssetSummary {
  padding-right: 16px;
  padding-left: 16px;
  margin-top: -4px;
}

.HorizontalChartGroupWithSummary_desktop {
  .HorizontalChartGroupWithSummary-Card {
    padding-bottom: 2px;
  }

  .HorizontalChartGroupWithSummary-BottomInfo {
    padding: 48px 64px 16px 0;
  }
}

.HorizontalChartGroupWithSummary_phone {
  .HorizontalChartGroupWithSummary-BottomInfo {
    padding: 32px 0 0;
  }
}
