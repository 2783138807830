.PromoBanner {
  display: block;
  overflow: hidden;
  border-radius: 4px;
  background: var(--primary-default);
  transition: background var(--animation-speed-default) var(--animation-easing-default);
}

.PromoBanner-Icon {
  margin-top: -3px;
  margin-bottom: -2px;
}

.PromoBanner-Icon_mobile {
  margin-top: -1px;
  margin-bottom: 0;
}

.PromoBanner-Text {
  width: 100%;
}

.PromoBanner:hover,
.PromoBanner:focus-visible,
.PromoBanner:active,
.PromoBanner:hover:active,
.PromoBanner:focus-visible:active {
  transition: background var(--animation-speed-zero) var(--animation-easing-default);
}

.PromoBanner:hover,
.PromoBanner:focus-visible {
  background: var(--primary-surface-85);
}

.PromoBanner:active,
.PromoBanner:hover:active,
.PromoBanner:focus-visible:active {
  background: var(--primary-action);
}
