.CashPortfolio-TypeSubtitle {
  padding: 2px 8px;
  border-radius: 12px;
  background-color: var(--cash-default);
}

.CashPortfolioWarning-Button {
  border-color: transparent;
  background: transparent;
  cursor: pointer;
  font-family: inherit;
}
