.Chart {
  position: relative;
  width: 100%;
  transform: translate3d(0, 0, 0); /* move to new draw layer to optimise draw calls */
}

.Chart-Element {
  position: absolute;
  inset: 0;
}

.Chart_size_small {
  height: 6.375em;
}

.Chart_size_medium {
  height: 10.75em;
}

.Chart_size_large {
  height: 189px;
}

.Chart_paddingLeft_normal {
  .Chart-Element {
    padding-left: 3em;
  }
}

.Chart_desktop {
  &.Chart_size_small {
    height: 10.75em;
  }

  &.Chart_paddingLeft_normal {
    .Chart-Element {
      padding-left: 4em;
    }
  }

  &.Chart_size_medium {
    height: 14.5em;
  }

  &.Chart_size_large {
    height: 272px;
  }
}

@media (pointer: coarse) {
  .Chart,
  .Chart * {
    user-select: none;
  }
}
