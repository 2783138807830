.JoinCards > .JoinCards-Card {
  &:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &:not(:first-child, :last-child) {
    border-radius: 0;
  }
}

.JoinCards > .JoinCards-Card + .JoinCards-Card {
  border-top: 0;
}
