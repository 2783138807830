.CreateOrTransferDropdown {
  .Card {
    padding-top: 20px;
    padding-right: 32px;
    padding-left: 20px;
  }

  .Text {
    font-size: 16px;
  }

  .Dropdown-Content {
    top: -20px;
    right: -11px;

    .Text {
      color: var(--content-on-background-default);
    }

    .Dropdown-Icon {
      fill: var(--content-on-background-default) !important; /* to overlap style attribute */
    }
  }
}
