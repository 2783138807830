.Toast {
  position: absolute;
  z-index: 99999;
  top: 3rem;
  right: 0;
  left: 0;
  display: inline-block;
  cursor: pointer;
}

.Toast.Toast_button {
  top: auto;
  bottom: 4rem;
  width: 200px;
  margin: auto;
  text-align: center;
}

.Toast.Toast_button .Toast-Inner {
  height: 42px;
  padding-top: 8px;
  border-radius: 24px;
}

.Toast-Inner {
  box-sizing: border-box;
  padding: 0.75em 1em;
}

.Toast_desktop {
  height: 0;
  inset: 13.5% auto auto 50%;
}

.Toast_desktop .Toast-Inner {
  display: inline-flex;
  max-width: 60vw;
  padding-right: 1.5em;
  padding-left: 1.5em;
  border-radius: 0.325em;
  margin-left: -50%;
}

.Toast_hidden .Toast-Inner {
  display: none !important;
}

.Toast_style_success .Toast-Inner {
  background-color: var(--status-success);
  box-shadow: var(--shadow-level-5);
  color: var(--content-on-color-default);
  transition: background-color var(--animation-speed-default) var(--animation-easing-default);
}

.Toast_style_success:hover .Toast-Inner,
.Toast_style_success:focus-visible .Toast-Inner,
.Toast_style_success:active .Toast-Inner {
  background-color: var(--status-success-action);
  transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
}

.Toast_style_fail .Toast-Inner {
  background-color: var(--status-error);
  box-shadow: var(--shadow-level-5);
  color: var(--content-on-color-default);
  transition: background-color var(--animation-speed-default) var(--animation-easing-default);
}

.Toast_style_fail:hover .Toast-Inner,
.Toast_style_fail:focus-visible .Toast-Inner,
.Toast_style_fail:active .Toast-Inner {
  background-color: var(--status-error-action);
  transition: background-color var(--animation-speed-zero) var(--animation-easing-default);
}

.Toast_enter .Toast-Inner {
  opacity: 0;
  transform: translate3d(0, -14em, 0);
}

.Toast_enter_active .Toast-Inner {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition:
    opacity var(--animation-speed-default) var(--animation-easing-default),
    transform var(--animation-speed-default) var(--animation-easing-default);
}

.Toast_leave {
  pointer-events: none;
}

.Toast_leave .Toast-Inner {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.Toast_leave_active .Toast-Inner {
  opacity: 0;
  transform: translate3d(0, -14em, 0);
  transition:
    opacity var(--animation-speed-slow) var(--animation-easing-default),
    transform var(--animation-speed-slow) var(--animation-easing-default);
}

.Toast_inPlace {
  position: static;
  display: inline-block;
  height: 2.8rem;
  cursor: default;
  pointer-events: none;
}

.Toast_inPlace .Toast-Inner {
  margin-left: 0;
}
