.Stories {
  position: relative;
  height: 120px;

  /* rewrite global `glider` styles */
  .glider {
    margin: -12px;

    .glider-track {
      box-sizing: border-box;
      padding: 12px;
    }
  }

  .glider::-webkit-scrollbar {
    height: 0 !important;
    opacity: 0 !important;
  }

  .glider-slide {
    min-width: unset !important;
  }
}

.Stories-PrevButton,
.Stories-NextButton {
  position: absolute;
  z-index: 2;
  top: calc(50% + 12px / 2 - 32px / 2);
  display: grid;
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  border-radius: 26px;
  background: var(--background-default);
  box-shadow: var(--shadow-level-4);
  cursor: pointer;
  place-content: center;
}

.Stories-PrevButton[aria-disabled='true'],
.Stories-NextButton[aria-disabled='true'] {
  display: none;
}

.Stories-PrevButton {
  left: -18px;
}

.Stories-NextButton {
  right: -18px;
}

.Stories-PrevArrow,
.Stories-NextArrow {
  display: grid;
  place-content: center;
}

.Stories-PrevArrow {
  padding-right: 2px;
}

.Stories-NextArrow {
  padding-left: 2px;
}

.Stories-PrevButton:active .Stories-PrevArrow,
.Stories-NextButton:active .Stories-NextArrow {
  fill: var(--primary-action);
}

.Stories-PrevButton:hover,
.Stories-NextButton:hover {
  box-shadow: var(--shadow-level-5);
}

.Stories-PrevButton:focus-visible,
.Stories-NextButton:focus-visible {
  border: var(--content-on-background-default);
}

.Stories-PrevButton:hover:not(:active) .Stories-PrevArrow,
.Stories-NextButton:hover:not(:active) .Stories-NextArrow {
  /* important is used to override inline styles of the svg components */
  fill: var(--status-error) !important;
}

.Stories-PrevButton:active,
.Stories-NextButton:active {
  box-shadow: var(--shadow-level-1);
}

.Stories_mobile {
  margin-right: -16px;
  margin-left: -16px;

  /* rewrite global `glider` styles */
  .glider {
    margin-right: 0;
    margin-left: 0;
  }
}
