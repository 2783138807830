.Width {
  display: block;
  max-width: 100%;
}

.Width_center {
  margin: 0 auto;
}

.Width_right {
  float: right;
}

.Width_inline {
  display: inline-block;
}

.Width_mobileFullWidth {
  @media (width <= 1023px) {
    html .Root & {
      width: 100% !important;
    }
  }
}
