.EditIncomePortfolio-ModelFooter {
  display: flex;

  > *:not(:first-child) {
    margin-left: 1.5rem;
  }
}

.EditIncomePortfolio-Buttons {
  position: sticky;
  z-index: 999;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5em;

  &:empty {
    display: none;
  }
}

.EditIncomePortfolio-Message {
  display: inline-block;
}

.EditIncomePortfolio {
  position: relative;
  overflow: inherit;

  .EditIncomePortfolio-Buttons {
    padding-bottom: 1em;

    .Button {
      .Button-Inner {
        min-width: 12.3em;
      }
    }
  }

  .EditIncomePortfolio-ModelFooter {
    > *:not(:first-child) {
      margin-left: 3rem;
    }
  }

  .EditIncomePortfolio-SliderLabels {
    .ColumnarLayout-Column {
      padding: 0 0.25rem;
    }
  }

  .EditIncomePortfolio-RiskLabel {
    margin-top: -0.5em;
    margin-bottom: -0.75em;

    .Label-Text {
      margin-bottom: 0;
    }
  }

  .EditIncomePortfolio-ModelLabel {
    margin-bottom: 0;
  }

  .EditIncomePortfolio-CapitalField {
    flex-grow: 0.6;
  }
}
