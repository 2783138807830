.Story-Modal {
  --new-story-width: min(100%, 424px);
  --new-story-height: 756px;
}

.Story-Modal ._new_Modal-Window {
  position: relative;
  overflow: auto;
  width: var(--new-story-width);
  height: var(--new-story-height);
}

.Story-Wrapper {
  position: relative;
  display: grid;
  overflow: auto;
  width: var(--new-story-width);
  height: var(--new-story-height);
  background-color: var(--primary-default);
  grid-template-rows: 1fr auto;
}

.Story-BackgroundImageWrapper {
  /* prevents scrolls when the size of the viewport is less than the size of the background image */
  overflow: hidden;
  grid-column: 1;
  grid-row: 1;
}

.Story-BackgroundImage {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* makes the image more centered while avoiding the text overflow. Works with the current image dimensions. */
  object-position: top 25% left 0%;
}

.Story-ImageWrapper {
  grid-row: -1;
}

.Story-Image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

.Story-ContentWrapper {
  position: var(--new-story-content-wrapper, static);
  z-index: 2;
  height: 100%;
  grid-column: 1;
  grid-row: 1;
}

.Story-ModalCloser {
  --click-target-helper-position: absolute;

  z-index: 20;
  top: 34px;
  right: 24px;
  display: block;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.Story-Button {
  --new-story-button-gap: 20px;
  --click-target-helper-position: absolute;

  top: 50%;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  outline: none;
}

.Story-Button:focus-visible {
  box-shadow:
    0 0 0 1px var(--background-default),
    0 0 0 2px var(--content-on-background-default);
}

.Story-Button_Next {
  right: calc(50% - calc(var(--new-story-width) / 2) - var(--new-story-button-gap));
  transform: translate(100%, -50%);
}

.Story-Button_Previous {
  left: calc(50% - calc(var(--new-story-width) / 2) - var(--new-story-button-gap));
  transform: translate(-100%, -50%);
}

.Story-Button circle {
  transition: fill var(--animation-speed-default) var(--animation-easing-default);
}

.Story-Button:hover:not(:active) circle,
.Story-Button:focus-visible:not(:active) circle {
  /* !important is to override the svg inline fill */
  fill: var(--primary-action) !important;
}

.Story-Button_Action {
  --new-story-button-action-margin: 32px;

  position: absolute;
  z-index: 20;
  bottom: var(--new-story-button-action-margin);

  /* prevents overflow of the white pseudoelement underneath */
  overflow: hidden;
  width: calc(100% - var(--new-story-button-action-margin) * 2);
  inset-inline: 0;
  isolation: isolate;
  margin-inline: auto;
}

.Story-Button_Action::before {
  position: absolute;
  z-index: -1;
  background-color: white;
  content: '';
  inset: 0;
}

/* override default react-insta-stories styles */
.Story-ContentWrapper > div:first-child {
  position: unset !important;
}

.Story-ContentWrapper > div:first-child > div:nth-child(2) {
  overflow: visible !important;
}

.Story-ContentWrapper > div > div:last-of-type > div:first-of-type {
  z-index: 10 !important;
  width: 33.333% !important;
}

.Story-ContentWrapper > div > div:last-of-type > div:last-of-type {
  z-index: 10 !important;
  width: 66.666% !important;
}

.click-target-helper {
  position: var(--click-target-helper-position, relative);
}

.click-target-helper::after {
  --click-target-minimum: 44px;
  --inset-by: min(0px, calc((100% - var(--click-target-minimum)) / 2));

  position: absolute;
  content: '';
  inset: var(--inset-by);
}

@media (width <= 550px) {
  .Story-Button {
    display: none;
  }
}
