.ChartHorizontalTick {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  white-space: nowrap;
}

.ChartHorizontalTick::before {
  display: block;
  width: 0.0625rem;
  height: 0.5em;
  margin: 0 auto 0.2em;
  background: var(--background-border);
  content: '';
}

.ChartHorizontalTick_phone {
  bottom: -1.4em;
}

.ChartHorizontalTick_desktop {
  bottom: -1.6em;
}
