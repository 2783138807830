.DotsMenu {
  position: relative;
  display: flex;
}

.DotsMenu-Link {
  display: flex;
}

.DotsMenu-Box {
  position: absolute;
  top: 2.5rem;
  right: -1.875rem;
  min-width: 10rem;

  &::before {
    position: absolute;
    top: -0.5rem;
    right: 2.125rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--background-default);
    box-shadow: var(--shadow-level-3);
    content: '';
    transform: rotate(45deg);
  }

  &::after {
    position: absolute;
    z-index: 1;
    top: -0.5rem;
    right: 2.125rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--background-default);
    content: '';
    transform: rotate(45deg);
  }
}

.DotsMenu-BoxInner {
  position: relative;
  z-index: 1;
  padding: 1.5rem 2.375rem 1.5rem 3rem;
  border-radius: 0.5rem;
  background-color: var(--background-default);
  box-shadow: var(--shadow-level-3);
}
