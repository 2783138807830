.List {
  display: block;
  padding: initial;
  margin: initial;
  list-style: none;
}

.List-Item {
  padding: initial;
  margin: initial;

  & + & {
    margin-top: 0.75em;
  }
}

.List_spacing_half {
  .List-Item + .List-Item {
    margin-top: 0.375em;
  }
}

.List_spacing_big {
  .List-Item + .List-Item {
    margin-top: 1.375em;
  }
}

.List_type_bullet {
  padding-left: 1.125em;
  list-style: disc;
}

.List_no-margin_top {
  .List-Item {
    margin-top: 0;
  }
}

.List_common {
  margin-top: 1.75em;
  margin-bottom: 1.75em;

  & li {
    margin: 0;
  }
}

.List_margin_standart {
  margin-top: 1em;
  margin-bottom: 1em;
}

.List_padding-left_big {
  padding-left: 4em;
}
