.EditGoal-Chart {
  margin-right: -1px !important;
  margin-bottom: 2.5rem !important;
  margin-left: -1px !important;
}

.EditGoal-Probability {
  margin-right: -1px !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;

  .ProjectionsChart-Tooltip {
    display: block;
    width: auto;
    border: 0;
  }
}

.EditGoal-PresetLabel {
  display: flex;
  white-space: nowrap;
}

.EditGoal-ChartHeadline {
  position: relative;
  z-index: 11;
  padding-bottom: 0.75rem;
}

.EditGoal-TargetButton {
  margin-bottom: 7px;
  font-size: 0.875rem;
}

.EditGoal_phone {
  overflow: hidden;

  .EditGoal-Chart {
    margin-bottom: 0.75rem !important;
  }

  .EditGoal-Probability {
    position: relative;
    z-index: 5;
  }
}

.EditGoal_desktop {
  width: 100%;
  margin-bottom: 2rem !important;
}
