.CardAccent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.CardAccent_primary {
  background-color: var(--primary-default);
}

.CardAccent_secondary {
  background-color: var(--secondary-default);
}

.CardAccent_cashcolor {
  background-color: var(--cash-default);
}

.CardAccent_selfSelected {
  background-color: var(--self-selected-default);
}

.CardAccent_phone {
  width: 4px;
}
