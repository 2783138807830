.Toggle {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
  border: 1px solid var(--background-border);
  border-radius: 16px;
  cursor: pointer;
}

.Toggle_checked {
  border-color: var(--primary-default);
}

.Toggle-Checkbox {
  position: relative;
  z-index: -1;
  opacity: 0;
}

.Toggle-Swipe {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--background-border);
  transition: left var(--animation-speed-fast) linear;
}

.Toggle-Checkbox:checked ~ .Toggle-Swipe {
  left: 20px;
  background: var(--primary-default);
}
