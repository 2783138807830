.ClaimBonusTitle {
  width: calc(100vw - 16px * 2);
}

.ClaimBonusTitle-Closed,
.ClaimBonusTitle-Open {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.ClaimBonusTitle_desktop {
  height: 42px;
}

.ClaimBonusTitle_phone {
  height: 84px;
}

.ClaimBonusTitle-Open {
  transform: translateY(-200%);
  transition: transform var(--animation-speed-slow) var(--animation-easing-default);
  transition-delay: 1.5s;
  will-change: transform;

  &.ClaimBonusTitle-Open_animated {
    transform: translateY(0);
  }
}
