.Footer {
  margin-bottom: 2rem;
  font-size: 0.75rem;

  > * {
    overflow: hidden;
  }
}

.Footer-Section {
  & + & {
    margin-top: -0.0625rem;
  }
}

.Footer-Info {
  border-right: 0;
  border-left: 0;
}

.Footer-Warning {
  padding: 1em 0;
}

.Footer-WarningIcon {
  margin: 0.25em 0 0.25em -0.125em;
}

.Footer-WarningHeadline {
  margin-bottom: 0.5em;
}

.Footer-WarningLink {
  display: inline-block;
  margin-top: 0.5em;
}

.Footer-Points {
  font-size: 0.875rem;
}

.Footer-Flexsheet {
  display: flex;
}

.Footer-FlexsheetItem {
  display: flex;
  align-items: center;
  margin: 0 -0.125em;

  &:first-child {
    padding-right: 2.5em;
  }

  .Icon {
    flex-shrink: 0;
  }

  @media (width <= 1023px) {
    html .Root & {
      margin-bottom: 0.75rem;
    }
  }
}

.Footer-FlexsheetItemText {
  flex-grow: 1;
  padding-left: 0.5rem;
}

.Footer-Flexsheet_right {
  justify-content: flex-end;

  .Footer-FlexsheetItem {
    padding-right: 0;
    padding-left: 2em;
  }
}

.Footer-SendMessageErr {
  position: absolute;
  background-color: var(--background-default);
}

.Footer_noMarginTop {
  margin-top: 0;
}

.Footer-Social {
  min-width: 8rem;
}

.Footer_phone {
  .Footer-Section {
    .ColumnarLayout {
      display: block;
    }

    > .Card {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .Footer-Flexsheet:not(.Footer-Flexsheet_right) {
    flex-wrap: wrap;
  }

  .Footer-Flexsheet_right {
    margin: 1rem -0.5rem 0.5rem;

    .Footer-FlexsheetItem {
      flex: 1 1 50%;
      padding: 0 0.5rem;

      .Width {
        width: 100% !important;
      }
    }
  }

  .Footer-WarningIcon {
    display: none;
  }

  .Footer-LinksSections {
    padding-top: 1em !important;
    padding-bottom: 1em !important;

    .ColumnarLayout {
      flex-wrap: wrap;

      &-Column {
        box-sizing: border-box;
        flex: 1 1 100%;
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }

  .Text_leftOnPhones {
    text-align: left;
  }
}

.Footer-Contacts {
  .ie & > .ColumnarLayout-Column {
    flex-basis: auto;
  }
}

.Footer-Button {
  font-size: 16px;

  @media (width <= 1023px) {
    margin-top: 16px;
  }
}

.Footer-AccordionItemIcon {
  margin-left: 8px;
  vertical-align: middle;
}

.Footer-AccordionItem_closed {
  .Footer-Points {
    display: none;
  }
}
