.IncomeLine,
.IncomeLine-Svg {
  height: inherit; /* fallback */
}

.IncomeLine {
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
}

.IncomeLine_hoverable {
  pointer-events: auto;
}

.IncomeLine-Svg {
  overflow: visible;
  width: 100%;
}

.IncomeLine-Tick {
  position: relative;
  top: -7px;
}

.IncomeLine_horde {
  .IncomeLine-TextNode {
    margin-left: calc(50% - 2.5em);
  }
}

.IncomeLine-Tick:only-child {
  .IncomeLine-TextNode {
    text-align: center;
  }
}

.IncomeLine_phone,
.IncomeLine_exactlyTwoDataPoints {
  .IncomeLine-Tick:not(:only-child) {
    position: absolute;
  }

  .IncomeLine-Tick:not(:only-child):first-child {
    right: auto !important;
    left: 1.75em !important;
    width: 50% !important;

    .IncomeLine-TickHolder {
      &::before {
        margin-left: 0;
      }
    }

    .IncomeLine-TextNode {
      margin-left: 0;
      text-align: left;
    }
  }

  .IncomeLine-Tick:not(:only-child):last-child {
    right: 0 !important;
    left: auto !important;
    width: 50% !important;

    .IncomeLine-TickHolder {
      &::before {
        margin-right: 0;
      }
    }

    .IncomeLine-TextNode {
      margin-right: 0;
      text-align: right;
    }
  }
}

.IncomeLine_phone {
  .IncomeLine-Tick:not(:only-child) {
    bottom: 0;
  }

  .IncomeLine-Tick:not(:only-child):first-child {
    left: 3em !important;
  }
}

.IncomeLine_exactlyTwoDataPoints:not(.IncomeLine_phone) {
  .IncomeLine-Tick:not(:only-child) {
    bottom: 1px;
  }

  .IncomeLine-Tick:not(:only-child):first-child {
    left: 4em !important;
  }
}
