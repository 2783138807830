.ErrorPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ErrorPage-Header {
  position: relative;
  z-index: 1;
  height: 6rem;
  margin-bottom: -6rem;

  .Logo {
    display: block;
    width: 10.75rem;
    margin: 1.5rem 0 1.5rem -0.125rem;
  }
}

.ErrorPage-Content {
  display: flex;
  flex-grow: 1;
}

.ErrorPage-Text {
  margin-top: -1em;

  p:first-child {
    margin-top: 0;
  }
}

.ErrorPage-Bottom {
  margin-top: 4em;
  white-space: nowrap;

  .Icon {
    display: inline-block;
    margin: -1.25em 0.75em -1.25em -0.25em;
    line-height: 1;
    vertical-align: middle;
  }

  .Icon + h4 {
    display: inline-block;
    margin: -0.25em 0 0;
    vertical-align: middle;
  }
}

.ErrorPage-BottomItem {
  display: block;
  line-height: 2.75em !important;
}

.ErrorPage_phone {
  .ErrorPage {
    .Inner {
      padding: 0 1rem;
    }
  }

  .ErrorPage-Header {
    margin-bottom: 0;
  }

  .ErrorPage-Headline {
    font-size: 2rem;
  }

  .ErrorPage-Text {
    margin-top: 0;
  }

  .ErrorPage-Bottom {
    display: block;

    .ErrorPage-Headline {
      font-size: 1em;
    }
  }

  .ErrorPage-BottomInfo {
    margin-top: 1em;
  }
}
