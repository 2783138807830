.PoundsWithPence:not(.PoundsWithPence_sameSize) .PoundsWithPence-Pence {
  font-size: max(0.5em, 12px);
  line-height: 1;
}

.PoundsWithPence-Odometer {
  display: inline-flex;
  height: 1.1875em; /* fallback for firefox https://caniuse.com/?search=lh%20unit */

  @supports (height: 1lh) {
    height: 1lh;
  }
}

.PoundsWithPence {
  white-space: nowrap;

  .odometer.odometer-auto-theme,
  .odometer.odometer-auto-theme .odometer-digit,
  .odometer.odometer-theme-minimal,
  .odometer.odometer-theme-minimal .odometer-digit {
    text-align: center;
    vertical-align: initial;
  }

  .odometer.odometer-auto-theme .odometer-digit .odometer-value,
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner {
    width: 100%;
    text-align: center;
  }
}
