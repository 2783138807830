.ShareModal-Item {
  border-bottom: 1px solid var(--background-border);
  cursor: pointer;
  outline: none;
}

.ShareModal-Item:focus-visible {
  border-radius: 2px;
  outline: 1px solid var(--content-on-background-default);
}

.ShareModal-Item:hover .ShareModal-LinkTitle {
  color: var(--status-error);
}
