.CreateAccount-Checkbox {
  line-height: 16px;
}

.CreateAccount-Checkbox.WithLabel_size_normal {
  margin-bottom: 0;
}

.CreateAccount-Checkbox.WithLabel_size_normal .WithLabel-Field {
  margin-top: 0;
}

.CreateAccount-Checkbox .Label_flex_inline {
  display: flex;
}

.CreateAccount-Checkbox .Label-Text {
  margin-top: 2px;
  margin-bottom: 0;
}

.CreateAccount-RequirementsContainer {
  border: 1px solid var(--background-border);
  border-radius: 6px;
}

.CreateAccount-CheckIcon {
  /* optical alignment */
  transform: translateY(-1px);
}
