.NumberInput {
  all: initial;
  position: relative;
  display: block;
  width: 100%;
  color: var(--content-on-background-default) !important;
  font-family: inherit;
  font-size: inherit;
}

.NumberInput-Input {
  width: 100%;
  padding: 0.5em 1.75em;
}

.NumberInput-Operator {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  width: 1.75em;
  align-items: center;
  justify-content: center;

  .windows & {
    font-weight: 900;
  }
}

.NumberInput-Operator::after {
  position: absolute;
  top: -24px;
  bottom: -24px;
  display: block;
  width: 48px;
  height: 48px;
  margin: auto;
  content: '';
}

.NumberInput-Operator_disabled {
  opacity: 0.45;
  pointer-events: none;
}

.NumberInput-Minus {
  left: 0;
}

.NumberInput-Minus::after {
  right: 0;
}

.NumberInput-Plus {
  right: 0;
}

.NumberInput-Plus::after {
  left: 0;
}

.NumberInput-After {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.NumberInput_align_left {
  .NumberInput-Input {
    padding-right: 1.25em;
  }

  .NumberInput-After {
    right: 1.25em;
  }
}

.NumberInput_align_right {
  .NumberInput-Input {
    padding-left: 1.25em;
    text-align: right;
  }

  .NumberInput-After {
    right: 1.75em;
  }
}
