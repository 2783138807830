.ListOfDirectorsAndShareholders-SubTitle {
  margin-bottom: 2.75rem;
}

.ListOfDirectorsAndShareholders-Item {
  margin-bottom: 1.5rem;
}

.ListOfDirectorsAndShareholders-ItemTopLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ListOfDirectorsAndShareholders-ItemUploadDocuments {
  display: flex;
  align-items: center;
  margin-top: 1.25rem;
}

.ListOfDirectorsAndShareholders-ItemUploadDocumentsIcon {
  margin-right: 0.875rem;
}

.ListOfDirectorsAndShareholders-AddButton {
  margin-bottom: 0.875rem;
  cursor: pointer;

  .SelectableCard-Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.ListOfDirectorsAndShareholders_phone {
  padding-bottom: 0;

  .ListOfDirectorsAndShareholders-SubTitle {
    margin-top: 0;
  }
}
