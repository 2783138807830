.GreyTabs {
  display: flex;
  width: max-content;
  padding: 2px;
  border-radius: 6px;
  background-color: var(--background-muted);
  white-space: nowrap;
}

.GreyTabs_mobile {
  width: 100%;
}

.GreyTabs_mobile .GreyTabs-Tab {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  padding-left: 3px;
}

.GreyTabs::-webkit-scrollbar {
  display: none;
}

.GreyTabs-Tab {
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  transition:
    background var(--animation-speed-default) var(--animation-easing-default),
    box-shadow var(--animation-speed-default) var(--animation-easing-default),
    color var(--animation-speed-default) var(--animation-easing-default),
    border var(--animation-speed-default) var(--animation-easing-default);
  user-select: none;
}

.GreyTabs-Tab:not(.GreyTabs-Tab_active) {
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    background: var(--background-default);
    transition:
      background var(--animation-speed-zero) var(--animation-easing-default),
      box-shadow var(--animation-speed-zero) var(--animation-easing-default),
      color var(--animation-speed-zero) var(--animation-easing-default);
  }
}

.GreyTabs-Tab_active {
  z-index: 1;
  border: none !important;
  background: var(--background-card-opaque);
  box-shadow: var(--shadow-level-2);
  pointer-events: none;
  transition:
    background var(--animation-speed-zero) var(--animation-easing-default),
    box-shadow var(--animation-speed-zero) var(--animation-easing-default),
    color var(--animation-speed-zero) var(--animation-easing-default);
}

.GreyTabs-Tab_active .GreyTabs-Tab-Text {
  transition: color var(--animation-speed-zero) var(--animation-easing-default);
}

.GreyTabs_fullWidth {
  width: 100%;
}

.GreyTabs_fullWidth .GreyTabs-Tab {
  flex: 1;
}

.GreyTabs-Tab_large {
  display: flex;
  justify-content: center;
  padding-block: 14px;
}
