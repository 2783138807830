.PercentageBar {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: var(--primary-surface-10);
  place-content: center flex-end;

  @media screen and (prefers-color-scheme: dark) {
    background-color: var(--background-card);
  }
}

.PercentageBar-Fill {
  height: inherit;
  border-radius: inherit;
}
