.Analysed-Icon {
  svg {
    max-height: 4rem;
  }
}

.Analysed-Text {
  width: 100%;
  white-space: normal;
}
