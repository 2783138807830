.PromoNotification {
  border-radius: 8px;
  background-color: var(--accent-default);
  cursor: pointer;
}

.PromoNotification_content {
  display: flex;
  align-items: center;
}
