._new_Modal {
  position: fixed;
  z-index: 1000;
  inset: 0;
}

._new_Modal-Backdrop {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-overlay);

  /* don't show modal backdrop if old modal is openeded to avoid flickering */
  body:has(.Modal-Backdrop) & {
    opacity: 0;
  }
}

._new_Modal-Window {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: var(--background-default);

  &:focus {
    outline: none;
  }
}

._new_Modal-Window_background_primary {
  background: var(--background-primary);
}

._new_Modal-Close {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  text-align: center;
}

._new_Modal-CloseCustom {
  padding: 20px 28px 0 0;
  font-size: 18px;
  line-height: 165%;
}

._new_Modal-CloseCross {
  width: 48px;
  height: 48px;
  font-size: 32px;
  line-height: 48px;

  svg {
    height: 48px;
  }
}

._new_Modal-Inside {
  position: relative;
  display: flex;
  overflow: auto;
  height: 100%;
  max-height: 100%;
  flex: 0 1 auto;
  flex-direction: column;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

._new_Modal_desktop {
  min-height: auto;

  ._new_Modal-Window {
    position: relative;
    display: inline-flex;
    width: auto;
    height: auto;
    max-height: 100%;
    box-shadow: var(--shadow-level-4);
  }
}

._new_Modal_phone,
._new_Modal_fullScreen {
  ._new_Modal-Window {
    width: 100%;
    height: 100%;
  }
}

._new_Modal_animation_null {
  ._new_Modal-Backdrop,
  ._new_Modal-Window {
    transform: none !important;
  }
}

/* Closed state */
._new_Modal_enter,
._new_Modal_leave_active {
  opacity: 0;
  pointer-events: none;
}

/* Before open state */
._new_Modal_enter {
  ._new_Modal-Backdrop {
    background-color: var(--background-transparent);
  }

  &._new_Modal_animation_bottomToTop {
    ._new_Modal-Window {
      transform: translate3d(0, 100vh, 0);
    }
  }

  &._new_Modal_animation_rightToLeft {
    ._new_Modal-Window {
      transform: translate3d(100vw, 0, 0);
    }
  }

  &._new_Modal_animation_leftToRight {
    ._new_Modal-Window {
      transform: translate3d(-100vw, 0, 0);
    }
  }
}

/* Open state */
._new_Modal_enter_active,
._new_Modal_leave {
  opacity: 1;
  pointer-events: all;
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);

  ._new_Modal-Backdrop {
    background-color: var(--background-overlay);
    transition: background-color var(--animation-speed-slow) var(--animation-easing-default);
  }

  ._new_Modal-Window {
    transform: translate3d(0, 0, 0);
    transition: transform var(--animation-speed-slow) var(--animation-easing-default);
  }

  &._new_Modal_animation_bottomToTop,
  &._new_Modal_animation_rightToLeft,
  &._new_Modal_animation_leftToRight {
    & ._new_Modal-Window {
      transform: translate3d(0, 0, 0);
    }
  }
}

/* After close state */
._new_Modal_leave_active {
  ._new_Modal-Backdrop {
    background-color: var(--background-transparent);
    transition: background-color var(--animation-speed-slow) var(--animation-easing-default);
  }

  &._new_Modal_animation_bottomToTop {
    ._new_Modal-Window {
      transform: translate3d(0, 100vh, 0);
    }
  }

  &._new_Modal_animation_rightToLeft {
    & ._new_Modal-Window {
      transform: translate3d(-100vw, 0, 0);
    }
  }

  &._new_Modal_animation_leftToRight {
    & ._new_Modal-Window {
      transform: translate3d(100vw, 0, 0);
    }
  }
}
