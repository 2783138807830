@define-mixin hidden {
  opacity: 0;
  pointer-events: none;

  &:not(.Modal_reverseAnimation) .Modal-Window,
  &:not(.Modal_reverseAnimation) .Modal-Gateway {
    transform: translate3d(0, 100vh, 0);
  }

  &.Modal_reverseAnimation .Modal-Window,
  &.Modal_reverseAnimation .Modal-Gateway {
    transform: translate3d(0, -100vh, 0);
  }

  .Modal_frameless& {
    .Modal-Window,
    .Modal-Gateway {
      transform: none;
    }
  }
}

@define-mixin shown {
  opacity: 1;
  pointer-events: all;

  /*
    &.Modal_reverseAnimation and &:not(.Modal_reverseAnimation) need for correct rules order in static
  */
  &.Modal_reverseAnimation .Modal-Window,
  &:not(.Modal_reverseAnimation) .Modal-Window,
  &:not(.Modal_reverseAnimation) .Modal-Gateway {
    transform: translate3d(0, 0, 0);
    transition: transform var(--animation-speed-slow) var(--animation-easing-default);
  }

  .Modal_frameless& {
    .Modal-Window,
    .Modal-Gateway {
      transform: none;
    }
  }
}

.Modal {
  position: fixed;
  z-index: 1000;
  min-height: 100vh;
  inset: 0;
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);
}

.Modal_beforeHeader {
  z-index: 998;
}

.Modal-Backdrop {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-overlay);
}

.Modal-Window {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--background-default);
  box-shadow: var(--shadow-level-4);
  overflow-y: auto;
  transform: translate3d(0, 0, 0);

  &:focus {
    outline: none;
  }

  .Modal_reverseAnimation & {
    transition: transform var(--animation-speed-slow) var(--animation-easing-default);
  }
}

.Modal-Gateway {
  width: 100%;
}

.Modal-Close {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 1.5em;
  height: 1.5em;
  font-size: 2rem;
  line-height: 1.5em;
  text-align: center;

  svg {
    height: 1.5em;
  }
}

.Modal-Inner {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
}

.Modal_enter {
  @mixin hidden;
}

.Modal_enter_active {
  @mixin shown;
}

.Modal_leave {
  @mixin shown;
}

.Modal_leave_active {
  @mixin hidden;
}

.Modal_hidden {
  display: none !important;
}

.Modal_phone {
  .Modal-Inner {
    width: 100%;
    max-width: calc(100% - 2rem);
    box-sizing: content-box;
    padding-top: 3.5rem;
  }

  .Modal-Close {
    padding-top: 0.625rem;
  }

  &.Modal_old-fashioned {
    .Modal-Inner {
      padding-top: 2.5rem;
    }

    .Modal-Close {
      padding-top: inherit;
    }
  }
}

.Modal_desktop {
  min-height: auto;

  .Modal-Window {
    display: inline-block;
    width: auto;
    height: auto;
    max-height: 100%;
  }

  .Modal-Inner {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.Modal_frameless {
  .Modal-Window {
    overflow: visible;
    background: none;
    box-shadow: none;
  }

  .Modal-Inner {
    padding: 0;
  }
}

.Modal_transparent {
  .Modal-Window {
    overflow: visible;
    max-width: calc(100% - 5em);
    background: none;
  }

  .Modal-Close {
    top: -1.25em;
    right: -1.25em;
  }

  .Modal-Inner {
    padding: 0;
  }
}
