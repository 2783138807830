.Headline {
  overflow: hidden;
  margin: 0.75em 0;
  font-weight: 500;
  line-height: 1.25;
  text-overflow: ellipsis;

  .windows & {
    line-height: 1.325;
  }
}

.Headline_inline {
  display: inline-block;
}

.Headline_level1 {
  font-size: 2em;
}

.Headline_level2 {
  font-size: 1.5em;
}

.Headline_level3 {
  font-size: 1.25em;
}

.Headline_level4 {
  font-size: 1em;
}

.Headline_level5 {
  font-size: 0.875em;
}

.Headline_level6 {
  font-size: 0.75em;
}

.Headline_text_normal {
  font-weight: inherit;
}

.Headline_text_light {
  font-weight: 400;

  .macOsX.firefox & {
    font-weight: 300;
  }

  .windows & {
    font-weight: 300;
  }
}

.Headline_text_center {
  text-align: center;
}

.Headline_size_colossal {
  font-size: 3.375em;
}

.Headline_size_gargantuan {
  font-size: 3em;
}

.Headline_size_fluid {
  font-size: 8vw;
}

.Headline_line-height_bigger {
  line-height: 1.5;

  .windows & {
    line-height: 1.625;
  }
}

.Headline_margin_smaller {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.Headline_overflow_visible {
  overflow: visible;
}

.Headline_no-margin_all {
  margin-top: 0;
  margin-bottom: 0;
}

.Headline_no-margin_top {
  margin-top: 0;
}

.Headline_no-margin_bottom {
  margin-bottom: 0;
}

.Headline_inverse-margin_top {
  margin-top: -0.125em;
}
