.SecurityDescription_truncated {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

.SecurityDescription-Button {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
  font: inherit;
  gap: 4px;
}

.SecurityDescription-Paragraph {
  padding-top: 8px;
}

.SecurityDescription-Title + .SecurityDescription-Paragraph {
  padding-top: 24px;
}

.SecurityDescription-SectionTitle + .SecurityDescription-Paragraph {
  padding-top: 12px;
}

.SecurityDescription-OrderedList {
  margin: 0;
}

.SecurityDescription-ListItem::marker {
  color: var(--content-on-background-additional);
  font-family: inherit;
  font-size: 14px;
}

.SecurityDescription-ListItem > .SecurityDescription-Paragraph {
  padding-top: 0;
}

.SecurityDescription-Image {
  display: block;
  max-width: 100%;
}

.SecurityDescription-Link {
  display: inline-flex;
  align-items: center;
}
