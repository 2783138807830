.PriceInformation-DescriptionToggle {
  display: inline-flex;
  vertical-align: middle;
}

.PriceInformation-DescriptionToggle_center {
  margin-bottom: 3px; /* on desktop has extra offset, it fixed that */
}

.PriceInformation-Description {
  margin-left: 0.375em; /* 1 space */

  .Tooltip-Subject {
    vertical-align: middle;
  }
}
