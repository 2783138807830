.Tooltip {
  position: relative;
}

.Tooltip-Subject {
  display: inline-flex;
  cursor: pointer;
}

.Tooltip-Pin {
  &::before,
  &::after {
    position: absolute;
    top: 1.75rem;
    left: 0.125rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--background-default);
    content: '';
    transform: rotate(45deg);
  }

  &::before {
    box-shadow: var(--shadow-level-3);
  }

  &::after {
    z-index: 1001;
  }
}

.Tooltip-Tip {
  position: absolute;
  z-index: 1000;
  top: 2rem;
  left: -1rem;
  width: 25rem;
  color: var(--content-on-background-default);
  font-size: 1rem;
  line-height: 1.4;
  white-space: normal;
}

.Tooltip-TipInner {
  position: relative;
  z-index: 1;
  padding: 2.5rem;
  background-color: var(--background-default);
  box-shadow: var(--shadow-level-3);
  text-align: left;
}

.Tooltip-Close {
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    .Icon {
      fill: var(--content-on-background-additional) !important;
    }
  }
}

.Tooltip_phone {
  .Tooltip-Tip {
    left: -3rem;
  }

  .Tooltip-TipInner {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.Tooltip-Tip_align_center {
  left: -190px;
}
