.PresetBannerContainer {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  background: var(--self-selected-default);
}

.PresetBannerImage {
  height: 100%;
  float: right;
}
