@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@define-mixin hidden {
  opacity: 0;
}

@define-mixin shown {
  opacity: 1;
}

.Preloader {
  position: fixed;
  border-radius: inherit;
  inset: 0;
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);
}

.Preloader-Holder {
  border-radius: inherit;
}

.Preloader-Icon {
  position: absolute;
  z-index: 1;
  margin: auto;
  animation: spin 2s infinite linear;
  inset: -100%;

  svg {
    display: block;
  }
}

.Preloader_background_transparent {
  background-color: var(--background-transparent);
}

.Preloader_background_default {
  background-color: var(--background-overlay);
}

.Preloader_background_background {
  background-color: var(--background-default-85);
}

.Preloader_opaque {
  background-color: var(--background-overlay-opaque);
}

.Preloader_zIndex {
  z-index: 1001;
}

.Preloader_absolute {
  position: absolute;
}

.Preloader_enter {
  @mixin hidden;
}

.Preloader_enter_active {
  @mixin shown;
}

.Preloader_leave {
  @mixin shown;
}

.Preloader_leave_active {
  @mixin hidden;
}
