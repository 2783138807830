.NumeralAxis {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-left: -3em;
  pointer-events: none;
}

.NumeralAxis-Tick {
  display: flex;
  height: 0.0625rem;
}

.NumeralAxis-TextHolder {
  position: relative;
  z-index: 100;
  top: -0.625em;
  min-width: fit-content; /* workaround of a strange... */
  max-width: fit-content; /* ... chrome layout bug */
  height: 1.2em;
  flex: 1 0 auto;
  padding: 0 0.3em;
  border-radius: 0.125rem;
  margin-left: -0.3em;
  background: transparent;
  line-height: 1;
}

.NumeralAxis-Text {
  padding: 0.3em 0;
}

.NumeralAxis-Tick::after {
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    var(--background-border),
    var(--background-border) 60%,
    transparent 60%,
    transparent 100%
  );
  background-position: top right -0.175rem;
  background-size: 0.5rem 0.0625rem;
  content: '';
}

.NumeralAxis:not(.NumeralAxis_right) .NumeralAxis-Tick:first-child::after {
  background: var(--background-border);
}

.NumeralAxis_fretless {
  .NumeralAxis-Tick::after {
    background: transparent;
  }
}

.NumeralAxis_smaller {
  .NumeralAxis-Tick::after {
    background: transparent;
  }
}

.NumeralAxis_desktop {
  margin-left: -4em;

  .NumeralAxis-TextHolder {
    flex-basis: 2.4em;
    padding: 0 0.6em;
    margin-left: -0.6em;
  }
}

.NumeralAxis_right {
  &,
  &.NumeralAxis_desktop {
    align-items: flex-end;
    margin-left: 0;

    .NumeralAxis-Tick {
      position: relative;
      width: 0;
      flex-direction: row-reverse;
    }

    .NumeralAxis-TextHolder {
      padding-right: 0;
      padding-left: 0.25em;
      margin-right: -1px;
      margin-left: 0;
    }
  }

  & .NumeralAxis-Tick::after {
    position: absolute;
    right: 0;
  }

  .NumeralAxis-Tick:first-child .NumeralAxis-TextHolder {
    top: 0;
  }

  .NumeralAxis-Tick:last-child .NumeralAxis-TextHolder {
    top: -20px;
  }

  &.NumeralAxis_desktop {
    .NumeralAxis-Tick:last-child .NumeralAxis-TextHolder {
      top: -23px;
    }
  }
}
