.IncomeHistoryChart-Chart {
  padding-bottom: 1.6em;
}

.IncomeHistoryChart-Tooltip {
  margin-top: 0.125em;

  .Tooltip-Pin {
    &::before,
    &::after {
      left: 0.0625em;
    }
  }

  .Tooltip-Tip {
    right: -1em;
    left: unset;

    .ie & {
      left: auto;
    }
  }
}

.IncomeHistoryChart-Tooltip_phone {
  margin-top: 0.075em;
  margin-left: -1em;
}

.IncomeHistoryChart-Empty {
  /* Hack to rewrite Chart’s css specificity */

  &&& {
    padding-left: 0;
  }
}

.IncomeHistoryChart-Legend {
  align-items: center;
}
