.Text_biggest {
  font-size: 1.325em;
}

.Text_big {
  font-size: 1.1675em;
}

.Text_bigger {
  font-size: 1.125em;
}

.Text_small {
  font-size: 0.875em;
}

.Text_smaller {
  font-size: 0.75em;
}

.Text_extraSmall {
  font-size: 0.6em;
}

.Text_superSmall {
  font-size: 0.5em;
}

.Text_zero {
  font-size: 0;
}

.Text_left {
  text-align: left;
}

.Text_center {
  text-align: center;
}

.Text_right {
  text-align: right;
}

.Text_heavy {
  font-weight: 700;
}

.Text_semibold {
  font-weight: 600;

  .windows & {
    font-weight: 500;
  }
}

.Text_bold {
  font-weight: 500;

  .windows & {
    font-weight: 600;
  }
}

.Text_light {
  font-weight: 400;

  .macOsX.firefox & {
    font-weight: 300;
  }
}

.Text_block {
  display: block;
}

.Text_inlineBlock {
  display: inline-block;
}

.Text_padding-right_2 {
  padding-right: 2rem;
}

.Text_noWrap {
  white-space: nowrap;
}

.Text_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.Text_grow {
  flex-grow: 1;
}

.Text_muted {
  opacity: 0.5;
}

.Text_muted_little {
  opacity: 0.75;
}

.Text_muted_very {
  opacity: 0.375;
}

.Text_muted_extreme {
  opacity: 0.25;
}

.Text_lineheight_smaller {
  line-height: 1.375;
}

.Text_lineheight_one-line {
  line-height: 1;
}

.Text_color_red {
  color: var(--status-error);
}

.Text_color_green {
  color: var(--status-success);
}

.Text_color_orange {
  color: var(--status-warning);
}

.Text_color_black {
  color: var(--content-on-background-default);
}

.Text_color_silver {
  color: var(--content-on-color-muted);
}

.Text_color_white {
  color: var(--content-on-color-default);
}

.Text_color_blue {
  color: var(--content-on-background-primary);
}

.Text_color_stocks {
  color: var(--stocks);
}

.Text_color_bonds {
  color: var(--bonds);
}

.Text_color_alternatives {
  color: var(--alternatives);
}

.Text_color_lightgray {
  color: var(--content-on-background-additional);
}

.Text_color_darkgray {
  color: var(--content-on-background-additional);
}

.Text_color_gray {
  color: var(--content-on-background-minor);
}

.Text_numeric_tabular {
  font-variant-numeric: tabular-nums;
}
