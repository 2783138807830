.EditableSecurityList_desktop {
  .EditableSecurityList-Security_firstNew {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .EditableSecurityList-Security_lastNew {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
