.ClickablePoint {
  cursor: pointer;
}

.ClickablePoint-Circle {
  fill: var(--content-on-background-default);
  transition: stroke var(--animation-speed-default) var(--animation-easing-default);
}

.ClickablePoint:hover .ClickablePoint-Circle,
.ClickablePoint:focus-visible .ClickablePoint-Circle {
  fill: var(--primary-link-hover);
  transition: stroke var(--animation-speed-zero) var(--animation-easing-default);
}

[data-negative='true'] {
  .ClickablePoint-Circle {
    fill: var(--content-on-background-additional);
  }

  .ClickablePoint:hover .ClickablePoint-Circle,
  .ClickablePoint:focus-visible .ClickablePoint-Circle {
    fill: var(--primary-link-hover);
  }
}

@media (pointer: coarse) {
  [data-tooltip-shown='true'] .ClickablePoint {
    pointer-events: none;
  }
}
