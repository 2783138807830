.BonusBadge {
  position: absolute;
  left: 26px;
  overflow: hidden;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  background: var(--background-card-opaque);
  box-shadow: var(--shadow-level-3);
  cursor: pointer;
}

.BonusBadge_desktop {
  left: 28px;
  width: 116px;
  height: 116px;
}

.BonusBadge_Content {
  height: 100%;
}
