.NavigationBar {
  position: relative;
  width: 100%;
  margin: 0;
}

.NavigationBar-AsideButton {
  position: absolute;
  top: 50%;
  font-weight: normal;
  transform: translateY(-50%);
}

.NavigationBar-LeftPartIcon {
  display: inline-block;
  width: 34px;
  height: 34px;
  vertical-align: middle;
}

.NavigationBar-Title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.NavigationBar-TitleMultipleChildHolder {
  display: flex;
  flex-direction: column;
}

.NavigationBar_desktop {
  .NavigationBar-LeftPart,
  .NavigationBar-RightPart {
    top: 14px;
  }

  .NavigationBar-LeftPart {
    left: 0;
  }

  .NavigationBar-LeftPartIcon {
    margin: -16px 16px -12px 0;
  }

  .NavigationBar-RightPart {
    right: 0;
  }

  .NavigationBar-Title {
    line-height: 38px;
  }
}

.NavigationBar_desktop.NavigationBar_plain {
  .NavigationBar-LeftPart,
  .NavigationBar-RightPart {
    top: 50%;
  }

  .NavigationBar-LeftPartIcon {
    margin: -12px 16px -12px 0;
  }
}

.NavigationBar_phone {
  z-index: 999;
  min-height: 48px;
  line-height: 48px;
  white-space: nowrap;

  .NavigationBar-LeftPart {
    left: 16px;
  }

  .NavigationBar-Title {
    height: 48px;
  }

  .NavigationBar-TitleContent,
  .NavigationBar-TitleMultipleChildHolder {
    flex-direction: column;
    align-items: center;
    place-content: center center;

    &,
    & > * {
      display: flex;
      overflow: hidden;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }

  .NavigationBar-TitleMultipleChildHolder {
    display: flex;
  }

  .NavigationBar-RightPart {
    right: 16px;
  }
}

.NavigationBar_phone.NavigationBar_withShadow {
  box-shadow: var(--shadow-level-2);
}
