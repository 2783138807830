.StackedBarGroup {
  overflow: hidden;
  width: 100%;

  @media (width <= 350px) {
    html .Root & {
      overflow-x: visible;
    }
  }
}

.StackedBarGroup-Inner {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid transparent;
  margin-top: 4.5rem;
  margin-bottom: -0.0625rem;
  background-position: 50% -100%;
  background-repeat: no-repeat;
  background-size: 28em 28em;
}

.StackedBarGroup-Stack {
  flex: 0 1 9em;
}

.StackedBarGroup-EdgeToEdge {
  margin-right: -1rem;
  margin-left: -1rem;
}
