.InvestmentStatusModal-DefaultStatusIcon {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid var(--primary-default);
  border-radius: 50%;
  margin-right: 2px;
  margin-left: 2px;
}

.InvestmentStatusModal-Step {
  position: relative;
  overflow: hidden;
  margin-top: -2px;
  margin-bottom: -2px;
}

.InvestmentStatusModal-StepIcon {
  margin-left: 12px;
}

.InvestmentStatusModal-Pathway {
  position: absolute;
  top: 20px;
  left: 23px;
  width: 2px;
  height: 9999px;
  background-color: var(--primary-default);
}

.InvestmentStatusModal-Pathway_active {
  top: auto;
}

.InvestmentStatusModal-Pathway_completed {
  background-color: var(--secondary-default);
}
