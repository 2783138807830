.recently-viewed-container {
  position: relative;
  width: 576px;
}

.recently-viewed-container-mobile {
  width: auto;
  margin-right: -16px;
  margin-left: -16px;
}

.recently-viewed-container-mobile .glider {
  padding: 10px 16px 48px !important;
}

.recently-viewed-container .glider {
  padding: 10px 0 56px;
}

.glider::-webkit-scrollbar {
  height: 0 !important;
  opacity: 0 !important;
}

.glider-slide {
  min-width: unset !important;
}

.recently-viewed-prev-button,
.recently-viewed-next-button {
  position: absolute;
  z-index: 2;
  top: 15px;
  width: 32px;
  height: 32px;
  border-radius: 26px;
  background: var(--background-primary);
  box-shadow: var(--shadow-level-4);
  cursor: pointer;

  &.disabled {
    display: none;
  }
}

.recently-viewed-prev-button {
  left: -16px;
}

.recently-viewed-next-button {
  right: -16px;
}

.recently-viewed-prev-arrow {
  padding-top: 4px;
  padding-left: 12px;
}

.recently-viewed-next-arrow {
  padding-top: 4px;
  padding-left: 13px;
}

.recently-viewed-prev-button:active,
.recently-viewed-next-button:active {
  box-shadow: var(--shadow-level-1) !important;
}

.recently-viewed-prev-button:active span,
.recently-viewed-next-button:active span {
  fill: var(--primary-action) !important;
}

.recently-viewed-prev-button:hover,
.recently-viewed-next-button:hover {
  box-shadow: var(--shadow-level-5);
}

.recently-viewed-prev-button:focus,
.recently-viewed-next-button:focus {
  border: var(--content-on-background-default);
}

.recently-viewed-prev-button:hover:not(:active) span,
.recently-viewed-next-button:hover:not(:active) span {
  fill: var(--status-error) !important;
}

.recently-viewed-prev-button:active {
  outline: none;
}
