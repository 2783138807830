.Legend_flex {
  display: flex;
  justify-content: space-between;
}

.Legend_top {
  align-items: flex-end;
  margin-bottom: 1.5em;
}

.Legend_bottom {
  align-items: flex-start;
  margin-top: 2em;
}

.Legend_desktop {
  &.Legend_top {
    margin-bottom: 2em;
  }

  &.Legend_bottom {
    margin-top: 2em;
  }
}
