@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Icon_animation_spin {
  svg {
    display: block;
    animation: spin 2s infinite linear;
  }
}

.Icon_inline {
  display: inline-block;

  svg {
    display: inline-block;
  }
}

.Icon_rounded {
  border-radius: 100%;
}

.Icon_transitioned {
  transition: transform var(--animation-speed-fast) var(--animation-easing-default);
}

.Icon_upside-down {
  transform: rotate(180deg);
}

.Icon_question {
  display: inline-block;
  width: 1.375em;
  height: 1.375em;
  border: 1px solid var(--content-on-background-primary);
  border-radius: 50%;
  margin-left: 0.5em;
  font-size: 0.75rem;
  line-height: 1.3;
  text-align: center;
  vertical-align: middle;

  .Link:hover & {
    border-color: var(--primary-link-hover);
  }
}
