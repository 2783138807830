.HorizontalChartGroup {
  display: block;
  width: 100%;
  font-size: 0.875em;

  &:not(&_columns) {
    .HorizontalChartGroup-Chart {
      &:first-child {
        .HorizontalChart-Label {
          margin-top: 0;
        }
      }

      &:not(:first-child) {
        .HorizontalChart-Headline {
          margin-top: 1.5em;

          @media (width <= 1023px) {
            html .Root & {
              margin-top: 0.75em;
            }
          }
        }
      }
    }
  }
}

.HorizontalChartGroup_center {
  margin: auto;
}

.HorizontalChartGroup_fluid {
  width: 100%;
}

.HorizontalChartGroup_columns {
  display: flex;
  width: 100%;

  .HorizontalChartGroup-Chart {
    flex: 1;
  }
}

.HorizontalChartGroup_w-summary {
  .HorizontalChart-Item {
    padding-right: 0;
  }

  .HorizontalChartGroup_edgeToEdge& {
    box-sizing: border-box;
  }
}

.HorizontalChartGroup_continue-line_left,
.HorizontalChartGroup_continue-line_right {
  .HorizontalChart-Item {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      display: none;
      width: 100%;
      height: 100%;
      background-color: inherit;
      content: '';
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}

.HorizontalChartGroup_continue-line_left {
  .HorizontalChart-Item {
    &::before {
      display: block;
    }
  }
}

.HorizontalChartGroup_continue-line_right {
  .HorizontalChart-Item {
    &::after {
      display: block;
    }
  }
}
