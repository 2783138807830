.PortfolioTabsContainer {
  display: flex;
  justify-content: center;
}

.PortfolioTabs {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 4px;
  border-radius: 6px;
  background-color: var(--background-muted);
}

.PortfolioTabs.PortfolioTabs_phone {
  width: 100%;
  padding: 2px;
  border-radius: 4px;
}

.PortfolioTab {
  border: initial;
  border-radius: 6px;
  background-color: initial;
  color: inherit;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid var(--content-on-background-default);
    outline-offset: 1px;
  }
}

.PortfolioTabs_phone .PortfolioTab {
  flex-grow: 1;
}

.PortfolioTab_active {
  background-color: var(--background-card-opaque);
  box-shadow: var(--shadow-level-2);
}

.PortfolioTabs_phone .PortfolioTab_active {
  border-radius: 4px;
  box-shadow: none;
}

.PortfolioTab_content {
  min-width: 158px;
}

.PortfolioTabs.PortfolioTabs_phone .PortfolioTab_content {
  min-width: 0;
}
