.SearchField {
  position: relative;
  width: 100%;
}

.SearchField-Input {
  width: 100%;
  height: 36px;
  padding: 8px 32px 8px 40px;
}

.SearchField-Icon {
  position: absolute;
  top: 10px;
  left: 12px;
}

.SearchField-Clear {
  all: initial;
  position: absolute;
  right: 8px;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;

  &::after {
    position: absolute;
    display: block;
    width: 36px;
    height: 48px;
    margin: auto;
    content: '';
    inset: -14px -8px;
  }
}

.SearchField-Input:not(:placeholder-shown) ~ .SearchField-Clear {
  pointer-events: auto;
}

.SearchField-Cancel {
  display: none;
}

.SearchField_phone {
  .SearchField-Clear {
    top: 8px;
    transition:
      right var(--animation-speed-default) var(--animation-easing-default),
      opacity var(--animation-speed-default) var(--animation-easing-default);
    will-change: right, opacity;
  }

  .SearchField-Cancel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 72px;
    height: 24px;
    margin: auto;
    opacity: 0;
    pointer-events: none;
    text-align: right;
    transform: translate3d(72px, 0, 1px);
    transition:
      opacity var(--animation-speed-default) var(--animation-easing-default),
      transform var(--animation-speed-default) var(--animation-easing-default);
    white-space: nowrap;
    will-change: opacity, transform;

    &::after {
      position: absolute;
      top: -12px;
      bottom: -12px;
      display: block;
      width: 100%;
      height: 48px;
      margin: auto;
      content: '';
    }
  }

  .SearchField-Input {
    width: 100%;
    transition: width var(--animation-speed-default) var(--animation-easing-default);
    will-change: width;
  }

  .SearchField-Input:focus-visible,
  .SearchField-Input:not(:placeholder-shown) {
    width: calc(100% - 72px);

    & ~ .SearchField-Clear {
      right: calc(72px + 8px);
    }

    & ~ .SearchField-Cancel {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0, 0, 1px);
    }
  }

  .SearchField-Input:not(:placeholder-shown) {
    & ~ .SearchField-Clear {
      opacity: 1;
    }
  }
}

.SearchField_absolute {
  position: absolute;
  top: -1px;
}

.SearchField_desktop {
  .SearchField-Clear {
    top: 6px;
    transition: opacity var(--animation-speed-default) var(--animation-easing-default);
    will-change: opacity;
  }

  .SearchField-Input:not(:placeholder-shown) ~ .SearchField-Clear {
    opacity: 0.45;

    &:hover,
    &:focus-visible {
      opacity: 1;
      transition: opacity var(--animation-speed-zero) var(--animation-easing-default);
    }
  }
}
