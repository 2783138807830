.SecurityLogo {
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 6px;
  place-content: center center;
}

.SecurityLogo-Image {
  display: block;
  width: 100%;
  height: auto;
}

.SecurityLogo_size_16 {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  font-size: 16px;
}

.SecurityLogo_size_24 {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.SecurityLogo_size_32 {
  width: 32px;
  height: 32px;
  font-size: 32px;
}

.SecurityLogo_size_36 {
  width: 36px;
  height: 36px;
  font-size: 36px;
}

.SecurityLogo_size_48 {
  width: 48px;
  height: 48px;
  font-size: 48px;
}

.SecurityLogo_size_64 {
  width: 64px;
  height: 64px;
  font-size: 64px;
}

.SecurityLogo_size_72 {
  width: 72px;
  height: 72px;
  font-size: 72px;
}

.SecurityLogo_loading {
  background-color: var(--background-border-opaque);
  box-shadow: none;
}

.SecurityLogo_showStub.SecurityLogo_empty {
  background: var(--background-default);

  /* em used here for a reason, box-shadow has to adapt by size */
  box-shadow: 0 0 0 0.08375em inset var(--primary-surface-10);
}

.SecurityLogo_grayLogo.SecurityLogo_empty {
  background-color: var(--content-on-background-muted);
  box-shadow: none;
}
