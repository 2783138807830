.Projections {
  position: relative;
  margin-top: -3.375rem;
}

.Projections-Preloader {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: -32px;
  background-color: var(--background-default-85);
}

.Projections_phone {
  .Projections-Preloader {
    top: -24px;
    bottom: -24px;
  }
}
