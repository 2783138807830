@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* !important is important, skeleton styles must be on top */
.Skeleton {
  position: relative !important;
  border-color: var(--background-transparent) !important;
  background-color: var(--background-border-opaque) !important;
  box-shadow: none !important; /* don't draw any shadow */
  color: transparent !important;
  contain: paint !important;
  pointer-events: none !important;
  user-select: none !important;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    animation: shimmer 2s infinite;
    background-image: linear-gradient(
      90deg,
      rgb(255 255 255 / 0%) 0,
      rgb(255 255 255 / 25%) 25%,
      rgb(255 255 255 / 40%) 50%,
      rgb(255 255 255 / 0%)
    );
    content: '';
    transform: translateX(-100%);
    visibility: visible;
  }

  > * {
    opacity: 0 !important; /* don't draw any child */
  }
}

.Skeleton_inline {
  display: inline-flex !important;
  color: transparent !important;
}

.Skeleton_darkTheme::after {
  background-image: linear-gradient(
    90deg,
    rgb(255 255 255 / 0%) 0,
    rgb(255 255 255 / 2.5%) 25%,
    rgb(255 255 255 / 15%) 50%,
    rgb(255 255 255 / 0%)
  );
}

.Skeleton_borderTopRightRadius_6 {
  border-top-right-radius: 6px;
}

.Skeleton_borderBottomRightRadius_6 {
  border-bottom-right-radius: 6px;
}

.Skeleton_borderTopLeftRadius_6 {
  border-top-left-radius: 6px;
}

.Skeleton_borderBottomLeftRadius_6 {
  border-bottom-left-radius: 6px;
}
