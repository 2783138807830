.FieldLine {
  display: grid;
}

.FieldLine-Label {
  line-height: 1.3;
}

.FieldLine_horizontal {
  align-items: baseline;
  grid-template-columns: 152px auto;
}

.FieldLine_horizontal > .FieldLine-Value {
  margin-left: 40px;
}

.FieldLine_vertical > .FieldLine-Value {
  margin-top: 10px;
}

.FieldLine_phone {
  &.FieldLine_horizontal {
    display: block;

    > .FieldLine-Value {
      margin-left: 0;
    }
  }
}
