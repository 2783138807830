.Card {
  display: block;
  box-sizing: border-box;
  padding: calc(1em - 0.0625rem);
  border: 0.0625rem solid var(--background-border);
  border-radius: 0.325rem;
  margin: 0 auto;
  background-color: var(--background-minor);
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
}

.Card::after {
  display: block;
  height: 0;
  clear: both;
  content: '';
  visibility: hidden;
}

/* TODO: techdebt, refactor this .Card .Card */

.Card .Card {
  margin: -0.0625rem auto;
}

.Card_fluid {
  width: 100%;
}

.Card_flex {
  display: flex;
}

.Card_grow {
  width: 100%;
  flex-grow: 1;
}

.Card_grow_off {
  flex-grow: 0;
}

.Card_direction_column {
  flex-direction: column;
}

.Card_text_center {
  text-align: center;
}

.Card_theme_transparent {
  border-color: transparent;
  border-radius: 0;
  background: none;
}

.Card_theme_vertical-border {
  border-color: inherit;
  border-radius: 0;
  border-right: 0;
  border-left: 0;
  background: none;
}

.Card_theme_white {
  border-color: var(--background-border);
  background-color: var(--background-default);
}

.Card_theme_gray {
  border-color: transparent;
  background-color: var(--content-on-background-additional);
  color: var(--content-on-color-default);
}

.Card_theme_straight-corners {
  border-radius: 0;
}

.Card_theme_border-bottom {
  border-bottom-color: var(--background-border);
}

.Card_theme_border-red {
  border-color: var(--status-error);
}

.Card_theme_edge-to-edge {
  border-radius: 0;
  border-right: var(--background-border);
  border-left: var(--background-border);
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}

.Card_phone.Card_theme_edge-to-edge {
  margin-right: -1rem;
  margin-left: -1rem;
}

.Card_theme_shadowed {
  border-width: 0;
  box-shadow: var(--shadow-level-3);
}

.Card_theme_no-sides {
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-right: 0;
  border-left: 0;
}

.Card_theme_no-border {
  border: 0;
}

.Card_theme_light {
  border-radius: 0.375rem;
}

.Card_desktop {
  padding: calc(1.5em - 0.0625rem) calc(2em - 0.0625rem);
}

.Card_padding_invert.Card_desktop {
  padding: calc(2em - 0.0625rem) calc(1.5em - 0.0625rem);
}

.Card_padding_nano {
  padding: calc(0.325em - 0.0625rem);
}

.Card_desktop.Card_padding_nano {
  padding: calc(0.325em - 0.0625rem) calc(0.5em - 0.0625rem);
}

.Card_padding_invert.Card_desktop.Card_padding_nano {
  padding: calc(0.5em - 0.0625rem) calc(0.325em - 0.0625rem);
}

.Card_padding_smaller {
  padding: calc(0.5em - 0.0625rem);
}

.Card_desktop.Card_padding_smaller {
  padding: calc(0.5em - 0.0625rem) calc(0.75em - 0.0625rem);
}

.Card_padding_invert.Card_desktop.Card_padding_smaller {
  padding: calc(0.75em - 0.0625rem) calc(0.5em - 0.0625rem);
}

.Card_padding_small {
  padding: calc(0.75em - 0.0625rem);
}

.Card_desktop.Card_padding_small {
  padding: calc(0.75em - 0.0625rem) calc(1em - 0.0625rem);
}

.Card_padding_invert.Card_desktop.Card_padding_small {
  padding: calc(1em - 0.0625rem) calc(0.75em - 0.0625rem);
}

.Card_padding_big {
  padding: calc(1.5em - 0.0625rem);
}

.Card_desktop.Card_padding_big {
  padding: calc(2em - 0.0625rem) calc(2.5em - 0.0625rem);
}

.Card_padding_invert.Card_desktop.Card_padding_big {
  padding: calc(2.5em - 0.0625rem) calc(2em - 0.0625rem);
}

.Card_padding_biggest {
  padding: calc(1.5em - 0.0625rem);
}

.Card_desktop.Card_padding_biggest {
  padding: calc(3em - 0.0625rem) calc(3.5em - 0.0625rem);
}

.Card_padding_invert.Card_desktop.Card_padding_biggest {
  padding: calc(3.5em - 0.0625rem) calc(3em - 0.0625rem);
}

.Card_padding_big-vertical {
  padding: 2rem 1rem;
}

.Card_padding_funds-amount {
  padding: 24px 64px 32px;
}

.Card_no-padding_all,
.Card_desktop.Card_no-padding_all {
  padding: 0;
}

.Card_no-padding_all.Card_theme_transparent {
  border: 0;
}

.Card_no-padding_top,
.Card_desktop.Card_no-padding_top {
  padding-top: 0;
}

.Card_no-padding_top.Card_theme_transparent {
  border-top: 0;
}

.Card_no-padding_right,
.Card_desktop.Card_no-padding_right {
  padding-right: 0;
}

.Card_no-padding_right.Card_theme_transparent {
  border-right: 0;
}

.Card_no-padding_bottom,
.Card_desktop.Card_no-padding_bottom {
  padding-bottom: 0;
}

.Card_no-padding_bottom.Card_theme_transparent {
  border-bottom: 0;
}

.Card_no-padding_left,
.Card_desktop.Card_no-padding_left {
  padding-left: 0;
}

.Card_no-padding_left.Card_theme_transparent {
  border-left: 0;
}

.Card_stack + .Card_stack {
  margin-top: 1em;
}

.Card_border-radius_16 {
  border-radius: 16px;
}
