.TooltipWithContributions-Legend {
  width: 16px;
  height: 2px;
  border-radius: 1px;
}

.TooltipWithContributions-Lines {
  width: fit-content;
  margin: auto;
}

.TooltipWithContributions-LineContent {
  display: flex;
  align-items: baseline;
  place-content: center flex-start;
}

.TooltipWithContributions-Name {
  flex-grow: 1;
}

.TooltipWithContributions_newStyle {
  .TooltipWithContributions-Legend {
    width: 24px;
    border-radius: 0;
  }
}
