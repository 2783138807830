.ContextMenu_Wrapper {
  position: relative;
}

.ContextMenu_Wrapper::before {
  position: absolute;
  top: 0;
  left: 100%;
  width: 40px;
  height: 100%;
  content: "";
}

.ContextMenu {
  position: absolute;
  left: calc(100% + 8px);
  display: inline-block;
  border-radius: 6px;
  cursor: pointer;
}

.ContextMenu:hover {
  background: var(--background-and-borders-muted);
  background-color: var(--background-minor);
}

.ContextMenu::after {
  position: absolute;
  top: 0;
  left: -8px;
  width: 8px;
  height: 32px;
  content: "";
}

.ContextMenu_Content {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: calc(100% + 40px);
  padding-left: 8px;
  transform: translateY(calc(-50% + 16px));
}

.ContextMenu_Overlay {
  position: fixed;
  z-index: 1000;
  background: var(--background-overlay);
  inset: 0;
}

.ContextMenu_OverlayContent {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.ContextMenu_OverlayChildren {
  border-radius: 6px;
  background-color: var(--background-default);
  pointer-events: none;
}

.ContextMenu_OverlayMenu {
  align-self: center
}
