.FilterButton {
  display: flex;
  white-space: nowrap;
}

.FilterButton > .FilterButton-Selected {
  overflow: hidden;
  padding: 1px 0;
  margin-left: 1px;
}

.FilterButton > .FilterButton-Selected:first-child {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.FilterButton > .FilterButton-Selected:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.FilterButton > .FilterButton-Reset {
  vertical-align: top;
}

.FilterButton-Empty,
.FilterButton-Selected {
  display: initial;
  padding: initial;
  margin: initial;
  cursor: pointer;
  font: inherit;
  outline: none;
  transition:
    background-color var(--animation-speed-default) var(--animation-easing-default),
    border-color var(--animation-speed-default) var(--animation-easing-default);
}

.FilterButton-Empty:hover,
.FilterButton-Selected:hover,
.FilterButton-Empty:focus-visible,
.FilterButton-Selected:focus-visible,
.FilterButton-Empty:disabled,
.FilterButton-Selected:disabled {
  transition: none;
}

.FilterButton-Empty {
  border: 1px solid var(--background-border);
  border-radius: 4px;
  background: var(--background-default);

  .Filter_blue & {
    border-color: var(--primary-surface-25);
  }
}

.FilterButton-EmptyInner {
  opacity: 0.65;
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);

  .Filter_blue & {
    opacity: 1;
  }
}

.FilterButton-Empty:hover .FilterButton-EmptyInner,
.FilterButton-Empty:focus-visible .FilterButton-EmptyInner {
  opacity: 0.9;
  transition: none;

  .Filter_blue & {
    opacity: 1;
  }
}

.FilterButton-Empty:hover {
  border-color: transparent;
  background-color: var(--primary-surface-10);
}

.FilterButton-Empty:focus-visible {
  border-color: var(--content-on-background-default);
}

.FilterButton-Empty:disabled {
  background-color: var(--background-muted);
  pointer-events: none;

  .Filter_blue & {
    background-color: var(--primary-surface-10);
    opacity: 0.5;
  }
}

.FilterButton-Selected {
  border: 0;
  background-color: var(--content-on-background-additional);

  .Filter_blue & {
    background-color: var(--primary-surface-10);
  }
}

.FilterButton-Selected:hover {
  background-color: var(--content-on-background-minor);

  .Filter_blue & {
    background-color: var(--primary-surface-25);
  }
}

.FilterButton-Selected:focus-visible {
  background-color: var(--content-on-background-minor);
  outline: 1px solid var(--content-on-background-default);
  outline-offset: 1px;

  .Filter_blue & {
    background-color: var(--primary-surface-25);
  }
}

.FilterButton-Selected:disabled {
  background-color: var(--content-on-background-minor);
  pointer-events: none;
}

.FilterButton_disableAnimation .FilterButton-Empty,
.FilterButton_disableAnimation .FilterButton-Selected {
  transition: none;
}
