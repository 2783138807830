.Sticky {
  position: sticky;
}

.Sticky_top {
  top: 0;
}

.Sticky_bottom {
  bottom: 0;
}

.Sticky_clickThrough {
  pointer-events: none;
}
