.PortfolioChartSelect-TabsLine {
  position: relative;
  display: flex;
}

.PortfolioChartSelect-TabsLineBorder {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.0625em;
  background: var(--background-border);
}

.PortfolioChartSelect-Tabs {
  position: relative;
  z-index: 1;
}

.PortfolioChartSelect-Tabs_desktop {
  .PortfolioChartSelect-Tab {
    padding: 0 1.25em;
    line-height: normal;

    .Tab-Inner {
      width: 100%;
      padding: 1.357em 0 1.143em;
    }
  }

  .PortfolioChartSelect-Tab:first-child {
    padding-left: 0;
  }
}

.PortfolioChartSelect-Tabs_phone {
  width: 100%;

  .PortfolioChartSelect-Tab {
    width: 50%;
    line-height: normal;

    .Tab-Inner {
      width: 100%;
      padding: 1.357em 0 1.143em;
    }
  }
}
