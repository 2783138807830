.CardNew {
  position: relative;
  box-sizing: border-box;
  border: 3px solid transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: var(--card-new-box-shadow);
}

.CardNew.CardNew_clickable:focus-visible {
  padding: 1px;
  border: 2px solid var(--background-default) !important;
  outline: none;
  outline: 1px solid var(--content-on-background-default);
}

.CardNew_clickable {
  cursor: pointer;
}

.CardNew_mask {
  position: absolute;
  border-radius: inherit;
  inset: 0;
  pointer-events: none;
}

.CardNew.CardNew_clickable:hover .CardNew_mask {
  inset: -3px;
}

.CardNew_withImage {
  padding: 0 !important;
  border: none;
}

.CardNew_selected {
  border: 2px solid var(--secondary-default) !important;
}

.CardNew_error {
  border: 2px solid var(--status-error) !important;
}

.CardNew_disabled.CardNew_clickable {
  cursor: default;
}

.CardNew_disabled.CardNew_withImage .CardNew_mask {
  background-color: var(--background-default);
  opacity: 0.2;
}

.CardNew_clickable.CardNew_withImage:hover:not(.CardNew_disabled) .CardNew_mask,
.CardNew_clickable.CardNew_hoverOverlay:hover:not(.CardNew_disabled) .CardNew_mask {
  background-color: var(--background-default);
  opacity: 0.5;
}

.CardNew_hoverable {
  transition: box-shadow var(--animation-speed-default) var(--animation-easing-default);
}

.CardNew_hoverable:hover,
.CardNew_hoverable:focus-visible {
  box-shadow: var(--card-new-box-shadow-on-hover);
  transition: box-shadow var(--animation-speed-zero) var(--animation-easing-default);
}
