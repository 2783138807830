.AltusProgressBarModal-Step {
  position: relative;
  overflow: hidden;
}

.AltusProgressBarModal-StepContent {
  margin-top: -2px;
  margin-bottom: -2px;
}

.AltusProgressBarModal-StepDescription {
  margin-left: 30px;
}

.AltusProgressBarModal-DefaultStatusIcon {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid var(--accent-default);
  border-radius: 50%;
  margin-right: 2px;
}

.AltusProgressBarModal-DefaultStatusIcon_active {
  background-color: var(--accent-default);
}

.AltusProgressBarModal-StatusIcon {
  margin-left: -2px;
}

.AltusProgressBarModal-InformationIcon {
  margin-top: 3px;
}

.AltusProgressBarModal-Pathway {
  position: absolute;
  top: 18px;
  left: 25px;
  width: 2px;
  height: 9999px;
  background-color: var(--accent-default);
}

.AltusProgressBarModal-Step_card .AltusProgressBarModal-Pathway {
  top: auto;
}

.AltusProgressBarModal-Pathway_completed {
  background-color: var(--secondary-default);
}
