.SecurityDetails_List {
  display: flex;
  flex-wrap: wrap;
}

/* calculates 3 column layout on desktop, 8px - padding on the item */
.SecurityDetails_Item {
  width: calc((100% - 8px * 2) / 3);
}

/* calculates 2 column layout on desktop, 8px - padding on the item */
.SecurityDetails_Item_mobile {
  width: calc((100% - 8px) / 2);
}

.SecurityDetails_Card {
  border: none;
}
