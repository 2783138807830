.CreateReportForm-Steps .CreateReportForm-Step[hidden] {
  display: none;
}

.CreateReportForm-Steps .CreateReportForm-Step {
  display: flex;
  flex-direction: column;
}

.CreateReportForm-Content {
  flex-grow: 1;
}

.CreateReportForm-Button,
.CreateReportForm-NavigationBar {
  position: sticky;
  z-index: 1;
  background-color: var(--background-default);
}

.CreateReportForm-Button {
  bottom: 0;
}

.CreateReportForm-NavigationBar {
  top: 0;
}

@supports (display: grid) {
  .CreateReportForm-Steps {
    display: grid;
    contain: layout;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .CreateReportForm-Steps .CreateReportForm-Step {
    grid-area: 1 / 1 / 1 / 1;
  }

  .CreateReportForm-Steps .CreateReportForm-Step[hidden] {
    display: block;
    max-height: 100vh; /* fallback */
    max-height: calc(var(--vh, 1vh) * 100);
    visibility: hidden;
  }
}

.CreateReportForm_phone .CreateReportForm-Steps {
  min-height: 100vh; /* fallback */
  min-height: calc(var(--vh, 1vh) * 100 - 48px - 16px);
  padding-right: 16px;
  padding-left: 16px;
}

.CreateReportForm_phone .CreateReportForm-Steps .CreateReportForm-Step[hidden] {
  max-height: 100vh; /* fallback */
  max-height: calc(var(--vh, 1vh) * 100 - 48px - 16px);
}

.CreateReportForm_phone .CreateReportForm-Button {
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}
