.InstantBankTransfer-HowItWorksLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.InstantBankTransfer-HowItWorksIcon {
  margin: -2px 0 -4px 6px;
  line-height: 1;
  vertical-align: middle;
}

.InstantBankTransfer-HowItWorksLink:hover,
.InstantBankTransfer-HowItWorksLink:focus-visible {
  .InstantBankTransfer-HowItWorksIcon path {
    fill: var(--primary-link-hover) !important;
  }
}
