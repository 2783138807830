.HorizontallyScrollable {
  -ms-overflow-style: none;
  overflow-x: auto;
  scrollbar-width: none;
}

.HorizontallyScrollable::-webkit-scrollbar {
  display: none;
}

.HorizontallyScrollable-Content {
  padding-right: 16px;
  padding-left: 16px;
  white-space: nowrap;
}

.HorizontallyScrollable-Content > * {
  white-space: initial;
}

.HorizontallyScrollable-Content > *:last-child {
  padding-right: 16px;
}
