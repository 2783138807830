.Badge {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 4px;
  border: 1px solid var(--background-border);
  border-radius: 0.334em;
  color: var(--content-on-background-minor);
  line-height: 1;
}

.Badge_theme_rounded-green {
  padding-top: 2px;
  padding-bottom: 2px;
  border: 0;
  border-radius: 9999px;
  background: var(--secondary-surface-45);
  color: var(--content-on-color-default);
}

.Badge_theme_rounded-warning {
  padding: 2px 8px;
  border: 0;
  border-radius: 9999px;
  background: var(--status-warning);
  color: var(--content-on-color-default);
}

.Badge_theme_rounded-primary-action {
  padding: 8px 12px;
  border: 0;
  border-radius: 9999px;
  background: var(--background-primary-action);
  color: var(--content-on-color-white);
}

.Badge_theme_rounded-accent-default {
  padding: 8px 12px;
  border-color: var(--accent-default);
  border-radius: 10px;
  color: var(--content-on-background-accent);
}
