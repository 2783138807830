.PortfolioLabels {
  display: inline-block;
  width: 263px;
}

.PortfolioLabel {
  display: inline-flex;
  width: auto;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
}
