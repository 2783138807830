.CurrentLine {
  position: absolute;
  top: auto;
  right: 0;
  left: auto;
  display: block;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    90deg,
    var(--background-border),
    var(--background-border) 60%,
    transparent 60%,
    transparent 100%
  );
  background-position: top right -0.175rem;
  background-size: 0.5rem 0.0625rem;
  content: '';
}
