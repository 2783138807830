.OfflineNotification {
  position: fixed;
  z-index: 9999;
  inset: 0;
}

.OfflineNotification-Inner {
  display: flex;
}

.OfflineNotification-Icon {
  flex-basis: 2em;
  flex-shrink: 0;
  padding-top: 0.125em;
}

.OfflineNotification-Text {
  flex-grow: 1;
}
