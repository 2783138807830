.AddressByPeriod {
  padding-bottom: 2.375rem;
  border-bottom: 1px solid var(--background-border);
  margin-bottom: 2.375rem;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.AddressByPeriod-InfoText {
  margin-bottom: 2.375rem;
}

.AddressByPeriod_noPaddingBottom {
  padding: 0;
  border-bottom: 0;
  margin-bottom: 0;
}

.AddressByPeriod_desktop {
  &:last-child {
    padding-bottom: 0;
  }
}
