.Document {
  display: block;
  border-top: 1px solid var(--background-border);
  border-bottom: 1px solid var(--background-border);
}

.Document + .Document {
  border-top-color: transparent;
  margin-top: -1px;
}

.Document-Icon {
  padding: 1px 4px;
  border: 2px solid var(--background-border);
  border-radius: 3px;
  color: var(--content-on-background-minor);
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
}
