.Report-Icon {
  margin: -8px 0;
}

.Report_desktop {
  .Report-Download {
    opacity: 0;
    transition: opacity var(--animation-speed-default) var(--animation-easing-default);
    will-change: opacity;
  }

  &.Report_generated:hover .Report-Download {
    opacity: 1;
    transition: opacity var(--animation-speed-zero) var(--animation-easing-default);
  }
}
