.FilterButton-Selected {
  .SecurityFilterLabel-Title {
    display: inline-block;
    overflow: hidden;
    max-width: var(--security-filter-label-max-width, 262px);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .SecurityFilterLabel-Ticker .Badge {
    display: none;
  }

  .MobileLayout & {
    .SecurityFilterLabel-Icon {
      width: 16px;
      height: 16px;
      border-radius: 3px;
      margin-top: -4px;
      margin-right: -8px;
    }

    .SecurityFilterLabel-Title {
      max-width: var(--security-filter-label-max-width, 243px);
      line-height: inherit;
    }
  }
}

.MobileLayout .SecurityFilterLabel-Title {
  line-height: 20px;
}

.SecurityFilterLabel-Ticker .Badge {
  vertical-align: top;
}
