.PaymentDetail {
  overflow: hidden;
  width: 100%;
}

.PaymentDetail-Table {
  display: table;
  float: right;
}

.PaymentDetail-TableRow {
  display: table-row;
}

.PaymentDetail-TableCell {
  display: table-cell;
  white-space: nowrap;

  &:not(:last-child)::after {
    content: ' ';
    white-space: pre;
  }
}

.PaymentDetail-BankLogo {
  > img {
    display: block;
  }
}

.PaymentDetail-BankLogo_none::before {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: var(--background-muted);
  content: '';
}

.PaymentDetail:not(.PaymentDetail_tinyPhone) {
  .PaymentDetail-TableRow:not(:first-child) {
    .PaymentDetail-TableCell {
      padding-top: 4px;
    }
  }
}

.PaymentDetail_tinyPhone {
  .PaymentDetail-Table,
  .PaymentDetail-TableRow,
  .PaymentDetail-TableCell {
    display: block;
  }

  .PaymentDetail-TableRow:not(:first-child) {
    margin-top: 8px;
  }
}
