.Segment_margin_smallest {
  margin-top: 0.25em;
  margin-bottom: 0.25em;

  &:first-child {
    margin-top: 0.5em;
  }

  &:last-child {
    margin-bottom: 0.5em;
  }
}

.Segment_margin_smaller {
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  &:first-child {
    margin-top: 1em;
  }

  &:last-child {
    margin-bottom: 1em;
  }
}

.Segment_margin_small {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.Segment_margin_normal {
  margin-top: 0.75em;
  margin-bottom: 0.75em;

  &:first-child {
    margin-top: 1.5em;
  }

  &:last-child {
    margin-bottom: 1.5em;
  }
}

.Segment_margin_bigger {
  margin-top: 1em;
  margin-bottom: 1em;

  &:first-child {
    margin-top: 2em;
  }

  &:last-child {
    margin-bottom: 2em;
  }
}

.Segment_margin_big {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.Segment_margin_biggest {
  margin-top: 2em;
  margin-bottom: 2em;

  &:first-child {
    margin-top: 4em;
  }

  &:last-child {
    margin-bottom: 4em;
  }
}

.Segment_margin_biggest-equal {
  margin-top: 2em;
  margin-bottom: 2em;
}

.Segment_margin_hefty {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.Segment_margin_huge {
  margin-top: 3em;
  margin-bottom: 3em;
}

.Segment_margin_double {
  margin-top: 4em;
  margin-bottom: 4em;
}

.Segment_noMargin_top,
.Segment_noMargin_all {
  &,
  &:first-child,
  &:last-child {
    margin-top: 0;
  }
}

.Segment_noMargin_bottom,
.Segment_noMargin_all {
  &,
  &:first-child,
  &:last-child {
    margin-bottom: 0;
  }
}
