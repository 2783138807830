.Checkbox {
  position: relative;
  overflow: hidden;
  width: 20px;
  height: 20px;
  padding: 1px;
  margin: -1px;
}

.Checkbox-RealInput {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  appearance: none;
  opacity: 0;
}

.Checkbox-Input {
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid var(--background-border);
  background: var(--background-default);
  pointer-events: none;
  transition:
    background-color var(--animation-speed-default) var(--animation-easing-default),
    border-color var(--animation-speed-default) var(--animation-easing-default);
}

.Checkbox-Icon {
  position: absolute;
  margin: auto;
  inset: 0;
}

.Checkbox-RealInput + .Checkbox-Input > .Checkbox-Icon {
  opacity: 0;
  transition: opacity var(--animation-speed-default) var(--animation-easing-default);
}

.Checkbox-RealInput:hover + .Checkbox-Input,
.Checkbox-RealInput:hover + .Checkbox-Input > .Checkbox-Icon,
.Checkbox-RealInput:focus-visible + .Checkbox-Input,
.Checkbox-RealInput:focus-visible + .Checkbox-Input > .Checkbox-Icon,
.Checkbox-Proxy:hover .Checkbox-Input,
.Checkbox-Proxy:hover .Checkbox-Icon,
.Checkbox-Proxy:focus-visible .Checkbox-Input,
.Checkbox-Proxy:focus-visible .Checkbox-Icon {
  transition: none;
}

.Checkbox-RealInput:disabled {
  pointer-events: none;
}

.Checkbox-RealInput:hover + .Checkbox-Input,
.Checkbox-Proxy:hover .Checkbox-Input {
  border-color: var(--content-on-background-additional);
}

.Checkbox-RealInput:active + .Checkbox-Input,
.Checkbox-RealInput:hover:active + .Checkbox-Input,
.Checkbox-Proxy:active .Checkbox-Input,
.Checkbox-Proxy:hover:active .Checkbox-Input {
  border-color: var(--content-on-background-additional);
  background-color: var(--primary-surface-10);
}

.Checkbox-RealInput:focus-visible + .Checkbox-Input,
.Checkbox-Proxy:focus-visible .Checkbox-Input {
  border-color: var(--content-on-background-default);
}

.Checkbox-RealInput:disabled + .Checkbox-Input {
  border-color: var(--content-on-background-muted);
  background-color: var(--content-on-background-muted);
}

.Checkbox-RealInput:checked + .Checkbox-Input {
  border-color: transparent;
  background-color: var(--primary-default);
}

.Checkbox-RealInput:checked + .Checkbox-Input > .Checkbox-Icon {
  opacity: 1;
}

.Checkbox-RealInput:checked:hover + .Checkbox-Input,
.Checkbox-Proxy:hover .Checkbox-RealInput:checked + .Checkbox-Input {
  border-color: var(--primary-default);
  background-color: var(--primary-surface-85);
}

.Checkbox-RealInput:checked:active + .Checkbox-Input,
.Checkbox-RealInput:checked:hover:active + .Checkbox-Input,
.Checkbox-Proxy:active .Checkbox-RealInput:checked + .Checkbox-Input,
.Checkbox-Proxy:hover:active .Checkbox-RealInput:checked + .Checkbox-Input {
  border-color: transparent;
  background-color: var(--primary-action);
}

.Checkbox-RealInput:checked:focus-visible + .Checkbox-Input,
.Checkbox-Proxy:focus-visible .Checkbox-RealInput:checked + .Checkbox-Input {
  border-color: var(--content-on-background-default);
}

.Checkbox-RealInput:checked:disabled + .Checkbox-Input {
  border-color: transparent;
  background-color: var(--primary-disabled);
}

.Checkbox_checkbox .Checkbox-RealInput,
.Checkbox_checkbox .Checkbox-Input {
  border-radius: 4px;
}

.Checkbox_radio .Checkbox-RealInput,
.Checkbox_radio .Checkbox-Input {
  border-radius: 50%;
}

.Checkbox_invalid .Checkbox-Input,
.Checkbox-RealInput:user-invalid:required + .Checkbox-Input {
  border-color: var(--status-error);
}
