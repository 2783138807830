.Filter_desktop {
  position: relative;
}

.Filter_desktop .Filter-Dropdown {
  position: absolute;
  z-index: 999;
  top: calc(100% + 4px);
  display: block !important;
  max-width: none;
  max-height: 420px;
  background: var(--background-default);
  box-shadow: var(--shadow-level-5);
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  transform: translate3d(0, -4px, 0) scale3d(0.375, 0.25, 1);
  transform-origin: top left;
  transition:
    opacity var(--animation-speed-default) var(--animation-easing-default),
    transform var(--animation-speed-default) var(--animation-easing-default);
}

.Filter_desktop .Filter-Dropdown--no-overflow {
  overflow-y: visible;
}

.Filter_desktop.Filter_open .Filter-Dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: translate3d(0, 0, 0);
  transition:
    opacity var(--animation-speed-fast) var(--animation-easing-default),
    transform var(--animation-speed-fast) var(--animation-easing-default);
}

.FilterTooltip .Tooltip-Tip {
  top: 28px;
}

.FilterTooltip .Tooltip-Pin::after {
  top: 24px;
}
