.SecurityItem {
  border-radius: 8px;
}

.SecurityItem-Mark {
  float: right;
}

.SecurityItem-Title {
  margin-bottom: 4px;
}

.SecurityItem-Ticker .Badge {
  margin-top: -2px;
  vertical-align: middle;
}

.SecurityItem_skeleton {
  min-height: 132px;
}

.SecurityItem_mobile.SecurityItem_skeleton {
  min-height: 124px;
}

.SecurityItem_skeleton_noLabels {
  min-height: 82px;
}

.SecurityItem_mobile.SecurityItem_skeleton_noLabels {
  min-height: 78px;
}
