/* Tab specific css */

.Tab {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  color: var(--content-on-background-additional);
  cursor: pointer;
  font-weight: 400;
  line-height: 2.75rem;
  place-content: center center;
  text-align: center;
  white-space: nowrap;
}

.Tab_hasElement {
  .Tab-Inner {
    position: initial;
    z-index: 2;
  }
}

.Tab-Inner {
  display: inline-block;
  transition: color var(--animation-speed-default) var(--animation-easing-default);

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 2px;
    content: '';
    transition: background var(--animation-speed-default) var(--animation-easing-default);
  }

  .Tab:not(.Tab_fullWidthBorder) & {
    position: relative;
  }

  .Tab:hover &,
  .Tab_active & {
    color: var(--content-on-background-default);
    transition: none;
  }

  .Tab:hover &::after,
  .Tab_active &::after {
    background: var(--secondary-default);
    transition: none;
  }

  .Tab_hasElement &::after {
    right: 2em;
    bottom: 0;
    left: 2em;
    height: 0.25em;
    pointer-events: none;
  }
}

.Tab-Element {
  display: flex;
}

/* Tabs specific css */

.Tabs {
  display: flex;
  justify-content: space-between;
}

/* Axis */

.Tabs_horizontal {
  height: 100%;

  .Tab_hasElement {
    .Tab-Inner {
      height: 100%;
      box-sizing: border-box;
      align-items: center;
      padding: 0.5em 2em;
    }

    .Tab-Element::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }
}

.Tabs_vertical {
  flex-direction: column;

  .Tab {
    align-items: flex-start;
    margin-right: -1rem;
    margin-left: -1rem;
    text-align: left;
  }

  .Tab-Inner {
    width: 100%;
    padding: 0.75em 1rem;

    &::after {
      bottom: 0.375em;
      left: 0;
      width: 0.25em;
      height: 2em;
    }
  }

  .Tab-Element {
    height: auto;
    padding: 0.75em 1rem;
    margin: -0.75em -1rem;
    line-height: 1.5;
  }
}

/* Media queries */

.Tabs_phone {
  &.Tabs_vertical {
    .Tab-Inner {
      padding-left: 2.5rem;
    }
  }

  &.Tabs_horizontal {
    font-size: 0.875em;
  }

  .Tab_desktop {
    display: none;
  }

  .Tab_phone {
    display: flex;
  }
}

.Tabs_desktop {
  &.Tabs_vertical {
    margin: 0.875em 0;
  }

  &.Tabs_horizontal,
  &.Tabs_vertical {
    font-size: 0.875rem;
  }

  &.Tabs_large.Tabs_horizontal {
    font-size: 16px;

    .Tab {
      line-height: 52px;
    }
  }

  .Tab_desktop {
    display: flex;
  }

  .Tab_phone {
    display: none;
  }
}

/* Layout mods */

.Tabs_noMargin {
  &.Tabs_vertical {
    margin: 0;
  }

  .Tab {
    margin-right: 0;
    margin-left: 0;
  }
}

.Tabs_padding-left_small {
  .Tab .Tab-Inner {
    padding-left: 1.25rem;
  }
}

/* Themes */

.Tabs_theme_iOS {
  .Tab {
    padding-right: 1rem;
    border-top: 1px solid var(--background-border);
    margin-top: -1px;

    &:last-child {
      border-bottom: 0;
    }
  }

  .Tab-Inner::after {
    display: none;
  }

  .Tab_active {
    .Tab-Element {
      color: var(--content-on-background-default) !important;
    }
  }

  .Tab-Element {
    &,
    &:hover,
    &:focus-visible {
      .Tab-Inner::after {
        opacity: 0 !important;
      }
    }
  }
}

.Tabs_theme_withBorder {
  box-shadow: 0 -1px 0 inset var(--background-border);
}

/* SubTabs specific css */

.Tabs-SubTabs {
  flex-direction: column;
  margin-right: 0;
  margin-left: 0;

  .Tab .Tab-Inner {
    &::after {
      display: none;
    }
  }

  .Tab-Element {
    display: flex;
    flex-direction: column;
  }
}

.Tabs-SubTabs.Tabs_desktop {
  margin: 0.5rem 0;

  .Tab .Tab-Inner {
    padding-left: 2.625rem;
  }
}

.Tabs-SubTabs.Tabs_phone {
  .Tab .Tab-Inner {
    padding-left: 5rem;
  }
}
