@keyframes sticky-show-from-top-animation {
  0% {
    position: sticky;
    transform: translateY(-100%);
  }

  100% {
    position: sticky;
    transform: translateY(0);
  }
}

@keyframes sticky-hide-to-top-animation {
  0% {
    position: sticky;
  }

  99% {
    position: sticky;
  }

  100% {
    position: relative;
    transform: translateY(-100%);
  }
}

.StickyTop {
  position: var(--sticky-top-position);
  z-index: 999;
  top: 0;
  background-color: var(--background-default);
  isolation: isolate;
  will-change: transform;

  & > *:only-child {
    border: none;
  }
}

.StickyTop_enter {
  animation: sticky-show-from-top-animation 400ms ease-in-out;
}

.StickyTop_leave {
  animation: sticky-hide-to-top-animation 200ms ease-in-out;
}
