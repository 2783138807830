.Sticked-Holder {
  position: relative;
  z-index: 999;
  right: 0;
  left: 0;
  background-color: var(--background-default);
}

.Sticked_woGateway {
  .Sticked-Holder {
    position: fixed;
  }

  &.Sticked_beforeLayout {
    .Sticked-Holder {
      top: 0;
    }
  }

  &.Sticked_afterLayout {
    .Sticked-Holder {
      bottom: 0;
    }
  }
}

.Sticked_insideNewModal {
  position: fixed;
  right: 16px;
  bottom: 16px;
  left: 16px;
}

.Sticked-PhantomButtons {
  margin-top: 80px;
  visibility: hidden;
}

.Sticked-PhantomButtons_marginSize_zero {
  margin-top: 0;
}

.Sticked-PhantomButtons_marginSize_small {
  margin-top: 20px;
}

.iOs {
  .Sticked_afterLayout:not(.Sticked_newButton),
  .Sticked_insideModal:not(.Sticked_newButton) {
    padding-bottom: 0;

    /* TODO: Refactor ? */

    /* HACK: Button_noPadding remove padding for ios 11 by new design */
    .Button.Button_size_big.Button_size_block:not(.Button_noPadding) {
      .Button-Inner {
        padding-bottom: calc(1em + env(safe-area-inset-bottom));
      }
    }
  }

  .Sticked_insideNewModal {
    bottom: calc(1em + env(safe-area-inset-bottom));
  }

  .Sticked_newButton,
  .Sticked-PhantomButtons {
    margin-bottom: calc(1em + env(safe-area-inset-bottom));
  }
}
