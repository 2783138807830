.Progress {
  display: flex;
  height: 0.25em;
  background-color: var(--background-minor);
}

.Progress-Holder {
  display: flex;
  flex-grow: 1;
}

.Progress-Bar,
.Progress-Gap_begin {
  background-color: var(--status-success);
}

.Progress-Bar {
  transition: width var(--animation-speed-default) var(--animation-easing-default);
}
