.SecurityInfo {
  position: relative;
}

.SecurityInfo-Content {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: minmax(100px, auto) max-content;
}

.SecurityInfo-Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SecurityInfo-RightColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.SecurityInfo-Processing {
  width: 24px;
  height: 24px;
  margin: -4px 4px -2px 0;
  vertical-align: middle;
}
