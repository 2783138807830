.Slider {
  position: relative;
}

.Slider_horizontal.Slider {
  width: 100%;
}

.Slider_vertical.Slider {
  height: 100%;
}

.Slider-Holder {
  position: relative;
  top: 0;
  left: 0;
}

.Slider_horizontal .Slider-Holder {
  width: 100%;
}

.Slider_vertical .Slider-Holder {
  height: 100%;
}

.Slider_vertical .Slider-Holder + .Slider-Holder {
  top: -100%;
}

.Slider-ValueAsCaption {
  display: block;
  height: 1em;
  padding-top: 0.425em;
  padding-bottom: 0.1em;
  margin-top: -0.175em;
  line-height: 1;
}

.Slider-Handle {
  box-sizing: border-box;
  border: 2px solid var(--background-thumb); /* without border handles kinda broken on dashboard */
  background-color: var(--background-thumb);
  box-shadow:
    var(--shadow-level-1),
    0 0 0 1px var(--background-border),
    var(--shadow-level-2);
  text-align: center;
}

.Slider-Handle::after {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  margin: auto;
  content: '';
  inset: -1000%;
}

.Slider-Bar {
  position: absolute;
  border-radius: calc(0.25rem / 2);
  background-color: var(--background-minor);
}

.Slider_horizontal .Slider-Bar {
  height: 0.25rem;
}

.Slider_vertical .Slider-Bar {
  width: 0.25rem;
}

.Slider-Bar::before {
  position: absolute;
  content: '';
}

.Slider_horizontal .Slider-Bar::before {
  height: 2rem;
  margin: auto;
  inset: -1000% 0;
}

.Slider_vertical .Slider-Bar::before {
  width: 3rem;
  height: 100%;
  margin: auto;
  inset: 0 -1000%;
}

.Slider-Bar-0 {
  border-radius: calc(0.25em / 2) 0 0 calc(0.25em / 2);
  background-color: var(--secondary-default);
}

.Slider_theme_risk-zones .Slider-Bar {
  background: linear-gradient(
    to left,
    #f2604c 13.08%,
    #f2c94c 31.7%,
    #f2c94c 45.95%,
    #4fbe0c 59.19%,
    #4fbe0c 68.07%,
    #6da6f2 90.34%
  );
}

/* that's a hack */
.Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_2 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_3 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_4 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_5 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_6 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_7 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_8 .Slider-Bar,
.Slider_theme_risk-zones.Slider_limitRight_9 .Slider-Bar {
  position: relative;
  overflow: hidden;
}

/* that's a hack */
.Slider_theme_risk-zones.Slider_limitLeft_1 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_2 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_3 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_4 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_5 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_6 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_7 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_8 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_9 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_2 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_3 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_4 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_5 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_6 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_7 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_8 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_9 .Slider-Bar .Slider-LimitRight {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  background-color: var(--background-minor-opaque);
  content: '';
}

.Slider_theme_risk-zones.Slider_limitLeft_1 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_2 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_3 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_4 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_5 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_6 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_7 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_8 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitLeft_9 .Slider-Bar .Slider-LimitLeft {
  left: 0;
}

.Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_2 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_3 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_4 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_5 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_6 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_7 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_8 .Slider-Bar .Slider-LimitRight,
.Slider_theme_risk-zones.Slider_limitRight_9 .Slider-Bar .Slider-LimitRight {
  right: 0;
}

.Slider_theme_risk-zones.Slider_limitLeft_1 .Slider-Bar .Slider-LimitLeft,
.Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar .Slider-LimitRight {
  pointer-events: none;
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_9 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar .Slider-LimitRight {
  width: calc(90% + 1.25rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_9 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar .Slider-LimitRight {
  width: calc(90% + 0.85rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_9 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar .Slider-LimitRight {
  width: calc(90% + 1.325rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_9 .Slider-Bar .Slider-LimitLeft.
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_1 .Slider-Bar .Slider-LimitRight, {
  width: calc(90% + 0.5rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_8 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_2 .Slider-Bar .Slider-LimitRight {
  width: calc(80% + 1.225rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_8 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_2 .Slider-Bar .Slider-LimitRight {
  width: calc(80% + 0.825rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_8 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_2 .Slider-Bar .Slider-LimitRight {
  width: calc(80% + 1.25rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_8 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_2 .Slider-Bar .Slider-LimitRight {
  width: calc(80% + 0.5rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_7 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_3 .Slider-Bar .Slider-LimitRight {
  width: calc(70% + 1.125rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_7 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_3 .Slider-Bar .Slider-LimitRight {
  width: calc(70% + 0.775rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_7 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_3 .Slider-Bar .Slider-LimitRight {
  width: calc(70% + 1.175rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_7 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_3 .Slider-Bar .Slider-LimitRight {
  width: calc(70% + 0.525rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_6 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_4 .Slider-Bar .Slider-LimitRight {
  width: calc(60% + 1.05rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_6 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_4 .Slider-Bar .Slider-LimitRight {
  width: calc(60% + 0.75rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_6 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_4 .Slider-Bar .Slider-LimitRight {
  width: calc(60% + 1.125rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_6 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_4 .Slider-Bar .Slider-LimitRight {
  width: calc(60% + 0.55rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_5 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_5 .Slider-Bar .Slider-LimitRight {
  width: calc(50% + 0.925rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_5 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_5 .Slider-Bar .Slider-LimitRight {
  width: calc(50% + 0.725rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_5 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_5 .Slider-Bar .Slider-LimitRight {
  width: calc(50% + 1.05rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_5 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_5 .Slider-Bar .Slider-LimitRight {
  width: calc(50% + 0.525rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_4 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_6 .Slider-Bar .Slider-LimitRight {
  width: calc(40% + 0.9rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_4 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_6 .Slider-Bar .Slider-LimitRight {
  width: calc(40% + 0.7rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_4 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_6 .Slider-Bar .Slider-LimitRight {
  width: calc(40% + 0.925rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_4 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_6 .Slider-Bar .Slider-LimitRight {
  width: calc(40% + 0.575rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_3 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_7 .Slider-Bar .Slider-LimitRight {
  width: calc(30% + 0.825rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_3 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_7 .Slider-Bar .Slider-LimitRight {
  width: calc(30% + 0.675rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_3 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_7 .Slider-Bar .Slider-LimitRight {
  width: calc(30% + 0.825rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_3 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_7 .Slider-Bar .Slider-LimitRight {
  width: calc(30% + 0.575rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_2 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_8 .Slider-Bar .Slider-LimitRight {
  width: calc(20% + 0.725rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_2 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_8 .Slider-Bar .Slider-LimitRight {
  width: calc(20% + 0.65rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_2 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_8 .Slider-Bar .Slider-LimitRight {
  width: calc(20% + 0.75rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_2 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_8 .Slider-Bar .Slider-LimitRight {
  width: calc(20% + 0.55rem - 1px);
}

.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitLeft_1 .Slider-Bar .Slider-LimitLeft,
.EditGoal_desktop .Slider_theme_risk-zones.Slider_limitRight_9 .Slider-Bar .Slider-LimitRight {
  width: calc(10% + 0.625rem - 1px);
}

.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitLeft_1 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_desktop .Slider_theme_risk-zones.Slider_limitRight_9 .Slider-Bar .Slider-LimitRight {
  width: calc(10% + 0.625rem - 1px);
}

.EditGoal_phone .Slider_theme_risk-zones.Slider_limitLeft_1 .Slider-Bar .Slider-LimitLeft,
.EditGoal_phone .Slider_theme_risk-zones.Slider_limitRight_9 .Slider-Bar .Slider-LimitRight {
  width: calc(10% + 0.675rem - 1px);
}

.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitLeft_1 .Slider-Bar .Slider-LimitLeft,
.EditGrowthPortfolio_phone .Slider_theme_risk-zones.Slider_limitRight_9 .Slider-Bar .Slider-LimitRight {
  width: calc(10% + 0.525rem - 1px);
}

.Slider_theme_risk-zones-to-yellow .Slider-Bar {
  background: linear-gradient(
    to left,
    #f2c94c 13.08%,
    #f2c94c 31.7%,
    #4fbe0c 45.95%,
    #4fbe0c 59.19%,
    #6da6f2 68.07%,
    #6da6f2 90.34%
  );
}

.Slider_horizontal.Slider_size_normal {
  height: 1.5em;
}

.Slider_vertical.Slider_size_normal {
  width: 1.5em;
}

.Slider_horizontal.Slider_size_normal.Slider_wCaption {
  height: calc(1.25em + 1.5em + 0.125em);
}

.Slider_vertical.Slider_size_normal.Slider_wCaption {
  width: calc(1.25em + 1.5em + 0.125em);
}

.Slider_size_normal .Slider-Handle {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  border-radius: calc(1.5em / 2);
  line-height: 1.5em;
}

.Slider_horizontal.Slider_size_normal .Slider-Bar {
  top: calc((1.5em - 0.25em) / 2);
  bottom: calc((1.5em - 0.25em) / 2);
}

.Slider_vertical.Slider_size_normal .Slider-Bar {
  right: calc((1.5em - 0.25em) / 2);
  left: calc((1.5em - 0.25em) / 2);
}

.Slider_horizontal.Slider_size_small {
  height: 0.875em;
}

.Slider_vertical.Slider_size_small {
  width: 0.875em;
}

.Slider_horizontal.Slider_size_small.Slider_wCaption {
  height: calc(1.25em + 0.875em + 0.125em);
}

.Slider_vertical.Slider_size_small.Slider_wCaption {
  width: calc(1.25em + 0.875em + 0.125em);
}

.Slider_size_small .Slider-Handle {
  position: relative;
  width: 0.875em;
  height: 0.875em;
  border-radius: calc(0.875em / 2);
  line-height: 0.875em;
}

.Slider_horizontal.Slider_size_small .Slider-Bar {
  top: calc((0.875em - 0.25em) / 2);
  bottom: calc((0.875em - 0.25em) / 2);
}

.Slider_vertical.Slider_size_small .Slider-Bar {
  right: calc((0.875em - 0.25em) / 2);
  left: calc((0.875em - 0.25em) / 2);
}

.Slider_absolute {
  position: absolute;
  margin: auto;
  inset: 0;
}

.Slider_phone:not(.Slider_absolute) {
  margin: 0.5em 0;
}

.Slider_phone:not(.Slider_absolute) .Slider-ValueAsCaption {
  padding-top: 0;
  padding-bottom: 0;
}

.Slider_phone:not(.Slider_absolute) + .SliderWTicks-Ticks {
  top: 1.875em;
  margin-bottom: 1em;
}
