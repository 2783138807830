.PortfolioLine {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 16px;
}

.PortfolioLine_managed {
  background-color: var(--primary-default);
}

.PortfolioLine_diy {
  background-color: var(--secondary-default);
}

.PortfolioLine_cash {
  background-color: var(--cash-default);
}

.PortfolioLine_selfSelected {
  background-color: var(--self-selected-default);
}
