.Inner {
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  margin: 0 auto;
}

.Inner_desktop {
  max-width: 78rem;
  padding: 0 2.5rem;
}

.Inner_height_full {
  display: flex;
  height: 100%;
  flex: 1 0 auto;
  flex-direction: column;
}

.Inner_size_small {
  max-width: 70rem;
}

.Inner_size_tiny {
  max-width: 47rem;
}

.Inner_flex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > * {
    flex-grow: 1;
  }
}

.Inner_no-padding_all {
  padding: 0;
}

.Inner_two_columns {
  max-width: calc(960px + 40px * 2);
}
