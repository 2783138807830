

.DocumentUploadModal_UploadBox {
  border: 1px dashed var(--background-border);
  border-radius: 16px;
}

.DocumentUploadModal_UploadBox-error {
  border: 1px dashed var(--status-error);
}

.DocumentUploadModal_IconContainer {
  position: relative;
  display: inline-block;
  border-radius: 50px;
  background-color: var(--background-border);
}

.DocumentUploadModal_IconContainer-success {
  background-color: var(--secondary-surface-45);
}

.DocumentUploadModal_IconContainer_SuccessIcon {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}
