.OrdersProcessing {
  line-height: 0;
}

.OrdersProcessing-Link {
  position: relative;
}

.OrdersProcessing-Link::after {
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: 0;
  display: block;
  width: 100%;
  height: 48px;
  content: '';
}

.OrdersProcessing-ItemWithIcon {
  margin-left: -4px;
}
